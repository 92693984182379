
import { Helmet } from 'react-helmet-async';
import blackWordLogo from '../../img/logo/black-word-logo.png';
import { FunnelFooter } from '../../components/landing/FunnelFooter';
import bgShapeDark from "../../img/bg/bg-shape-dark.png"

import { useEffect } from 'react';
import { useState } from 'react';
import moment from 'moment'
import $ from 'jquery'
import {set,ref,push} from 'firebase/database'
import { funnelDB } from '../../api/firebase-funnel-config';

export const Congratulations = () =>{

    const [pageID,setPageID] = useState()

    useEffect(()=>{

        if(pageID){
            var now = moment().format('MMMM DD YYYY')

            if(pageID){

                $.getJSON("https://api64.ipify.org?format=json", function(e){

                    var cleanedIP = String(e.ip).split('.').join('-')

                    push(ref(funnelDB,`analytics/impressions/${pageID}/${now}`),e.ip).then(()=>{

                        set(ref(funnelDB,`analytics/unique/${pageID}/${now}/${cleanedIP}`),'VISITED').then(()=>{

                        })

                    })

                });

            }
        }else setPageID('congratulations')

    },[pageID])

    return(
        <>
            <Helmet>
                <title>Trading Circle - Congratulations</title>
                <script>
                    window.fbq("track", "Lead");
                </script>
            </Helmet>
            
            <div className="container-fluid animate__animated animate__fadeIn py-4">

                <div className='row justify-content-center mt-lg-3 mt-0'>

                    <div className='col-12 text-center mb-4'>
                        <img className='d-none d-lg-inline darkmode-logo' src={blackWordLogo} width='20%' alt='logo'/>
                        <img className='d-inline d-lg-none darkmode-logo' src={blackWordLogo} width='70%' alt='logo'/>
                        <div className='row justify-content-center mb-3'>
                            <div className='col-lg-6 col-12'>
                                <hr className='blue-hr my-3' style={{opacity:'0.5'}}/>
                            </div>
                        </div>
                        <h1 className='bold mb-2 text-blue black'>Congratulations</h1>
                        <h4 className='bold' style={{fontSize:'calc(1rem + 0.5vw)'}}>Check your Email Address for the Training</h4>
                    </div>

                    <div className='col-lg-7 col-12' data-aos="fade-up">

                        <div className='video-container' style={{padding:"56.25% 0 0 0",position:"relative"}}><iframe src="https://player.vimeo.com/video/720988201?h=7e9d447d74&badge=0&autopause=0&player_id=0&app_id=58479/embed" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{position:"absolute",top:"0",left:"0",width:"100%",height:"100%"}} title="Website Walkthrough"></iframe></div>
                        <script src="https://player.vimeo.com/api/player.js"></script>
                    </div>

                    <div className='col-12 mt-5 mb-4 py-5 text-white' style={{background:`url('${bgShapeDark}')`,backgroundSize:'cover',backgroundPosition:'center',minHeight:'1050px'}}>

                        <h2 className='text-center bold mt-5 black' style={{fontSize:'calc(1.25rem + 0.95vw)',marginBottom:'75px'}} data-aos="fade-up">Here's What to Do Now</h2>
                        
                        <div className='row text-lg-start text-center mt-5 justify-content-center' style={{rowGap:"50px"}}>

                            <div className='col-lg-7 col-10 pb-5' data-aos="fade-up" style={{borderBottom:'2px solid white'}}>
                                <h3 className='black'><i className='fa fa-envelope'/> CHECK YOUR EMAIL</h3>
                                <p style={{fontSize:'calc(0.7rem + 0.7vw)'}}>Please check your <b>registered email address</b> as the <b>training video link</b> will be sent there. If you don't see it, please <b>check</b> your <b>Spam</b> or <b>Promotions</b> folders.</p>
                            </div>

                            <div className='col-lg-7 col-10 pb-5' data-aos="fade-up" style={{borderBottom:'2px solid white'}}>
                                <h3 className='black'><i className='fa fa-clock'/> BLOCK OUT YOUR TIME</h3>
                                <p style={{fontSize:'calc(0.7rem + 0.7vw)'}}>The webclass will last for <b>60 minutes</b> so turn off all distractions to really get the most out of this session.</p>
                            </div>

                            <div className='col-lg-7 col-10 pb-5' data-aos="fade-up">
                                <h3 className='black'><i className='fa fa-computer'/> / <i className='fa fa-mobile-screen'/> USE YOUR DESKTOP OR MOBILE PHONE</h3>
                                <p style={{fontSize:'calc(0.7rem + 0.7vw)'}}>I will be sharing a few graphs and data during the webclass, and to get the full experience, do use your <b>desktop</b> or <b>rotate</b> your mobile phone in <b>landscape</b>.</p>
                            </div>
                            
                        </div>

                    </div>

                </div>

            </div>
            
            <FunnelFooter/>
        </>
    );

}