import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

const studentConfig = {
  apiKey: "AIzaSyALNJyqbbXefKpulB6pF8eiaHsnP1VKv30",
  authDomain: "tradingcircle-ph.firebaseapp.com",
  databaseURL: "https://tradingcircle-ph-default-rtdb.firebaseio.com",
  projectId: "tradingcircle-ph",
  storageBucket: "tradingcircle-ph.appspot.com",
  messagingSenderId: "196432718257",
  appId: "1:196432718257:web:6fde4c7f2cc9cd835bf590",
  measurementId: "G-MMV6JVZCQ1"
};

const app = initializeApp(studentConfig);

export const studentDB = getDatabase(app);
export const studentStorage = getStorage(app);
export const studentAuth = getAuth(app);

