
import { Helmet } from 'react-helmet-async';
import { Footer } from '../../components/academy/Footer';

import {Header} from '../../components/general/Header'

import chartAnalysis from '../../img/tools/charting-analytics.png';
import currencyCorrelation from '../../img/tools/currency-correlation.png';
import currencyStrength from '../../img/tools/currency-strength.png';
import currentVolatility from '../../img/tools/current-volatility.png';
import newsUpdates from '../../img/tools/news-updates.png';
import positionCalculator from '../../img/tools/position-calculator.png';

export const Tools = () =>{

    return(
        <>
            <Helmet>
                <title>Trading Circle - Tools</title>
            </Helmet>
            
            <div className="container-fluid animate__animated animate__fadeIn p-4">

                <Header title='Tools'/>

                <div className='row justify-content-center mb-5 d-flex mt-5 px-lg-5 px-0' style={{gap:"50px"}}>
                    
                    <div className='col-lg-4 col-11' style={{maxWidth:"375px"}} data-aos="fade-up">

                        <div className='row justify-content-center'>

                            <div className='col-12 card-container-debossed px-5 py-4 text-center' style={{minHeight:"415px"}}>
                                <img className='darkmode-logo' src={chartAnalysis} width="50%" alt='icon'/>
                                <h4 className='saira text-blue text-center bold'>Charting & Analysis</h4>
                                <p className='text-blue text-justify mb-4 mt-3'>Tradingview will help you plot your technical analysis. Traders and investors worldwide use this tool to spot opportunities across global markets.</p>
                            </div> 
                        
                            <div className='col-10' style={{marginTop:"-25px"}}>
                                <a target="_blank" rel="noreferrer" href='https://www.tradingview.com/'><button className='form-control btn-accent py-3'><span>Learn More <i className='fa fa-arrow-right ms-1'/></span></button></a>
                            </div>

                        </div>

                    </div>

                    <div className='col-lg-4 col-11' style={{maxWidth:"375px"}} data-aos="fade-up">

                        <div className='row justify-content-center'>

                            <div className='col-12 card-container-debossed px-5 py-4 text-center' style={{minHeight:"415px"}}>
                                <img className='darkmode-logo' src={newsUpdates} width="50%" alt='icon'/>
                                <h4 className='saira text-blue text-center bold'>News & Updates</h4>
                                <p className='text-blue text-justify mb-4 mt-3'>Forexfactory is where you can check the upcoming news. Analyze how these news will affect your trading setup and entries. </p>
                            </div> 
                        
                            <div className='col-10' style={{marginTop:"-25px"}}>
                                <a target="_blank" rel="noreferrer" href='https://www.forexfactory.com/'><button className='form-control btn-blue py-3'><span>Learn More <i className='fa fa-arrow-right ms-1'/></span></button></a>
                            </div>

                        </div>

                    </div>

                    <div className='col-lg-4 col-11' style={{maxWidth:"375px"}} data-aos="fade-up">

                        <div className='row justify-content-center'>

                            <div className='col-12 card-container-debossed px-5 py-4 text-center' style={{minHeight:"415px"}}>
                                <img className='darkmode-logo' src={currentVolatility} width="50%" alt='icon'/>
                                <h4 className='saira text-blue text-center bold'>Currency Volatility</h4>
                                <p className='text-blue text-justify mb-4 mt-3'>You can check here the volatility of the currency pairs. Volatility simply means how fast the exchange rate of the currencies changes. </p>
                            </div> 
                        
                            <div className='col-10' style={{marginTop:"-25px"}}>
                                <a target="_blank" rel="noreferrer" href='https://www.investing.com/tools/forex-volatility-calculator?fbclid=IwAR3UO82SqTmr8lHd1zs9egC53OMC7im-d1gBRJP8lJDn3Ohm9Vyh4YW2EC0/'><button className='form-control btn-blue py-3'><span>Learn More <i className='fa fa-arrow-right ms-1'/></span></button></a>
                            </div>

                        </div>

                    </div>

                    <div className='col-lg-4 col-11' style={{maxWidth:"375px"}} data-aos="fade-up">

                        <div className='row justify-content-center'>

                            <div className='col-12 card-container-debossed px-5 py-4 text-center' style={{minHeight:"415px"}}>
                                <img className='darkmode-logo' src={positionCalculator} width="50%" alt='icon'/>
                                <h4 className='saira text-blue text-center bold'>Position Calculator</h4>
                                <p className='text-blue text-justify mb-4 mt-3'>You may use this calculator if you are unsure with the proper lot sizing. Proper position sizing is the key to managing your risk.   </p>
                            </div> 
                        
                            <div className='col-10' style={{marginTop:"-25px"}}>
                                <a target="_blank" rel="noreferrer" href='https://www.babypips.com/tools/position-size-calculator'><button className='form-control btn-blue py-3'><span>Learn More <i className='fa fa-arrow-right ms-1'/></span></button></a>
                            </div>

                        </div>

                    </div>
                    
                    <div className='col-lg-4 col-11' style={{maxWidth:"375px"}} data-aos="fade-up">

                        <div className='row justify-content-center'>

                            <div className='col-12 card-container-debossed px-5 py-4 text-center' style={{minHeight:"415px"}}>
                                <img className='darkmode-logo' src={currencyCorrelation} width="50%" alt='icon'/>
                                <h4 className='saira text-blue text-center bold'>Currency Correlation</h4>
                                <p className='text-blue text-justify mb-4 mt-3'>Knowing the correlation of the currencies will give you confidence whether the position you entered to multiple pairs is reasonable or not.       </p>
                            </div> 
                        
                            <div className='col-10' style={{marginTop:"-25px"}}>
                                <a target="_blank" rel="noreferrer" href='https://www.myfxbook.com/forex-market/correlation'><button className='form-control btn-blue py-3'><span>Learn More <i className='fa fa-arrow-right ms-1'/></span></button></a>
                            </div>

                        </div>

                    </div>

                    <div className='col-lg-4 col-11' style={{maxWidth:"375px"}} data-aos="fade-up">

                        <div className='row justify-content-center'>

                            <div className='col-12 card-container-debossed px-5 py-4 text-center' style={{minHeight:"415px"}}>
                                <img className='darkmode-logo' src={currencyStrength} width="50%" alt='icon'/>
                                <h4 className='saira text-blue text-center bold'>Currency Strength</h4>
                                <p className='text-blue text-justify mb-4 mt-3'>This chart will give you a real-time glimpse of how weak or strong a currency is. This may give you an overall view of the strength or weakness of the currency.  </p>
                            </div> 
                        
                            <div className='col-10' style={{marginTop:"-25px"}}>
                                <a target="_blank" rel="noreferrer" href='https://www.livecharts.co.uk/currency-strength.php'><button className='form-control btn-blue py-3'><span>Learn More <i className='fa fa-arrow-right ms-1'/></span></button></a>
                            </div>

                        </div>

                    </div>

                </div>

            </div>
            <Footer/>
        </>
    );

}