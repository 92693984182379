import { Helmet } from "react-helmet-async"
import { Footer } from "../../components/academy/Footer"
import { Header } from "../../components/general/Header"

export const AdvancedTraining = ()=>{


    return(
        <>
            <Helmet>
                <title>Trading Circle - Advanced Training</title>
            </Helmet>

            <div className="container-fluid animate__animated animate__fadeIn p-4">
                
                
                <>
                    <Header title="Advanced Training"/>
                    <div className='row justify-content-center mt-lg-5 mt-0'>
                        <div className="col-lg-8">
                            <h4 className="text-center text-light-blue bold d-none d-lg-block" style={{fontSize:'22px'}}>Discover the Secrets of the Professional Traders</h4>
                            <h4 className="text-center text-light-blue bold d-block d-lg-none" style={{fontSize:'14px'}}>Discover the Secrets of the Professional Traders</h4>
                            <h4 className="text-white bold text-center bg-blue p-3 mb-3 d-none d-lg-block saira" style={{borderRadius:'25px'}} data-aos='fade-down'>How they Massively Earn from the Forex Market Easily and Consistently</h4>
                            <h6 className="text-white bold text-center bg-blue p-3 mb-3 d-block d-lg-none" style={{borderRadius:'25px'}} data-aos='fade-down'>How they Massively Earn from the Forex Market Easily and Consistently</h6>
                            
                            <div className="video-container">
                                <iframe className='d-lg-block' src="https://player.vimeo.com/video/721348258?h=1ea86b5c63&badge=0&autopause=0&player_id=0&app_id=58479/embed" title="Advanced Training"/>
                            </div>
                        </div>

                        <div className="col-lg-9 col-12">
                            <div className='row justify-content-center d-flex mt-5 mb-5' style={{gap:"40px"}}>
                                <div className="row justify-content-center">

                                    <div className="col-12">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-6 text-center mb-lg-0 mb-3">
                                                <a href='/elitecheckout' target='_blank'><button className="btn-solid py-2 px-5 w-100" data-aos='fade-up'><i className="fa-solid fa-handshake-angle"/> I WANT TO UPGRADE NOW</button></a>
                                                <p className="text-muted mt-2 text-center ms-1 d-lg-block d-none" data-aos='fade-up' style={{fontSize:'14px'}}>*Click the button above to start skyrocketing your profit.</p>
                                                <p className="text-muted mt-2 text-start ms-lg-4 ms-1 d-lg-none d-block" data-aos='fade-up' style={{fontSize:'12px'}}>*Click the button above to start skyrocketing your profit.</p>
                                            </div>

                                            <div className="col-lg-6 text-center">
                                                <a href='/testimonial-submission' target='_blank'><button className="btn-solid py-2 px-5 w-100" data-aos='fade-up'><i className="fa-solid fa-tag"/> I WANT MORE DISCOUNT</button></a>
                                                <p className="text-muted mt-2 text-center ms-1 d-lg-block d-none" data-aos='fade-up' style={{fontSize:'14px'}}>*Click the button above for a discount.</p>
                                                <p className="text-muted mt-2 text-start ms-lg-4 ms-1 d-lg-none d-block" data-aos='fade-up' style={{fontSize:'12px'}}>*Click the button above for a discount.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                
            </div>
            <Footer/>
        </>
    )
}