
import { Helmet } from 'react-helmet-async';
import blackWordLogo from '../../img/logo/trading-circle-workshop-logo.png';
import { FunnelFooter } from '../../components/landing/FunnelFooter';
import irregShape from "../../img/bg/irregular-bg.png"

import { useEffect } from 'react';
import { useState } from 'react';
import moment from 'moment'
import $ from 'jquery'
import {set,ref,push} from 'firebase/database'
import { funnelDB } from '../../api/firebase-funnel-config';

export const WorkshopDay1 = () =>{

    const [pageID,setPageID] = useState()

    var[hour,setHour] = useState(0)
    var[minute,setMinute] = useState(0)
    var[second,setSecond] = useState(0)

    var[finished,setFinished] = useState(false);

    useEffect(()=>{

        if(pageID){
            var now = moment().format('MMMM DD YYYY')

            if(pageID){

                $.getJSON("https://api64.ipify.org?format=json", function(e){

                    var cleanedIP = String(e.ip).split('.').join('-')

                    push(ref(funnelDB,`analytics/impressions/${pageID}/${now}`),e.ip).then(()=>{

                        set(ref(funnelDB,`analytics/unique/${pageID}/${now}/${cleanedIP}`),'VISITED').then(()=>{

                        })

                    })

                });

            }
        }else setPageID('workshop-day-1')

    },[pageID])

    useEffect(()=>{

        var eventTime= moment().add(1,'day').unix(); 
        var currentTime = moment(moment().month()+1 + '-' +moment().date()+ '-'+moment().year() ,'MM-DD-YY');
        var diffTime =  currentTime - eventTime;
        var duration = moment.duration(diffTime*1000, 'milliseconds');
        var interval = 1000;

        const inte = setInterval(() =>{
            duration = moment.duration(duration - interval, 'milliseconds');
            setHour(duration.hours() < 10?'0'+ String(duration.hours()):duration.hours())
            setMinute(duration.minutes() < 10?'0'+ String(duration.minutes()):duration.minutes())
            setSecond(duration.seconds() < 10?'0'+ String(duration.seconds()):duration.seconds())
        }, interval);

        return () => clearInterval(inte);

    },[])

    return(
        <>
            <Helmet>
                <title>Trading Circle - Mini Workshop</title>
                <script>

                </script>
            </Helmet>
            
            <div className="container-fluid animate__animated animate__fadeIn py-4">

                <div className='row justify-content-center mt-lg-3 mt-0'>

                    <div className='col-12 text-center mb-4'>
                        <img className='d-none d-lg-inline darkmode-logo' src={blackWordLogo} width='20%' alt='logo'/>
                        <img className='d-inline d-lg-none darkmode-logo' src={blackWordLogo} width='70%' alt='logo'/>
                        <div className='row justify-content-center mb-3'>
                            <div className='col-lg-6 col-12'>
                                <hr className='blue-hr my-3' style={{opacity:'0.5'}}/>
                            </div>
                        </div>
                        <h1 className='bold mb-2 text-blue black'>Day 1</h1>
                        <div className='row justify-content-center'>
                            <h4 className='bold bg-blue text-white py-2 px-5 mx-3' style={{fontSize:'calc(0.8rem + 0.75vw)',width:'fit-content',borderRadius:'15px',boxShadow:'0 0 5px 3px rgba(0,0,0,0.3)'}}>How to Earn Money on Forex</h4>
                        </div>
                    </div>
        
                    <div className='col-lg-7 col-12' data-aos="fade-up">

                        <div className='video-container' style={{padding:"56.25% 0 0 0",position:"relative"}}><iframe src="https://player.vimeo.com/video/849398796?h=53a5508731&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"  frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{position:"absolute",top:"0",left:"0",width:"100%",height:"100%"}} title="Website Walkthrough"></iframe></div>
                        <script src="https://player.vimeo.com/api/player.js"></script>
                    </div>
                </div>

                <div className='row justify-content-center'>
                    <div className='col-lg-12 col-11 mt-4'>

                        {!finished?
                        <div className='row justify-content-center mb-4'>
                            <p className='text-center mb-3 text-blue' style={{fontSize:'calc(0.75rem + 0.4vw)'}}>Please <b>click</b> the <b>button</b> below to <b>confirm</b> your <b>attendance</b> and <b>get</b> the <b>access</b> for <b>tomorrow</b>'s training.</p>
                            <button className='py-3 px-5' style={{width:'fit-content',fontSize:'calc(0.65rem + 0.5vw)'}} onClick={()=>setFinished(true)}><i className='fa fa-check'/> I AM READY FOR DAY 2 TOMORROW</button>
                        </div>
                        :
                        <div className='row justify-content-center mb-4 animate__animated animate__tada'>
                            <p className='fa fa-check text-center text-success' style={{fontSize:'calc(1rem + 0.75vw)'}}><span> Success</span></p>
                            <p className='text-center mb-3 text-success' style={{fontSize:'calc(0.65rem + 0.4vw)'}}>This is a <b>confirmation message</b> that you <b>finished</b> <b>Day 1</b> and you are ready for <b>Day 2</b>.<br/><br className='d-lg-none d-block'/>Please <b>keep checking</b> your <b><i className='fa fa-envelope'/> email inbox</b> and we will <b>send</b> the <b>link</b> to you there any time of the day.</p>
                        </div>
                        }
                    </div>
                </div>


                <div className='row justify-content-center d-none d-lg-block'>

                    <div className='col-12'>
                        <div className='row justify-content-center pb-5 mt-3'>

                            <div className='col-12 mt-5'>
                                <p className='text-light-blue saira bold text-center '>2ND DAY WORKSHOP AVAILABLE IN</p>
                            </div>

                            <div className='col-lg-10 text-center'>

                                <h1 className='black text-blue' style={{fontSize:"6.5vw"}}>
                                    {hour}
                                    <span className='saira text-light-blue me-5' style={{fontSize:"1.25vw"}}>HOURS</span>
                                    {minute}
                                    <span className='saira text-light-blue me-5' style={{fontSize:"1.25vw"}}>MINUTES</span>
                                    {second}
                                    <span className='saira text-light-blue' style={{fontSize:"1.25vw"}}>SECONDS</span>
                                </h1>
                            </div>

                        </div>
                    </div>
                    

                </div>

                <div className='row justify-content-center d-lg-none d-block pb-2 pt-3 mt-3'>

                    <div className='col-12'>
                        <p className='text-light-blue saira bold text-center'>2ND DAY WORKSHOP AVAILABLE IN</p>
                    </div>

                    <div className='col-12 text-center'>

                        <h1 className='black text-blue' style={{fontSize:"10vw"}}>
                            {hour} : {minute} : {second}
                        </h1>
                    </div>

                </div>

            </div>

            <div className='d-lg-block d-none' style={{background:`url('${irregShape}')`,width:'125%',height:'1507px',position:'absolute',top:'0',zIndex:'-999',backgroundPosition:'center center',right:'-12.5%',backgroundSize:'cover',opacity:'0.3'}}/>
            <div className='d-lg-none d-block' style={{background:`url('${irregShape}')`,width:'100%',height:'1005px',position:'absolute',top:'-100px',zIndex:'-999',backgroundPosition:'center center',backgroundSize:'cover',opacity:'0.2'}}/>

            <FunnelFooter/>
        </>
    );

}