import Modal from 'react-bootstrap/Modal';

import { useEffect, useState } from 'react';
import ModalLoading from '../general/ModalLoading';
import { onAuthStateChanged } from 'firebase/auth';
import { onValue, ref, set } from 'firebase/database';
import { studentAuth, studentDB } from '../../api/firebase-student-config';
import moment from 'moment'

export default function ModalNotifications(props) {

  var [processing,setProcessing] = useState(false)
  
  var [notifications,setNotifications] = useState([]);
  
  var [student,setStudent] = useState();
  var [UID,setUID] = useState();

  useEffect(()=>{

    onAuthStateChanged(studentAuth,(student)=>{
        
      if(student){
          
          setUID(student.uid)

          onValue(ref(studentDB,"students/"+student.uid),(snapshot)=>{
              if(snapshot.exists()){
                setStudent(snapshot.val());
              }
          })
  
      }
    })

  },[])

  useEffect(()=>{
    if(UID){
      
      onValue(ref(studentDB,`notifications/${UID}`),(snapshot)=>{

        if(snapshot.exists()){

          var nfs = []

          for(var k in snapshot.val()){
            var n = snapshot.val()[k]
            n.key = k;
            nfs.push(n);
          }
          nfs.reverse();
          setNotifications(nfs);

        }

      })

    }
  },[UID])

  function readNotification(n){

    setProcessing(true);

    set(ref(studentDB,`notifications/${UID}/${n.key}/read`),true).then(()=>{

      window.location.href = n.link;
      setProcessing(false)

    }).catch((error)=>{
      alert(error.message);
    })

  }

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop='static'
      >
        
        <Modal.Header className='bg-blue' closeButton>
        
          <h4 className='saira text-center text-white m-0'><i className='fa fa-bell'/> Notifications</h4>

        </Modal.Header>

        <Modal.Body className='bg-white-blue' style={{minHeight:'500px', maxHeight:'500px',overflowY:'auto',overflowX:'hidden'}}>

          {notifications.length === 0 && 
          <div className='row' style={{minHeight:'470px'}}>
            <div className='col-12 align-self-center'>
              <h3 className='text-center text-muted mb-0 align-self-center' style={{opacity:'0.25'}}>No Notifications</h3>
            </div>
          </div>
          }

          {notifications.map((n)=>{
            return(
            <div className='row notification-container py-3 px-2' key={n.key} onClick={()=>readNotification(n)}>
              
              <div className='image col-lg-2 col-3 text-center align-self-center'>
                <img alt="notif-icon" src={n.image} width='65px' style={{borderRadius:'50rem'}}/>
                <i className={`sub-icon ${n.icon}`}/>
              </div>

              <div className='message col-lg-9 col-9 align-self-center'>
                <p className={`text mb-1 ${n.read && 'read'}`} dangerouslySetInnerHTML={{__html:n.message}}/>
                <p className={`timestamp ${n.read && 'text-muted'}`}>{moment(n.time).fromNow()}</p>
              </div>
              
              {!n.read &&
              <div style={{position:'absolute',top:'40%'}}>
                <i className='fa fa-circle animate__animated animate__pulse animate__infinite'/>
              </div>
              }

            </div>
            )
          })}

          



        </Modal.Body>

        <Modal.Footer className='bg-blue'>
        </Modal.Footer>

      </Modal>

      <ModalLoading show={processing} />

    </>
  );
}