import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import { onValue, ref } from 'firebase/database';
import { forumDB } from '../../api/firebase-forum-config';
import { studentDB } from '../../api/firebase-student-config';
import placeholder from '../../img/placeholder/profile-placeholder.png'
import { reactEmoji } from '../../helper/globals';
import { chatDB } from '../../api/firebase-chat-config';

export default function ModalViewReaction(props) {

  const [selected,setSelected] = useState('All')

  const [students,setStudents] = useState()
  const [reactions,setReactions] = useState([])

  const [reactionDisplay,setReactionDisplay] = useState([])

  const [like,setLike] = useState(0)
  const [love,setLove] = useState(0)
  const [haha,setHaha] = useState(0)
  const [wow,setWow] = useState(0)
  const [sad,setSad] = useState(0)
  const [angry,setAngry] = useState(0)

  function setDefault(){
    setSelected('All')
    setReactions([])
    setReactionDisplay([])
  }

  useEffect(()=>{

    onValue(ref(studentDB,'students'),(snap)=>{
      if(snap.exists()){
        setStudents(snap.val())
      }else setStudents(null)
    })

  },[])

  useEffect(()=>{

    if(props.level && props.postKey){
      
      onValue(ref(forumDB,`${props.level}/posts/${props.postKey}/reaction`),(snap)=>{

        if(snap.exists()){

          var rs = []

          for(var k in snap.val()){
            rs.push(JSON.parse(`{"${k}":"${snap.val()[k]}"}`))
          }

          setReactions(rs)

        }else setReactions([])

      })

    }else if(props.level && props.chatKey){
      onValue(ref(chatDB,`${props.level.toLowerCase()}/${props.chatKey}/reaction`),(snap)=>{

        if(snap.exists()){

          var rs = []

          for(var k in snap.val()){
            rs.push(JSON.parse(`{"${k}":"${snap.val()[k]}"}`))
          }

          setReactions(rs)

        }else setReactions([])

      })
    }

  },[props])
  

  useEffect(()=>{

    var li = 0
    var lo = 0
    var ha = 0
    var wo = 0
    var sa = 0
    var an = 0

    var reacts = []

    for(var i in reactions){

      var k = Object.keys(reactions[i])[0]

      if(reactions[i][k] === 'Like') li += 1
      else if(reactions[i][k] === 'Love') lo += 1
      else if(reactions[i][k] === 'Haha') ha += 1
      else if(reactions[i][k] === 'Wow') wo += 1
      else if(reactions[i][k] === 'Sad') sa += 1
      else if(reactions[i][k] === 'Angry') an += 1

      if(selected !== 'All')
        if(reactions[i][k] === selected) reacts.push(reactions[i])
      

    }

    if(selected === 'All') setReactionDisplay(reactions)
    else setReactionDisplay(reacts)

    setLike(li)
    setLove(lo)
    setHaha(ha)
    setWow(wo)
    setSad(sa)
    setAngry(an)

  },[reactions,selected])

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdropClassName='top-most'
        onShow={()=>setDefault()}
      > 

        <Modal.Header className='bg-white-blue' style={{borderBottom:'none'}}>

          <i className='fa fa-times pointer' onClick={()=>props.onHide()} style={{position:'absolute',right:'20px',top:'10px'}}/>

          <div className='row reaction-selection' style={{overflow:'auto',whiteSpace:'nowrap'}}>
            <div className='col-12 px-2'>
              <span onClick={()=>setSelected('All')} className={selected === 'All' && 'selected'}>All</span>
              {like > 0 && <span onClick={()=>setSelected('Like')} className={selected === 'Like' && 'selected'}>👍 {like}</span>}
              {love > 0 && <span onClick={()=>setSelected('Love')} className={selected === 'Love' && 'selected'}>❤️ {love}</span>}
              {haha > 0 && <span onClick={()=>setSelected('Haha')} className={selected === 'Haha' && 'selected'}>😆 {haha}</span>}
              {wow > 0 && <span onClick={()=>setSelected('Wow')} className={selected === 'Wow' && 'selected'}>😯 {wow}</span>}
              {sad > 0 && <span onClick={()=>setSelected('Sad')} className={selected === 'Sad' && 'selected'}>😢 {sad}</span>}
              {angry > 0 && <span onClick={()=>setSelected('Angry')} className={selected === 'Angry' && 'selected'}>😠 {angry}</span>}
            </div>
          </div>

        </Modal.Header>

        <Modal.Body className='bg-white-blue' style={{borderRadius:'0 0 5px 5px'}}>

          <div className='row'>

            <div className='col-12'>

              {reactionDisplay.map((r)=>{

                var k = Object.keys(r)[0]

                return(
                <div className='row mb-2'>
                  <div className='col-2 pe-0' style={{width:'fit-content'}}>
                    <img className="reaction-profile" src={students?students[k].profile.profilePictureURL:placeholder} alt="profile"/>

                    <div style={{position:'relative'}}>
                      <p style={{position:'absolute',top:'-15px'}}>
                        {reactEmoji[r[k]]}
                      </p>
                    </div>

                  </div>
                  <div className='col-10 align-self-center bold text-blue small'>
                    <p>{students && `${students[k].profile.name.firstName} ${students[k].profile.name.lastName}`}</p>
                  </div>
                </div>
                )

              })}
              

            </div>

          </div>

        </Modal.Body>

      </Modal>

    </>
  );
}