import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import $ from 'jquery'
import { onValue, push, ref, set } from "firebase/database"
import { forumDB } from '../../api/firebase-forum-config';
import { studentDB } from '../../api/firebase-student-config';
import { globalCoaches, globalDevelopers, reactColor, reactEmoji } from '../../helper/globals';
import moment from 'moment';

import placeholder from '../../img/placeholder/profile-placeholder.png'
import ModalViewImage from '../general/ModalViewImage';
import ModalLoading from '../general/ModalLoading';
import ModalViewReaction from './ModalViewReaction';
import ModalConfirmation from '../general/ModalConfirmation';
import { shortenDescription } from '../../helper/formatter';

export default function ModalViewPost(props) {

  const [student,setStudent] = useState()

  const [students,setStudents] = useState()
  const [post,setPost] = useState()

  const [owner,setOwner] = useState()
  const [postImages,setPostImages] = useState([])
  const [reactCount, setReactCount] = useState()
  const [emojis,setEmojis] = useState()

  const [selectedImage,setSelectedImage] = useState()

  const [content,setContent] = useState('')

  const [processing,setProcessing] = useState(false)

  const [comments,setComments] = useState([])
  const [finalComments,setFinalComments] = useState([])
  const [limit,setLimit] = useState(5)
  
  const [selectedReaction,setSelectedReaction] = useState()

  const [deletingPost,setDeletingPost] = useState(null)
  const [deletingComment,setDeletingComment] = useState(null)

  useEffect(()=>{

    onValue(ref(studentDB,'students'),(snapshot)=>{
      if(snapshot.exists()){
        setStudents(snapshot.val())
      }
    })

  },[])

  useEffect(()=>{

    if(students && props.uid){
      setStudent(students[props.uid])
    }

  },[students,props])
  
  useEffect(()=>{

    if(props.level && props.postKey){

      onValue(ref(forumDB,`${props.level}/posts/${props.postKey}`),(snap)=>{
        
        if(snap.exists()){
          setPost(snap.val())
          console.log(snap.val())
        }else props.onHide()

      })

      onValue(ref(forumDB,`${props.level}/comments/${props.postKey}`),(snap)=>{
        if(snap.exists()){
  
          var cs = []
  
          for(var k in snap.val()){
            var c = snap.val()[k]
            c.key = k
            cs.push(c)
          }
          
          cs.reverse()
          setComments(cs)
        }else setComments([])

      })

    }

  },[props])

  useEffect(()=>{

    if(comments.length > 0){

      var fc = []

      for(var i = 0; i < limit;i++){
        if(comments[i]) fc.push(comments[i])
        else break
      }

      fc.reverse()
      setFinalComments(fc)
      console.log(fc)

    }else setFinalComments([])

  },[comments,limit])

  useEffect(()=>{

    if(post && students){

      setOwner(students[post.owner])
      var emj = ''

      var images = []
      
      if(post.images){
          console.log(post.images)
          for(var i in post.images){
              images.push(post.images[i])
          }
      }

      setPostImages(images)

      if(post.reaction){
          setReactCount(Object.keys(post.reaction).length)
          var reacts ={
              'Like':0,
              'Love':0,
              'Haha':0,
              'Wow':0,
              'Sad':0,
              'Angry':0
          }

          for(var react in post.reaction){
              reacts[post.reaction[react]] += 1
          }

          let sortable = [];
          for (var r in reacts) {
              sortable.push([r, reacts[r]]);
          }

          sortable.sort(function(a, b) {
              return b[1] - a[1];
          });
          
          if(sortable[0][1] !== 0) emj += reactEmoji[sortable[0][0]]
          if(sortable[1][1] !== 0) emj += reactEmoji[sortable[1][0]]

          setEmojis(emj)
      }

    }

  },[post,students])

  function postReact(reaction){

    if(props.postKey){
      
      if(post.reaction && post.reaction[props.uid] && post.reaction[props.uid] === reaction){
          set(ref(forumDB,`${props.level}/posts/${props.postKey}/reaction/${props.uid}`),null).then(()=>{

          })
      }else{
          set(ref(forumDB,`${props.level}/posts/${props.postKey}/reaction/${props.uid}`),reaction).then(()=>{
            
          })
      }

    }

  }

  function verifyContent(){

    if($('#txtContent').text().trim().length > 0){
      setContent($('#txtContent').html())
    }else setContent('')
  }

  function publishComment(){

    if(content.length > 0){

      setProcessing(true)

      var datePosted = moment().format('MMMM, DD YYYY hh:mm:ss a')

      var body ={
        content:content,
        datePosted:datePosted,
        owner:props.uid
      }
      
      push(ref(forumDB,`${props.level}/comments/${props.postKey}`),body).then(()=>{

        var cc = post.comments?post.comments+1:1

        set(ref(forumDB,`${props.level}/posts/${props.postKey}/comments`),cc).then(()=>{
          setProcessing(false)
          $('#txtContent').html('')
          setContent('')

          var d = moment().format("MMMM DD, YYYY hh:mm:ss a");

          let notification = {
              icon:"fa fa-comment",
              image:student.profile.profilePictureURL,
              link:`/forum/${props.level.toLowerCase()}?id=${props.postKey}`,
              message:`<b>${student.profile.name.firstName} ${student.profile.name.lastName}</b> commented on your post: ${shortenDescription(content,30)}`,
              read:false,
              time:d
          }
          push(ref(studentDB,`notifications/${post.owner}`),notification)

        })

      })

      
      

    }

  }

  useEffect(()=>{

    if(content.length > 0){
      $('#txtPlaceholder').css('display','none')
    }else{
      $('#txtPlaceholder').css('display','block')
    }

    console.log(content)

  },[content])

  function setDefault(){
    setComments([])
    setFinalComments([])
  }

  function regulatePaste(e){

    e.preventDefault();

    var item = e.clipboardData.items[0];
    console.log(e.clipboardData.items)
 
    if (item.type.indexOf("image") === 0)
    {
      
    }else{

      let paste = (e.clipboardData || window.clipboardData).getData('text/html');

      const selection = window.getSelection();
      if (!selection.rangeCount) return;
      selection.deleteFromDocument();

      var newDiv = document.createElement('span')
      newDiv.innerHTML = paste

      var links = newDiv.getElementsByTagName('a')
      if(links.length > 0){
        
        for(var i = 0; i < links.length;i++){
          links[i].innerHTML = links[i].href
          links[i].target = '_blank'
        }

      }else{
        paste = (e.clipboardData || window.clipboardData).getData('text');
        newDiv.innerHTML = paste
      }

      selection.getRangeAt(0).insertNode(newDiv);
      selection.collapseToEnd();

    }
  
  }

  function deletePost(){

    setProcessing(true)
    var postID = deletingPost
    setDeletingPost(null)

    set(ref(forumDB,`${props.level}/posts/${postID}`),null).then(()=>{
        set(ref(forumDB,`${props.level}/comments/${postID}`),null).then(()=>{
          props.onHide()  
          setProcessing(false)
        })
    })

  }

  function deleteComment(){
    setProcessing(true)
    var commentID = deletingComment
    setDeletingComment(null)

    var c = post.comments - 1
    if(c <= 0) c = null

    set(ref(forumDB,`${props.level}/comments/${props.postKey}/${commentID}`),null).then(()=>{
      set(ref(forumDB,`${props.level}/posts/${props.postKey}/comments`),c).then(()=>{
        setProcessing(false)
      })
    })
  }

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onShow={()=>setDefault()}
        keyboard={false}
        backdrop='static'
      > 

        <Modal.Header className='bg-blue' closeButton>

          <h4 className='saira text-center text-white m-0 w-100'><i className='fa fa-comment'/> {post && owner && owner.profile.name.firstName}'s Post</h4>

        </Modal.Header>

        <Modal.Body className='bg-white-blue'>
        {post && props &&
        <div className='row justify-content-center'>
          <div className="col-12 forum-card-container modal-variant">

              {((student && (globalDevelopers.includes(student.profile.emailAddress) || globalCoaches.includes(student.profile.emailAddress))) || props.uid === post.owner) &&
              <div className="dropdown post-action-button">
                  <button className="text-dark pointer bg-transparent" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa-solid fa-ellipsis-vertical"></i></button>
                  <ul className="dropdown-menu saira" style={{transition:'0s'}}>
                      <li>
                          <p className="dropdown-item pointer text-danger" onClick={()=>setDeletingPost(props.postKey)}>
                              <i className="fa fa-trash"></i> Delete Post
                          </p>
                      </li>
                  </ul>
              </div>
              }

              <div className="row mb-3 px-3">
                  <div className='col-4 px-0' style={{width:'fit-content'}}>
                      <img className="profile-picture profile" src={owner?owner.profile.profilePictureURL:placeholder} alt="profile"/>
                  </div>
                  <div className="col-8 align-self-center">
                      <p className="align-self-center bold text-blue">{owner && owner.profile.name.firstName + ' ' + owner.profile.name.lastName} {owner && (globalDevelopers.includes(owner.profile.emailAddress) || globalCoaches.includes(owner.profile.emailAddress)) && <i className="fa fa-circle-check text-light-blue small"/>}</p>
                      <span className="text-muted small">{moment(post.datePosted).fromNow()}</span>

                      
                      {/*s.level === 'Standard' && <span className='small pill'><i className='fa fa-user'/> <span>Standard</span></span>}
                      {s.level === 'Elite' && <span className='small pill'><i className='fa fa-bolt'/> <span>Elite</span></span>}
                      {/*innerCircle !== 'N/A' && <span className='small pill'><i className='fa-brands fa-discourse'/> <span className='d-lg-inline d-none'>Inner Circle</span></span>}
                      {s &&  globalDevelopers.includes(s.profile.emailAddress) && <span className='small pill'><i className='fa fa-computer'/> <span className='d-lg-inline d-none'>Developer</span></span>}
                      {s &&  globalCoaches.includes(s.profile.emailAddress) && <span className='small pill'><i className='fa fa-star'/> <span className='d-lg-inline d-none'>Coach</span></span>*/}

                  </div>
              </div>

              <div className='row mt-3'>
                  {post.content &&
                  <div className='col-12'>
                      <p className='post-content' dangerouslySetInnerHTML={{__html:post.content}}></p>
                  </div>
                  }

                  {post.attachment &&
                  <div className='col-12 create-post-attachment-container py-3 px-4 mt-3'>
                      <a href={post.attachment.url} target='_blank' rel='noreferrer'><i className='fa fa-paperclip'/> {post.attachment.name}</a>
                  </div>
                  }

                  {postImages.length > 0 &&
                  <div className='col-12 mt-3 px-0'>
                      <div className="row justify-content-center">

                          {postImages.length === 1 &&
                          <div className="col-12 post-image" style={{backgroundImage:`url('${postImages[0]}')`}} onClick={()=>setSelectedImage(postImages[0])}>
                              <img src={postImages[0]} alt='post' style={{width:'100%',visibility:'hidden'}}/>
                          </div>
                          }

                          {postImages.length === 2 &&
                          <>
                              <div className="col-6 post-image" style={{backgroundImage:`url('${postImages[0]}')`}} onClick={()=>setSelectedImage(postImages[0])}>
                                  <img src={postImages[0]} alt='post' style={{width:'100%',visibility:'hidden'}}/>
                              </div>
                              <div className="col-6 post-image" style={{backgroundImage:`url('${postImages[1]}')`}} onClick={()=>setSelectedImage(postImages[1])}>
                                  <img src={postImages[1]} alt='post' style={{width:'100%',visibility:'hidden'}}/>
                              </div>
                          </>
                          }

                          {postImages.length === 3 &&
                          <>
                              <div className="col-6 post-image" style={{backgroundImage:`url('${postImages[0]}')`}} onClick={()=>setSelectedImage(postImages[0])}>
                                  <img src={postImages[0]} alt='post' style={{width:'100%',visibility:'hidden'}}/>
                              </div>
                              <div className="col-6 post-image" style={{backgroundImage:`url('${postImages[1]}')`}} onClick={()=>setSelectedImage(postImages[1])}>
                                  <img src={postImages[1]} alt='post' style={{width:'100%',visibility:'hidden'}}/>
                              </div>
                              <div className="col-12 post-image" style={{backgroundImage:`url('${postImages[2]}')`}} onClick={()=>setSelectedImage(postImages[2])}>
                                  <img src={postImages[2]} alt='post' style={{width:'100%',visibility:'hidden'}}/>
                              </div>
                          </>
                          }

                          {postImages.length === 4 &&
                          <>
                              <div className="col-6 post-image" style={{backgroundImage:`url('${postImages[0]}')`}} onClick={()=>setSelectedImage(postImages[0])}>
                                  <img src={postImages[0]} alt='post' style={{width:'100%',visibility:'hidden'}}/>
                              </div>
                              <div className="col-6 post-image" style={{backgroundImage:`url('${postImages[1]}')`}} onClick={()=>setSelectedImage(postImages[1])}>
                                  <img src={postImages[1]} alt='post' style={{width:'100%',visibility:'hidden'}}/>
                              </div>
                              <div className="col-6 post-image" style={{backgroundImage:`url('${postImages[2]}')`}} onClick={()=>setSelectedImage(postImages[2])}>
                                  <img src={postImages[2]} alt='post' style={{width:'100%',visibility:'hidden'}}/>
                              </div>
                              <div className="col-6 post-image" style={{backgroundImage:`url('${postImages[3]}')`}} onClick={()=>setSelectedImage(postImages[3])}>
                                  <img src={postImages[3]} alt='post' style={{width:'100%',visibility:'hidden'}}/>
                              </div>
                          </>
                          }

                      </div>
                  </div>
                  }
              </div>

              <div className="row mt-3">
                  
                  <div className="col-6 text-start">

                      {post.reaction &&
                      <p className="text-blue small">
                          <span className="reaction-count d-lg-inline d-none" onClick={()=>setSelectedReaction(props.postKey)}>
                              {emojis}
                              {post.reaction[props.uid]?
                              `You ${reactCount-1 > 0?`and ${reactCount-1} other/s.`:''}`
                              :
                              reactCount
                              } 
                          </span>
                          
                          <span className="reaction-count d-lg-none d-inline" onClick={()=>setSelectedReaction(props.postKey)}>
                            {emojis} {reactCount}
                          </span>
                      </p>
                      }

                  </div>

                  {post.comments &&
                  <div className="col-6 text-end">
                      <p className="small text-blue">{post.comments} <i className="fa fa-comment d-lg-none d-inline"/> <span className="d-lg-inline d-none">Comment/s</span></p>
                  </div>
                  }

                  <hr className="mb-1 mt-1"/>  

                  <div className="col-6 text-center post-action py-2 pointer">
                      {post.reaction && post.reaction[props.uid]?
                      <p className="animate__animated animate__tada bold" style={{color:`${reactColor[post.reaction[props.uid]]}`}} onClick={()=>postReact(post.reaction[props.uid])}><span>{reactEmoji[post.reaction[props.uid]]}</span> {post.reaction[props.uid]}</p>
                      :
                      <p className="text-blue" onClick={()=>postReact('Like')}><span style={{filter:'grayscale(1)'}}>👍</span> Like</p>
                      }

                      <div style={{position:'relative'}}>
                        <div className="react-container py-1 d-flex justify-content-center px-3 animate__animated animate__fadeIn" style={{bottom:'30px'}}>
                            <picture className="react-item" onClick={(e)=>postReact('Like')}>
                                <source srcset="https://fonts.gstatic.com/s/e/notoemoji/latest/1f44d_1f3fb/512.webp" type="image/webp"/>
                                <img src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f44d_1f3fb/512.gif" alt="👍" width="32" height="32"/>
                            </picture>
                            <picture className="react-item" onClick={(e)=>postReact('Love')}>
                                <source srcset="https://fonts.gstatic.com/s/e/notoemoji/latest/2764_fe0f/512.webp" type="image/webp"/>
                                <img src="https://fonts.gstatic.com/s/e/notoemoji/latest/2764_fe0f/512.gif" alt="❤" width="32" height="32"/>
                            </picture>
                            <picture className="react-item" onClick={(e)=>postReact('Haha')}>
                                <source srcset="https://fonts.gstatic.com/s/e/notoemoji/latest/1f606/512.webp" type="image/webp"/>
                                <img src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f606/512.gif" alt="😆" width="32" height="32"/>
                            </picture>
                            <picture className="react-item" onClick={(e)=>postReact('Wow')}>
                                <source srcset="https://fonts.gstatic.com/s/e/notoemoji/latest/1f62f/512.webp" type="image/webp"/>
                                <img src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f62f/512.gif" alt="😯" width="32" height="32"/>
                            </picture>
                            <picture className="react-item" onClick={(e)=>postReact('Sad')}> 
                                <source srcset="https://fonts.gstatic.com/s/e/notoemoji/latest/1f622/512.webp" type="image/webp"/>
                                <img src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f622/512.gif" alt="😢" width="32" height="32"/>
                            </picture>  
                            <picture className="react-item" onClick={(e)=>postReact('Angry')}>
                                <source srcset="https://fonts.gstatic.com/s/e/notoemoji/latest/1f620/512.webp" type="image/webp"/>
                                <img src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f620/512.gif" alt="😠" width="32" height="32"/>
                            </picture>
                        </div>
                      </div>
                      
                  </div>
                  <div className="col-6 text-center pointer post-action py-2">
                      <p className="text-blue"><i className="fa fa-comment"/> Comment</p>
                  </div>

                  <div className='col-12 mt-2'>
                    
                    {comments.length > limit &&
                    <div className='row px-4'>
                      <p className='underline text-blue mb-3 small pointer' onClick={()=>setLimit(limit+5)}>View Previous Comments</p>
                    </div>
                    }

                    {finalComments.map((c)=>{ 

                      var owner = students && students[c.owner]

                      return(
                      <div className='row comment-container mb-3 justify-content-center'>

                        <div className='col-1' style={{minWidth:'65px'}}>
                          <img src={owner?owner.profile.profilePictureURL:placeholder} alt='profile'/>
                        </div>
                        
                        <div className='col-lg-9 col-9 align-self-center text-blue'>
                          <div className='row'>
                            <div className='col-12 bubble px-lg-3 px-3'>
                              <p className='bold small'>{owner && `${owner.profile.name.firstName} ${owner.profile.name.lastName}`} {owner && (globalDevelopers.includes(owner.profile.emailAddress) || globalCoaches.includes(owner.profile.emailAddress)) && <i className="fa fa-circle-check text-light-blue align-self-center small"/>}</p>
                              <p className='comment-content' dangerouslySetInnerHTML={{__html:c.content}}></p>
                            </div>
                            <p className='small text-muted'>{moment(c.datePosted).fromNow()}</p>
                          </div>
                        </div>
                        
                        <div className='col-1 align-self-center d-lg-block d-none' style={{marginBottom:'20px'}}>
                        {((student && (globalDevelopers.includes(student.profile.emailAddress) || globalCoaches.includes(student.profile.emailAddress))) || props.uid === c.owner) &&

                          <div className="dropdown">
                            <button className="text-dark pointer bg-transparent" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa-solid fa-ellipsis-vertical"></i></button>
                            <ul className="dropdown-menu saira" style={{transition:'0s'}}>
                              <li>
                                  <p className="dropdown-item pointer text-danger" onClick={()=>setDeletingComment(c.key)}>
                                      <i className="fa fa-trash"></i> Delete Comment
                                  </p>
                              </li>
                            </ul>
                          </div>
                        }
                        </div>
                        

                      </div>
                      )
                    })}
                    

                  </div>

              </div>
          </div>
          <div className="col-12"/>
        </div>
        }

        </Modal.Body>

        <Modal.Footer className='bg-white-blue d-block'>
          <div className='row post-comment-container justify-content-center'>
            <div className='col-1' style={{width:'fit-content'}}>
              <img src={student?student.profile.profilePictureURL:placeholder} alt='profile'/>
            </div>
            <div className='col-lg-11 col-9 align-self-center bubble'>
              <div className='row'>
                <div className='col-12'>
                  <div style={{position:'relative'}}>
                    <p id='txtPlaceholder' className='content-placeholder' style={{position:'absolute',top:'0',userSelect:'none',zIndex:'0',pointerEvents:'none'}}>Write a comment...</p>
                  </div>
                  <p id='txtContent' onPaste={(e)=>regulatePaste(e)} onKeyUp={()=>verifyContent()} contentEditable></p>
                </div>
                <p className='text-end'>
                  {content?
                  <i className='fa fa-send text-light-blue pointer' onClick={()=>publishComment()}/>
                  :
                  <i className='fa fa-send text-muted'/>
                  }
                </p>
              </div>
            </div>
          </div>
          
        </Modal.Footer>

      </Modal>

      <ModalConfirmation show={deletingComment} onHide={()=>setDeletingComment(null)} onConfirm={()=>deleteComment()} title='<i class="fa fa-trash"/> <span class="bold">Delete Comment?</span>' message='Are you sure you want to delete the comment?'/>
      <ModalConfirmation show={deletingPost} onHide={()=>setDeletingPost(null)} onConfirm={()=>deletePost()} title='<i class="fa fa-trash"/> <span class="bold">Delete Post?</span>' message='Deleting a post will also delete its comments and reactions. Are you sure you want to proceed?'/>

      <ModalViewReaction show={selectedReaction} onHide={()=>setSelectedReaction(null)} postKey={selectedReaction} level={props.level}/>
      <ModalViewImage show={selectedImage} onHide={()=>setSelectedImage(null)} image={selectedImage}/>

      <ModalLoading show={processing}/>
    </>
  );
}