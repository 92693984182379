import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import $ from 'jquery'
import { hasCorrectEmail, toTitleCase } from '../../helper/formatter';
import ModalLoading from './ModalLoading';

export default function ModalFreeTraining(props) {

  
  const [processing,setProcessing] = useState(false)

  const [page,setPage] = useState(1);
  const [pageDone,setPageDone] = useState([[],[],[],[],[],[],[],[]]);

  const [fullName,setFirstName] = useState()
  //const [lastName,setLastName] = useState()

  const [emailAddress,setEmailAddress] = useState()

  useEffect(()=>{
    console.log(pageDone)
  },[pageDone])

  function verifyFullName(){

    var txtFullName = $('#txtFullName').val().trim();
    var errFirstName = $('#errFullName');
    
    errFirstName.text('')
    setFirstName(null);

    if(txtFullName.trim().length === 0) errFirstName.text('Blank spaces are not allowed.');
    else if(txtFullName.length <= 1) errFirstName.text('Atleast 2 Characters.');
    else setFirstName(toTitleCase(txtFullName));
    
  }

  /*
  function verifyLastName(){

    var txtLastName = $('#txtLastName').val().trim();
    var errLastName = $('#errLastName');
    
    errLastName.text('')
    setLastName(null);

    if(txtLastName.trim().length === 0) errLastName.text('Blank spaces are not allowed.');
    else if(txtLastName.length <= 1) errLastName.text('Atleast 2 Characters.');
    else setLastName(toTitleCase(txtLastName));

  }*/

  function verifyEmailAddress(){

    var txtEmailAddress = $('#txtEmailAddress').val();
    var errEmailAddress = $('#errEmailAddress');
    
    txtEmailAddress = txtEmailAddress.trim()

    errEmailAddress.text('')
    setEmailAddress(null);

    if(txtEmailAddress.trim().length === 0) errEmailAddress.text('Blank spaces are not allowed.');
    else if(!hasCorrectEmail(txtEmailAddress.trimEnd())) errEmailAddress.text('Invalid Email Format.');
    else setEmailAddress(txtEmailAddress)  
  }

  function submitForm(){

    setProcessing(true)
    $('#formSendTraining').trigger('submit');

  }

  function toggleCheckbox(element, p, i, isRadio){

    let inp = $(element.target.querySelector('input'))
    $(inp).trigger('click');

    let pd = [...pageDone];
    
    if(isRadio) pd[p] = [];
    pd[p][i] = inp[0].checked;


    setPageDone(pd);

  }

  function conditionalNext(canContinue){
    if(canContinue) setPage(page+1);
    else{
      setProcessing(true)
      window.location.href = '/sorry'
    }
  }

  return (
    <>
      <Modal
        {...props}
        size="fullscreen"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >

        <Modal.Body className='bg-white-blue p-0'>

          <i className='fa fa-times pointer' onClick={()=>props.onHide()} style={{position:'absolute',top:15,right:25,fontSize:'32px'}}/>

          <div className='row justify-content-center h-100 m-0'>       
            <div className='col-12 d-flex p-0 m-0 py-5 justify-content-center'>
              <div className='row align-self-center w-100 justify-content-center'>
                
                {page === 1 &&
                <div className='col-lg-8 col-10 training-questionnaire p-0'>
                  <h2 className='black text-center text-light-blue mb-3 question animate__animated animate__fadeInDown' style={{fontSize:'calc(0.75vw + 30px)'}}>Bakit gusto mo matutunan ang Forex Trading? 👇</h2>

                  
                  <div className='row justify-content-center'>

                    <div onClick={(e)=>toggleCheckbox(e,1,0,false)} className={`col-lg-5 col-12 choice d-flex align-self-center animate__animated animate__fadeInUp ${pageDone[1][0] && 'selected'}`}>
                      <div className='align-self-center'>
                        <input defaultChecked={pageDone[1][0]} type='checkbox' className='p-0'/>
                        <p>Magkaroon ng side hustle or extra income.</p>
                      </div>
                    </div>

                    <div onClick={(e)=>toggleCheckbox(e,1,1,false)} className={`col-lg-5 col-12 choice d-flex align-self-center animate__animated animate__fadeInUp animate__delay-1s ${pageDone[1][1] && 'selected'}`}>
                      <div className='d-flex align-self-center'>
                        <input defaultChecked={pageDone[1][1]} type='checkbox' className='p-0'/>
                        <p>Makaipon ng capital para sa business.</p>
                      </div>
                    </div>

                    <div onClick={(e)=>toggleCheckbox(e,1,2,false)} className={`col-lg-5 col-12 choice d-flex align-self-center animate__animated animate__fadeInUp animate__delay-2s ${pageDone[1][2] && 'selected'}`}>
                      <div className='d-flex align-self-center'>
                        <input defaultChecked={pageDone[1][2]} type='checkbox' className='p-0'/>
                        <p>Gusto ko na mag-resign sa trabaho.</p>
                      </div>
                    </div>

                    <div onClick={(e)=>toggleCheckbox(e,1,3,false)} className={`col-lg-5 col-12 choice d-flex align-self-center animate__animated animate__fadeInUp animate__delay-3s ${pageDone[1][3] && 'selected'}`}>
                      <div className='d-flex align-self-center'>
                        <input defaultChecked={pageDone[1][3]} type='checkbox' className='p-0'/>
                        <p>Makaahon sa mga pinagkakautangan.</p>
                      </div>
                    </div>

                    <div className='col-12 text-center'>
                      {pageDone[1].includes(true)?
                      <button onClick={()=>setPage(2)} class="btn-solid bold mt-3 animate__animated animate__fadeIn px-5 py-2" style={{width:'fit-content', fontSize:'18px'}}>Next <i className='fa fa-arrow-right-long ms-1'/></button>
                      :
                      <button disabled class="btn-solid bold mt-3 animate__animated animate__fadeIn px-5 py-2" style={{width:'fit-content', fontSize:'18px'}}>Next <i className='fa fa-arrow-right-long ms-1'/></button>
                      }
                    </div>
                  </div>
                </div>
                }

                {page === 2 &&
                <div className='col-lg-8 col-10 training-questionnaire p-0'>
                  <h2 className='black text-center text-light-blue mb-3 question animate__animated animate__fadeInDown' style={{fontSize:'calc(0.75vw + 30px)'}}>Magkano ang monthly income na gusto mong kitain? 💸</h2>

                  
                  <div className='row justify-content-center'>

                    <div onClick={(e)=>toggleCheckbox(e,2,0,true)} className={`col-lg-5 col-12 choice d-flex align-self-center animate__animated animate__fadeInUp ${pageDone[2][0] && 'selected'}`}>
                      <div className='align-self-center'>
                        <input defaultChecked={pageDone[2][0]} name='income' type='radio' className='p-0'/>
                        <p>10,000 - 20,000</p>
                      </div>
                    </div>

                    <div onClick={(e)=>toggleCheckbox(e,2,1,true)} className={`col-lg-5 col-12 choice d-flex align-self-center animate__animated animate__fadeInUp animate__delay-1s ${pageDone[2][1] && 'selected'}`}>
                      <div className='d-flex align-self-center'>
                        <input defaultChecked={pageDone[2][1]} name='income' type='radio' className='p-0'/>
                        <p>30,000 - 50,000</p>
                      </div>
                    </div>

                    <div onClick={(e)=>toggleCheckbox(e,2,2,true)} className={`col-lg-5 col-12 choice d-flex align-self-center animate__animated animate__fadeInUp animate__delay-2s ${pageDone[2][2] && 'selected'}`}>
                      <div className='d-flex align-self-center'>
                        <input defaultChecked={pageDone[2][2]} name='income' type='radio' className='p-0'/>
                        <p>60,000 - 100,000</p>
                      </div>
                    </div>

                    <div onClick={(e)=>toggleCheckbox(e,2,3,true)} className={`col-lg-5 col-12 choice d-flex align-self-center animate__animated animate__fadeInUp animate__delay-3s ${pageDone[2][3] && 'selected'}`}>
                      <div className='d-flex align-self-center'>
                        <input defaultChecked={pageDone[2][3]} name='income' type='radio' className='p-0'/>
                        <p>200,000 - 500,000</p>
                      </div>
                    </div>

                    <div className='col-12 text-center'>
                      <button onClick={()=>setPage(1)} class="btn-transparent bold mt-3 animate__animated animate__fadeIn px-5 py-2" style={{width:'fit-content', fontSize:'18px'}}><i className='fa fa-arrow-left-long me-1'/> Back</button>

                      {pageDone[2].includes(true)?
                      <button onClick={()=>setPage(3)} class="btn-solid bold mt-3 animate__animated animate__fadeIn px-5 py-2" style={{width:'fit-content', fontSize:'18px'}}>Next <i className='fa fa-arrow-right-long ms-1'/></button>
                      :
                      <button disabled class="btn-solid bold mt-3 animate__animated animate__fadeIn px-5 py-2" style={{width:'fit-content', fontSize:'18px'}}>Next <i className='fa fa-arrow-right-long ms-1'/></button>
                      }
                    </div>
                  </div>
                </div>
                }

                {page === 3 &&
                <div className='col-lg-8 col-10  training-questionnaire p-0'>
                  <h2 className='black text-center text-light-blue mb-3 question animate__animated animate__fadeInDown' style={{fontSize:'calc(0.75vw + 30px)'}}>Saan mo ilalaan ang perang maiipon mo mula sa Forex trading? 💰</h2>

                  
                  <div className='row justify-content-center'>

                    <div onClick={(e)=>toggleCheckbox(e,3,0)} className={`col-lg-5 col-12 choice d-flex align-self-center animate__animated animate__fadeInUp ${pageDone[3][0] && 'selected'}`}>
                      <div className='align-self-center'>
                        <input defaultChecked={pageDone[3][0]} name='income' type='checkbox' className='p-0'/>
                        <p>Bibilhin ko yung dream house at car ko.</p>
                      </div>
                    </div>

                    <div onClick={(e)=>toggleCheckbox(e,3,1)} className={`col-lg-5 col-12 choice d-flex align-self-center animate__animated animate__fadeInUp animate__delay-1s ${pageDone[3][1] && 'selected'}`}>
                      <div className='d-flex align-self-center'>
                        <input defaultChecked={pageDone[3][1]} name='income' type='checkbox' className='p-0'/>
                        <p>Magre-resign na ako sa trabaho.</p>
                      </div>
                    </div>

                    <div onClick={(e)=>toggleCheckbox(e,3,2)} className={`col-lg-5 col-12 choice d-flex align-self-center animate__animated animate__fadeInUp animate__delay-2s ${pageDone[3][2] && 'selected'}`}>
                      <div className='d-flex align-self-center'>
                        <input defaultChecked={pageDone[3][2]} name='income' type='checkbox' className='p-0'/>
                        <p>Magtatayo ako ng sarili kong negosyo.</p>
                      </div>
                    </div>

                    <div onClick={(e)=>toggleCheckbox(e,3,3)} className={`col-lg-5 col-12 choice d-flex align-self-center animate__animated animate__fadeInUp animate__delay-3s ${pageDone[3][3] && 'selected'}`}>
                      <div className='d-flex align-self-center'>
                        <input defaultChecked={pageDone[3][3]} name='income' type='checkbox' className='p-0'/>
                        <p>Iaahon ko sa hirap ang sarili ko at pamilya ko.</p>
                      </div>
                    </div>

                    <div className='col-12 text-center'>
                      <button onClick={()=>setPage(2)} class="btn-transparent bold mt-3 animate__animated animate__fadeIn px-5 py-2" style={{width:'fit-content', fontSize:'18px'}}><i className='fa fa-arrow-left-long me-1'/> Back</button>

                      {pageDone[3].includes(true)?
                      <button onClick={()=>setPage(4)} class="btn-solid bold mt-3 animate__animated animate__fadeIn px-5 py-2" style={{width:'fit-content', fontSize:'18px'}}>Next <i className='fa fa-arrow-right-long ms-1'/></button>
                      :
                      <button disabled class="btn-solid bold mt-3 animate__animated animate__fadeIn px-5 py-2" style={{width:'fit-content', fontSize:'18px'}}>Next <i className='fa fa-arrow-right-long ms-1'/></button>
                      }
                    </div>
                  </div>
                </div>
                }

                {page === 4 &&
                <div className='col-lg-8 col-10  training-questionnaire p-0'>
                  <h2 className='black text-center text-light-blue mb-3 question animate__animated animate__fadeInDown' style={{fontSize:'calc(0.75vw + 30px)'}}>Willing ka bang maglaan ng TIME, ENERGY, at EFFORT para lang matutunan ang Forex? 🕟⚡</h2>

                  
                  <div className='row justify-content-center'>

                    <div onClick={(e)=>toggleCheckbox(e,4,0,true)} className={`col-lg-5 col-12 choice d-flex align-self-center animate__animated animate__fadeInUp ${pageDone[4][0] && 'selected'}`}>
                      <div className='align-self-center'>
                        <input defaultChecked={pageDone[4][0]} name='income' type='radio' className='p-0'/>
                        <p>Yes, willing ako at magsisikap ako.</p>
                      </div>
                    </div>

                    <div onClick={(e)=>toggleCheckbox(e,4,1,true)} className={`col-lg-5 col-12 choice d-flex align-self-center animate__animated animate__fadeInUp animate__delay-1s ${pageDone[4][1] && 'selected'}`}>
                      <div className='d-flex align-self-center'>
                        <input defaultChecked={pageDone[4][1]} name='income' type='radio' className='p-0'/>
                        <p>No, wala akong oras para dyan.</p>
                      </div>
                    </div>

                    
                    <div className='col-12 text-center'>
                      <button onClick={()=>setPage(3)} class="btn-transparent bold mt-3 animate__animated animate__fadeIn px-5 py-2" style={{width:'fit-content', fontSize:'18px'}}><i className='fa fa-arrow-left-long me-1'/> Back</button>

                      {pageDone[4].includes(true)?
                      <button onClick={()=>conditionalNext(pageDone[4][0])} class="btn-solid bold mt-3 animate__animated animate__fadeIn px-5 py-2" style={{width:'fit-content', fontSize:'18px'}}>Next <i className='fa fa-arrow-right-long ms-1'/></button>
                      :
                      <button disabled class="btn-solid bold mt-3 animate__animated animate__fadeIn px-5 py-2" style={{width:'fit-content', fontSize:'18px'}}>Next <i className='fa fa-arrow-right-long ms-1'/></button>
                      }
                    </div>
                  </div>
                </div>
                }

                {page === 5 &&
                <div className='col-lg-8 col-10  training-questionnaire p-0'>
                  <h2 className='black text-center text-light-blue mb-3 question animate__animated animate__fadeInDown' style={{fontSize:'calc(0.75vw + 30px)'}}>Magkano ang perang willing mong ilaan para aralin at magsimula sa Forex Trading? 📈</h2>

                  
                  <div className='row justify-content-center'>

                    <div onClick={(e)=>toggleCheckbox(e,5,0,true)} className={`col-lg-5 col-12 choice d-flex align-self-center animate__animated animate__fadeInUp ${pageDone[5][0] && 'selected'}`}>
                      <div className='align-self-center'>
                        <input defaultChecked={pageDone[5][0]} name='income' type='radio' className='p-0'/>
                        <p>₱0 Wala akong pera para dyan</p>
                      </div>
                    </div>

                    <div onClick={(e)=>toggleCheckbox(e,5,1,true)} className={`col-lg-5 col-12 choice d-flex align-self-center animate__animated animate__fadeInUp animate__delay-1s ${pageDone[5][1] && 'selected'}`}>
                      <div className='d-flex align-self-center'>
                        <input defaultChecked={pageDone[5][1]} name='income' type='radio' className='p-0'/>
                        <p>₱5,000 - ₱10,000</p>
                      </div>
                    </div>

                    <div onClick={(e)=>toggleCheckbox(e,5,2,true)} className={`col-lg-5 col-12 choice d-flex align-self-center animate__animated animate__fadeInUp animate__delay-2s ${pageDone[5][2] && 'selected'}`}>
                      <div className='d-flex align-self-center'>
                        <input defaultChecked={pageDone[5][2]} name='income' type='radio' className='p-0'/>
                        <p>₱10,000 - ₱20,000</p>
                      </div>
                    </div>

                    <div onClick={(e)=>toggleCheckbox(e,5,3,true)} className={`col-lg-5 col-12 choice d-flex align-self-center animate__animated animate__fadeInUp animate__delay-3s ${pageDone[5][3] && 'selected'}`}>
                      <div className='d-flex align-self-center'>
                        <input defaultChecked={pageDone[5][3]} name='income' type='radio' className='p-0'/>
                        <p>₱30,000 - ₱50,000</p>
                      </div>
                    </div>

                    <div className='col-12 text-center'>
                      <button onClick={()=>setPage(4)} class="btn-transparent bold mt-3 animate__animated animate__fadeIn px-5 py-2" style={{width:'fit-content', fontSize:'18px'}}><i className='fa fa-arrow-left-long me-1'/> Back</button>

                      {pageDone[5].includes(true)?
                      <button onClick={()=>conditionalNext(!pageDone[5][0])} class="btn-solid bold mt-3 animate__animated animate__fadeIn px-5 py-2" style={{width:'fit-content', fontSize:'18px'}}>Next <i className='fa fa-arrow-right-long ms-1'/></button>
                      :
                      <button disabled class="btn-solid bold mt-3 animate__animated animate__fadeIn px-5 py-2" style={{width:'fit-content', fontSize:'18px'}}>Next <i className='fa fa-arrow-right-long ms-1'/></button>
                      }
                    </div>
                  </div>
                </div>
                }


                {page === 6 &&
                <div className='col-lg-8 col-10  training-questionnaire p-0'>
                  <h2 className='black text-center text-light-blue mb-3 question animate__animated animate__fadeInDown' style={{fontSize:'calc(0.75vw + 30px)'}}>Ready ka na ba sa ONE-HOUR EXCLUSIVE TRAINING na ibibigay ko sayo? 🧑🏻‍🏫</h2>

                  
                  <div className='row justify-content-center'>

                    <div onClick={(e)=>toggleCheckbox(e,6,0,true)} className={`col-lg-5 col-12 choice d-flex align-self-center animate__animated animate__fadeInUp ${pageDone[6][0] && 'selected'}`}>
                      <div className='align-self-center'>
                        <input defaultChecked={pageDone[6][0]} name='income' type='radio' className='p-0'/>
                        <p>Yes, panonoorin ko na yung training ngayon.</p>
                      </div>
                    </div>

                    <div onClick={(e)=>toggleCheckbox(e,6,1,true)} className={`col-lg-5 col-12 choice d-flex align-self-center animate__animated animate__fadeInUp animate__delay-1s ${pageDone[6][1] && 'selected'}`}>
                      <div className='d-flex align-self-center'>
                        <input defaultChecked={pageDone[6][1]} name='income' type='radio' className='p-0'/>
                        <p>No, next time na lang at wala pa akong oras dyan.</p>
                      </div>
                    </div>


                    <div className='col-12 text-center'>
                      <button onClick={()=>setPage(5)} class="btn-transparent bold mt-3 animate__animated animate__fadeIn px-5 py-2" style={{width:'fit-content', fontSize:'18px'}}><i className='fa fa-arrow-left-long me-1'/> Back</button>

                      {pageDone[6].includes(true)?
                      <button onClick={()=>conditionalNext(pageDone[6][0])} class="btn-solid bold mt-3 animate__animated animate__fadeIn px-5 py-2" style={{width:'fit-content', fontSize:'18px'}}>Next <i className='fa fa-arrow-right-long ms-1'/></button>
                      :
                      <button disabled class="btn-solid bold mt-3 animate__animated animate__fadeIn px-5 py-2" style={{width:'fit-content', fontSize:'18px'}}>Next <i className='fa fa-arrow-right-long ms-1'/></button>
                      }
                    </div>
                  </div>
                </div>
                }


                {page === 7 &&
                <div className='col-lg-8 col-10  training-questionnaire p-0'>
                  <h2 className='black text-center text-light-blue mb-3 question animate__animated animate__fadeInDown' style={{fontSize:'calc(0.75vw + 30px)'}}>Okay lang ba sayo na 24 HOURS lang AVAILABLE yung training at kapag nag-skip ka ay NO REPLAY na ito? ⌛</h2>

                  
                  <div className='row justify-content-center'>

                    <div onClick={(e)=>toggleCheckbox(e,7,0,true)} className={`col-lg-5 col-12 choice d-flex align-self-center animate__animated animate__fadeInUp ${pageDone[7][0] && 'selected'}`}>
                      <div className='align-self-center'>
                        <input defaultChecked={pageDone[7][0]} name='income' type='radio' className='p-0'/>
                        <p>Yes, tatapusin ko ito hanggang dulo.</p>
                      </div>
                    </div>

                    <div onClick={(e)=>toggleCheckbox(e,7,1,true)} className={`col-lg-5 col-12 choice d-flex align-self-center animate__animated animate__fadeInUp animate__delay-1s ${pageDone[7][1] && 'selected'}`}>
                      <div className='d-flex align-self-center'>
                        <input defaultChecked={pageDone[7][1]} name='income' type='radio' className='p-0'/>
                        <p>No, hindi ko kayang tapusin.</p>
                      </div>
                    </div>


                    <div className='col-12 text-center'>
                      <button onClick={()=>setPage(6)} class="btn-transparent bold mt-3 animate__animated animate__fadeIn px-5 py-2" style={{width:'fit-content', fontSize:'18px'}}><i className='fa fa-arrow-left-long me-1'/> Back</button>

                      {pageDone[7].includes(true)?
                      <button onClick={()=>conditionalNext(pageDone[7][0])} class="btn-solid bold mt-3 animate__animated animate__fadeIn px-5 py-2" style={{width:'fit-content', fontSize:'18px'}}>LAST STEP <i className='fa fa-arrow-right-long ms-1'/></button>
                      :
                      <button disabled class="btn-solid bold mt-3 animate__animated animate__fadeIn px-5 py-2" style={{width:'fit-content', fontSize:'18px'}}>LAST STEP <i className='fa fa-arrow-right-long ms-1'/></button>
                      }
                    </div>
                  </div>
                </div>
                }

                {page === 8 &&
                <div className='col-lg-8 col-10 p-0'>
                  <h2 className='black text-center text-light-blue mb-4 question animate__animated animate__fadeInDown' style={{fontSize:'calc(0.75vw + 30px)'}}>Saan ko isesend ang FREE TRAINING mo? 🎥💯</h2>
                  <form id='formSendTraining' method="POST" action="https://tradingcircle.activehosted.com/proc.php" target="_top" class="form_class_VOErZPhjk8Xc optin_form_for_video js_kartra_trackable_object" data-kt-type="optin" data-kt-value="VOErZPhjk8Xc" data-kt-owner="Nr5oAKJg" accept-charset="UTF-8">
                              
                    <input type="hidden" name="u" value="1" data-name="u"/>
                    <input type="hidden" name="f" value="1" data-name="f"/>
                    <input type="hidden" name="s" data-name="s"/>
                    <input type="hidden" name="c" value="0" data-name="c"/>
                    <input type="hidden" name="m" value="0" data-name="m"/>
                    <input type="hidden" name="act" value="sub" data-name="act"/>
                    <input type="hidden" name="v" value="2" data-name="v"/>

                    <div className='animate__animated animate__fadeInUp'>
                      <label className='text-light-blue saira bold mb-0' style={{fontSize:'24px'}}>Email Address</label>
                      <input style={{fontSize:'24px'}} type="text" class="form-control" name="email" id='txtEmailAddress' required onChange={()=>verifyEmailAddress()}/>
                      <p className='small text-error mb-3' id='errEmailAddress'></p>
                    </div>

                    <div className='animate__animated animate__fadeInUp animate__delay-1s'>
                      <label className='text-light-blue saira bold mb-0' style={{fontSize:'24px'}}>Full Name</label>
                      <input style={{fontSize:'24px'}} type="text" class="form-control" name="name" id='txtFullName' required onChange={()=>verifyFullName()}/>
                      <p className='small text-error mb-4' id='errFullName'></p>
                    </div>

                    {fullName && emailAddress?
                    <button class='btn-solid w-100 py-2 mt-3 animate__animated animate__fadeIn' style={{fontSize:'18px'}} onClick={()=>submitForm()}><i className='fa fa-send'/> Send Free Training</button>
                    :
                    <button class='btn-solid w-100 py-2 mt-3 animate__animated animate__fadeIn' style={{fontSize:'18px'}} disabled><i className='fa fa-send'/> Send Free Training</button>
                    }

                  </form>
                </div>
                }


              </div>
            </div>

          </div>

          
          

          

          {/*
          <form id='formSendTraining' method="post" action="https://app.kartra.com/process/add_lead/VOErZPhjk8Xc" target="_top" class="form_class_VOErZPhjk8Xc optin_form_for_video js_kartra_trackable_object" data-kt-type="optin" data-kt-value="VOErZPhjk8Xc" data-kt-owner="Nr5oAKJg" accept-charset="UTF-8">
            
            <label className='txt-primary saira bold mb-0'>Email Address</label>
            <input type="text" class="form-control" name="email" id='txtEmailAddress' required onChange={()=>verifyEmailAddress()}/>
            <p className='small text-error mb-4' id='errEmailAddress'></p>

            <label className='txt-primary saira bold mb-0'>First Name</label>
            <input type="text" class="form-control" name="first_name" id='txtFirstName' required onChange={()=>verifyFirstName()}/>
            <p className='small text-error mb-2' id='errFirstName'></p>

            <label className='txt-primary saira bold mb-0'>Last Name</label>
            <input type="text" class="form-control" name="last_name" id='txtLastName' required onChange={()=>verifyLastName()}/>
            <p className='small text-error mb-2' id='errLastName'></p>

            {firstName && lastName && emailAddress?
            <button class='btn-primary w-100 py-2 mt-3' onClick={()=>submitForm()}><i className='fa fa-send'/> Send Free Training</button>
            :
            <button class='btn-primary w-100 py-2 mt-3' disabled><i className='fa fa-send'/> Send Free Training</button>
            }

          </form>
          */}

        </Modal.Body>

      </Modal>

      <ModalLoading show={processing}/>

    </>
  );
}