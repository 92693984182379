import Modal from 'react-bootstrap/Modal';

import $ from 'jquery'
import { useState } from 'react';
import ModalLoading from '../general/ModalLoading';
import blueFullLogo from '../../img/logo/blue-full-logo.png';
import { onAuthStateChanged} from 'firebase/auth';
import { studentAuth, studentDB } from '../../api/firebase-student-config';
import { useEffect } from 'react';
import { onValue, ref, set } from 'firebase/database';


export default function ModalEliteTraining(props) {

  var [processing,setProcessing] = useState(false)

  var [uid,setUID] = useState()
  var [emailAddress,setEmailAddress] = useState()
  var [firstName,setFirstName] = useState()
  var [lastName,setLastName] = useState()
  var [phone,setPhone] = useState()

  var [sent] = useState(false);

  

  useEffect(()=>{
    onAuthStateChanged(studentAuth,(user)=>{
      if(user){
        setEmailAddress(user.email)
        onValue(ref(studentDB,`students/${user.uid}`),(snapshot)=>{
          if(snapshot.exists()){
            setFirstName(snapshot.val().profile.name.firstName)
            setLastName(snapshot.val().profile.name.lastName)
            setPhone(snapshot.val().profile.contactNumber)
            setUID(user.uid);
          }
        })
      }
    })
  },[]) 

  function sendTraining(){

    setProcessing(true)
    
    
    set(ref(studentDB,`students/${uid}/special`),'UPGRADE').then(()=>{
      $("#sendTrainingForm").trigger("submit");
    })
    
    
    
  }

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop='static'
      >
        
        <Modal.Header className='bg-blue' closeButton>
        
          <h4 className='saira text-center text-white m-0'>Send Free Advanced Training</h4>

        </Modal.Header>

        <Modal.Body className='bg-white-blue'>

          <div className='row justify-content-center py-3'>

            {sent?
            <div className='col-10 animate__animated animate__fadeIn'>

              <div className='row mb-2 justify-content-center'>
                <div className='col-12 text-center'>
                  <img src={blueFullLogo} alt='logo' width="50%" />
                </div>
              </div>

              <p className='text-center'><i className='fa fa-check text-success' style={{fontSize:"72px",marginBottom:"-50px"}}/></p>
              <p className='text-center text-success'>Password Reset Email was sent to <b>({emailAddress})</b></p>
            
            </div>
            :
            <div className='col-10 animate__animated animate__fadeIn'>

              <div className='row mb-4 justify-content-center'>
                <div className='col-12 text-center'>
                  <img src={blueFullLogo} alt='logo' width="50%" />
                </div>
              </div>

              <form method='POST' action="https://tradingcircle.activehosted.com/proc.php" id="sendTrainingForm">
                
                <input type="hidden" name="u" value="7" data-name="u"/>
                <input type="hidden" name="f" value="7" data-name="f"/>
                <input type="hidden" name="s" data-name="s"/>
                <input type="hidden" name="c" value="0" data-name="c"/>
                <input type="hidden" name="m" value="0" data-name="m"/>
                <input type="hidden" name="act" value="sub" data-name="act"/>
                <input type="hidden" name="v" value="2" data-name="v"/>

                <p className='bold text-blue mt-3'>Full Name</p>
                <input name='name' id="txtName" value={firstName + " " + lastName} readOnly className='form-control text-muted' type="text"/>

                <p className='bold text-blue mt-3'>Email Address</p>
                <input name='email' id="txtEmailAddress" value={emailAddress} readOnly className='form-control text-muted' type="text"/>

                <p className='bold text-blue mt-3'>Contact Number</p>
                <input name='phone' id="txtPhoneNumber" value={phone} readOnly className='form-control text-muted' type="text"/>
                
              </form>
              
              {/*
              <form method='POST' action="https://app.kartra.com/process/add_lead/508jpB9W6LlF" id="sendTrainingForm">

                <p className='bold text-blue mt-3'>First Name</p>
                <input name='first_name' id="txtName" value={firstName} readOnly className='form-control text-muted' type="text"/>

                <p className='bold text-blue mt-3'>First Name</p>
                <input name='last_name' id="txtName" value={lastName} readOnly className='form-control text-muted' type="text"/>

                <p className='bold text-blue mt-3'>Email Address</p>
                <input name='email' id="txtEmailAddress" value={emailAddress} readOnly className='form-control text-muted' type="text"/>

                <p className='bold text-blue mt-3'>Contact Number</p>
                <input name='phone' id="txtPhoneNumber" value={phone} readOnly className='form-control text-muted' type="text"/>

              </form>*/}
            


              <button onClick={()=>sendTraining()} className='form-control btn-blue py-2 mt-4'><i class="fa-solid fa-send me-1"></i>Send Advanced Training</button>
             

            
            </div>

            }
            

          </div>

        </Modal.Body>

        <Modal.Footer className='bg-blue'>
        </Modal.Footer>

      </Modal>

      <ModalLoading show={processing} />

    </>
  );
}