import {NavLink} from 'react-router-dom'

export const MainCarousel = ()=>{
    return(
        <div className="row animate__animated animate__fadeIn">
            <div className="col-12" style={{position:'relative'}}>
                <div className="item row d-lg-block d-none" style={{background:'url("https://wallpaperaccess.com/full/1304036.jpg")',backgroundPosition:'center',backgroundRepeat:'no-repeat',backgroundSize:'cover'}}>
                    <div style={{background:'rgba(0,0,0,0.8)',padding:'calc(15.475rem + 2vh) calc(5.475rem + 5vw)'}}>
                        <h3 className="text-light-blue black saira m-0" style={{fontSize:'calc(0.475rem + 1vw)'}} data-aos='fade-up'>JOIN OUR TRADING COMMUNITY</h3>
                        <h2 className="text-white black m-0" style={{fontSize:'calc(1.475rem + 2vw)'}} data-aos='fade-up'>Learn anytime anywhere<br/>stop the hype just focus<br/>on the learning.</h2>
                        <NavLink to='/login'><button className="btn-solid py-3 px-5 mt-5" data-aos='fade-up'><i className="fa-solid fa-handshake-angle"/> Sign In</button></NavLink>
                    </div>
                </div>
            
                <div className="item row d-lg-none d-block" style={{background:'url("https://wallpaperaccess.com/full/1304036.jpg")',backgroundPosition:'center',backgroundRepeat:'no-repeat',backgroundSize:'cover'}}>
                    <div className='text-center ' style={{background:'rgba(0,0,0,0.8)',padding:'100px 25px'}}>
                        <h3 className="text-light-blue black saira m-0" style={{fontSize:'calc(0.75rem + 0.5vw)'}} data-aos='fade-up'>JOIN OUR TRADING COMMUNITY</h3>
                        <h2 className="text-white black m-0" style={{fontSize:'calc(1rem + 1.5vw)'}} data-aos='fade-up'>Learn anytime anywhere<br/>stop the hype just focus<br/>on the learning.</h2>
                        <NavLink to='/login'><button className="btn-solid py-2 px-3 mt-4" style={{fontSize:'14px'}} data-aos='fade-up'><i className="fa-solid fa-handshake-angle"/> Sign In</button></NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}