
import { Helmet } from 'react-helmet-async';
import {onAuthStateChanged} from 'firebase/auth'
import {onValue, ref, update,push,set} from 'firebase/database'
import {getDownloadURL, ref as sref, uploadBytes} from 'firebase/storage'
import {studentAuth, studentDB, studentStorage} from '../../api/firebase-student-config'
import ModalLoading from '../../components/general/ModalLoading';

import profilePlaceholder from '../../img/placeholder/profile-placeholder.png';
import imagePlaceholder from '../../img/placeholder/image-placeholder.png';

import moment from 'moment'
import { useEffect, useState } from 'react';
import ModalFreeTraining from '../../components/general/ModalFreeTraining';
import blackWordLogo from '../../img/logo/black-word-logo.png';
import { FunnelFooter } from '../../components/landing/FunnelFooter';

import paypal from '../../img/logo/paypal.png'
import bdo from '../../img/logo/bdo.png'
import chinaBank from '../../img/logo/china-bank.png'
import coinsPH from '../../img/logo/coins-ph.png'
import gcash from '../../img/logo/gcash.png'
import landBank from '../../img/logo/landbank.png'
import maya from '../../img/logo/maya.png'
import bpi from '../../img/logo/bpi.png'
import securityBank from '../../img/logo/security-bank.png'
import unionBank from '../../img/logo/union-bank.png'

import $ from 'jquery'
import { getFileExt } from '../../helper/formatter';

import { funnelDB } from '../../api/firebase-funnel-config';


export const WealthCircleCheckout = () =>{

    const [pageID,setPageID] = useState()

    useEffect(()=>{

        if(pageID){
            var now = moment().format('MMMM DD YYYY')

            if(pageID){

                $.getJSON("https://api64.ipify.org?format=json", function(e){

                    var cleanedIP = String(e.ip).split('.').join('-')

                    push(ref(funnelDB,`analytics/impressions/${pageID}/${now}`),e.ip).then(()=>{

                        set(ref(funnelDB,`analytics/unique/${pageID}/${now}/${cleanedIP}`),'VISITED').then(()=>{

                        })

                    })

                });

            }
        }else setPageID('wealth-circle-checkout')

    },[pageID])

    var[subscribing,setSubscribing] = useState(false);

    const [processing,setProcessing] = useState(false);
    const [student,setStudent] = useState()
    const [studentInfo,setStudentInfo] = useState()

    const [proofURL,setProofURL] = useState(imagePlaceholder)
    const [status,setStatus] = useState(`<span class='text-danger'>Unpaid</span>`)
    const [reason,setReason] = useState()

    const [redirectURL] = useState('/')
    const [type] = useState('wealthcircle')

    const [canSubmit,setCanSubmit] = useState(true)

    const [qualified,setQualified] = useState(true)


    useEffect(()=>{

        onAuthStateChanged(studentAuth,(s)=>{
            
            if(s) setStudent(s)
            else{
                setStudent(null)
                setStudentInfo(null)
                window.location.href = '/'
            }

        },[])

    },[])

    useEffect(()=>{

        if(student){

            onValue(ref(studentDB,`students/${student.uid}`),(snapshot)=>{
                if(snapshot.exists()){
                    setStudentInfo(snapshot.val())

                    if(!snapshot.val().level || snapshot.val().level === 'Standard'){
                        setQualified(false)
                    }

                    if(snapshot.val().manualPayment){

                        if(type === 'wealthcircle'){

                            if(snapshot.val().manualPayment.wealthcircle.status === 'Pending') setStatus(`<span class='text-pending'>Pending</span>`)
                            else if(snapshot.val().manualPayment.wealthcircle.status === 'Rejected'){
                                setStatus(`<span class='text-error'>Rejected</span>`)
                                setReason(snapshot.val().manualPayment.wealthcircle.reason)
                            }else if(snapshot.val().manualPayment.wealthcircle.status === 'Verified'){
                                window.location.href = '/'
                                setStatus(`<span class='text-success'>Verified</span>`)
                                setCanSubmit(false)
                            }

                            setProofURL(snapshot.val().manualPayment.wealthcircle.proof)
                        }



                    }else{
                        setStatus(`<span class='text-danger'>Unpaid</span>`)
                        setProofURL(imagePlaceholder)
                    }

                }
            })

        }
        

    },[student,type])

    function openProof(){
        if(proofURL === imagePlaceholder) return
        window.open(proofURL)
    }

    function uploadProof(){

        setProcessing(true)
        
        var file = $('#fileUploadProof').prop('files')[0]
        var txtErr = $("#errProfilePicture")
        txtErr.text('')

        if(!file){
            setProcessing(false)
            return;
        }else{
            if(getFileExt(file.name).toLowerCase() !== 'png' && getFileExt(file.name).toLowerCase() !== 'jpg' && getFileExt(file.name).toLowerCase() !== 'jpeg'){
                
                txtErr.text('PNG and JPG Files are the only accepted files.')
                $('#fileUploadProof').val(null)
                setProcessing(false)
                return
            }
        }

        setStatus("<span class='text-danger'>Unpaid</span>")

        uploadBytes(sref(studentStorage,`students/paymentProof/${student.uid}`),file).then(()=>{

            getDownloadURL(sref(studentStorage,`students/paymentProof/${student.uid}`)).then((url)=>{
                
                setProofURL(url)

                var type = `wealthcircle`
                var body = {}

                var time = moment().format()
                
                if(type === 'wealthcircle') body['wealthcircle'] = {'status':'Pending','proof':url,'date':time}

                update(ref(studentDB,`students/${student.uid}/manualPayment`),body).then(()=>{

                    setProcessing(false)
                    setReason(null)
                    sendAdminMail()

                }).catch((error)=>{
                    console.log(error)
                    setProcessing(false)
                })
                
            })

        }).catch((error)=>{
            console.log(error)
            setProcessing(false)
        })

    }

    function sendAdminMail(){

        /*
        setProcessing(true)

        axios.post('https://us-central1-trading-circle-ph-admin.cloudfunctions.net/app/api/email',{
          subject:`New Enrollment - ${type==='standard'?'NEW':'UPGRADE'}`,
          email:'payment@tradingcircle.com.ph',
          html:`        
          <div style='display:table;margin:0 auto;'>
            <div style='max-width:500px;margin:0 auto;'>
              
                <p style='text-align:center;'>
                    <img src='https://tradingcircle-ph.web.app/static/media/black-word-logo.1bf10b226314dd33a7ab.png' width='350px'/>
                </p>
        
                <hr/>
        
                <h2 style='white-space:pre;color:#01245A;margin-bottom:0;'>New Enrollment - ${type==='standard'?'NEW':'UPGRADE'}</h2>
                <p style='color:#01245A;margin-top:0;margin-bottom:0;'>Attached below is the payment proof of the enrollee.</p>

                <table style='color:#01245A;border-collapse:collapse;'>
                    <tr>
                        <th colspan='2' style='color:white;background-color:#01245A;border: 1px solid #ddd;text-align:center;padding:8px;'>Account Details</th>
                    </tr>
                    <tr>
                        <th style='border: 1px solid #ddd;text-align:left;padding:8px;'>Full Name</th>
                        <td style='border: 1px solid #ddd;text-align:left;padding:8px;'>${studentInfo.profile.name.firstName} ${studentInfo.name.lastName}</td>
                    </tr>
                    <tr>
                        <th style='border: 1px solid #ddd;text-align:left;padding:8px;'>Email Address</th>
                        <td style='border: 1px solid #ddd;text-align:left;padding:8px;'>$ ${studentInfo.profile.emailAddress}</td>
                    </tr>
                    <tr>
                        <th style='border: 1px solid #ddd;text-align:left;padding:8px;'>Payment Type</th>
                        <td style='border: 1px solid #ddd;text-align:left;padding:8px;'>${type==='standard'?'NEW':'UPGRADE'}</td>
                    </tr>
                </table>

                <img src='${proofURL}'/>
        
            </div>
          </div>
          `
        }).then((res)=>{
    
          if(res.status === 200){
            setProcessing(false)
          }
    
        })*/
    
    }

    return(
        <>
            <Helmet>
                <title>Trading Circle - Checkout</title>
                <script>
                    window.fbq("track", "WealthCirclePayment");
                </script>
            </Helmet>
            
            <div className="container-fluid animate__animated animate__fadeIn px-lg-2 px-4 py-4">



                <div className='row justify-content-center mt-lg-3 mt-0 text-center'>

                    <div className='col-12 text-center mb-4'>
                        <img className='d-none d-lg-inline darkmode-logo' src={blackWordLogo} width='20%' alt='logo'/>
                        <img className='d-inline d-lg-none darkmode-logo' src={blackWordLogo} width='70%' alt='logo'/>
                        <div className='row justify-content-center'>
                            <div className='col-lg-6 col-12'>
                                <hr className='blue-hr my-3' style={{opacity:'0.5'}}/>
                            </div>
                        </div>
                        <h3 className='black text-blue' style={{fontSize:'calc(1rem + 0.75vw)'}}>Wealth Circle</h3>
                        <div className='row justify-content-center'>
                            <div className='col-lg-7 col-11'>
                                <h4 className='bold' style={{fontSize:'calc(0.75rem + 0.5vw)'}}>Please ensure that you type the correct details, and validate these before authorizing the payments from your mobile banking apps, e-wallets, or remittances</h4>
                            </div>
                        </div>
                    </div>
                    
                    <div className='col-lg-5 funnel-container'>

                        <div className='row justify-content-center my-4' style={{rowGap:'50px'}}>

                            <div className='col-lg-7 col-11' data-aos='fade-up'>
                                <img src={gcash} width='80%' alt='logo'/>
                                <h5><b>Account Name:</b> Alex Jose Balingit</h5>
                                <h5><b>Account Number:</b> 0929 853 5283</h5>
                                {/*<h5><b>Account Name:</b> Aleli Joy Balingit</h5>
                                <h5><b>Account Number:</b> 0927 577 3805</h5>*/}
                                {/*<h5><b>Account Name:</b> Aljay Balingit</h5>
                                <h5><b>Account Number:</b> 0920 971 0611</h5>*/}
                            </div>

                            <div className='col-lg-7 col-11' data-aos='fade-up'>
                                <img src={paypal} width='80%' alt='logo'/>
                                <button className='px-3 py-3 w-100' onClick={()=>window.open('https://www.paypal.com/paypalme/tradingcircle/10405')}>Click Here to Pay Using Paypal/Credit Card</button>
                                <p className='small bold mt-2'>(Note: 3.9% + ₱15 PayPal fees apply)</p>
                            </div>

                            <div className='col-lg-7 col-11' data-aos='fade-up'>
                                <img src={bdo} width='80%' alt='logo'/>
                                <h5><b>Account Name:</b> Alex Jose Balingit</h5>
                                <h5><b>Account Number:</b> 0056 4034 7652</h5>
                            </div>

                            <div className='col-lg-7 col-11' data-aos='fade-up'>
                                <img src={chinaBank} width='80%' alt='logo'/>
                                <h5><b>Account Name:</b> Alex Jose Balingit</h5>
                                <h5><b>Account Number:</b> 1268 0202 1864</h5>
                            </div>

                            <div className='col-lg-7 col-11' data-aos='fade-up'>
                                <img src={coinsPH} width='80%' alt='logo'/>
                                <h5><b>Account Name:</b> Alex Jose Balingit</h5>
                                <h5><b>Account Number:</b> 0929 853 5283</h5>
                            </div>

                            <div className='col-lg-7 col-11' data-aos='fade-up'>
                                <img src={landBank} width='80%' alt='logo'/>
                                <h5><b>Account Name:</b> Alex Jose Balingit</h5>
                                <h5><b>Account Number:</b> 2796 2042 76</h5>
                            </div>

                            <div className='col-lg-7 col-11' data-aos='fade-up'>
                                <img src={maya} width='80%' alt='logo'/>
                                <h5><b>Account Name:</b> Alex Jose Balingit</h5>
                                <h5><b>Account Number:</b> 09298535283</h5>
                            </div>

                            <div className='col-lg-7 col-11' data-aos='fade-up'>
                                <img src={bpi} width='80%' alt='logo'/>
                                <h5><b>Account Name:</b> Alex Jose Balingit</h5>
                                <h5><b>Account Number:</b> 4719 3229 51</h5>
                            </div>

                            <div className='col-lg-7 col-11' data-aos='fade-up'>
                                <img src={securityBank} width='80%' alt='logo'/>
                                <h5><b>Account Name:</b> Alex Jose Balingit</h5>
                                <h5><b>Account Number:</b> 00000 5691 5885</h5>
                            </div>

                            <div className='col-lg-7 col-11' data-aos='fade-up'>
                                <img src={unionBank} width='80%' alt='logo'/>
                                <h5><b>Account Name:</b> Alex Jose Balingit</h5>
                                <h5><b>Account Number:</b> 1094 2909 4115   </h5>
                            </div>

                        </div>

                    </div>

                    <div className='col-lg-5 funnel-container'>

                        <div className='row justify-content-center my-4'>

                            <div className='col-12 align-self-center'>
                                <div className='row justify-content-center'>
                                    <div className='col-lg-12 justify-content-center text-center align-self-center'>
                                        <img style={{borderRadius:"100rem"}} src={studentInfo?studentInfo.profile.profilePictureURL:profilePlaceholder} alt='profile' width="75px"/>
                                        <p className='mt-2 mb-1 text-center text-blue bold'>{studentInfo?studentInfo.profile.name.firstName + ' ' + studentInfo.profile.name.lastName:''}</p>
                                        <p className='mb-4'>{studentInfo && !canSubmit?'You may now access the website by clicking the button below.':studentInfo?'Please upload a screenshot of the payment proof so Trading Circle can verify it.':<span>{type === 'Standard' && <><b>Enroll</b> or</>} <b>Log in</b> to your account to confirm your payment.</span>}</p>
                                        <hr className='mb-4 mt-0'/>
                                    </div>

                                    <div className='col-lg-12 justify-content-center text-center'>
                                        
                                        {studentInfo?
                                        <div className='row justify-content-center'>
                                            <h3 className='saira bold text-blue'>Wealth Circle Payment Proof</h3>
                                            <div className='col-12 justify-content-center d-flex'>
        
                                                <div id='imgProof' className={`card-container ${proofURL !== imagePlaceholder && 'pointer'}`} style={{
                                                    backgroundImage:`url('${proofURL}')`,
                                                    backgroundPosition:'center center', 
                                                    backgroundSize:'cover',
                                                    backgroundRepeat:'no-repeat',
                                                    width:'500px',height:'300px'}}
                                                    onClick={()=>openProof()}
                                                />

                                            </div>
                                            {proofURL !== imagePlaceholder && <span className='mt-1 text-muted small'>*Tap/Click image to view</span>}

                                            <div className='col-lg-9 col-10 mt-3'>
                                                {canSubmit && qualified && 
                                                <>
                                                    <input className='form-control' type='file' accept='image/png,image/jpg,image/jpeg' id='fileUploadProof' onChange={()=>uploadProof()}/>
                                                    <small id="errProfilePicture" class="form-text text-danger text-center mb-3 mt-2"></small>
                                                </>
                                                }
                                                {qualified?
                                                <>
                                                    <h5 className='saira mt-3'>STATUS: <span dangerouslySetInnerHTML={{__html:status}}></span></h5>
                                                    <p className='text-error'>{reason}</p>
                                                    {status && status.includes('Pending') && <p className='text-success'><i className='fa fa-check'/> Payment Proof Sent</p>}
                                                    {status && status.includes('Pending') && <p className='text-muted small'>*You will be notified here and on your email ({studentInfo.profile.emailAddress})</p>}
                                                </> 
                                                :
                                                <>
                                                    <h5 className='saira mt-3'>STATUS: <span className='text-error'>NOT ELIGIBLE FOR UPGRADE</span></h5>
                                                    <p className='text-error'>You are not an Elite Member yet.</p>
                                                </>
                                                }
                                                {!canSubmit && <a href='http://tradingcircle.com.ph/' target='_top'><button className='btn-solid px-3 py-2 w-100 mt-3'><i className='fa fa-check'/> Access Website</button></a>}
                                            </div>

                                            <p className='text-muted small mt-3'>{reason && "*Please resubmit the correct payment proof."}</p>
                                        </div>
                                        :
                                        <div className='row justify-content-center'>
                                            <div className='col-lg-8 col-10'>
                                                <a href={redirectURL} target='_top'><button className='px-3 py-2 btn-solid w-100'> <i className='fa fa-sign-in me-2'/>Enroll / Log In</button></a>
                                            </div>
                                        </div>
                                        }
                                    </div> 

                                </div>
                            </div>


                            <div className='col-lg-9 col-11 card-container py-5 px-lg-5 px-4 text-start mt-4'>
                                <h1 className='text-light-blue text-center black mb-0'>WEALTH CIRCLE</h1>
                                <h5 className='bold text-center'>Unlimited One-on-One</h5>
                                <h5 className='bold text-center small' style={{opacity:'0.5'}}>Maximize the unlimited one-on-one for the whole 2024.</h5>
                                <hr/>

                                <ul>
                                    <li className='mb-4'><b>UNLIMITED ONE-ON-ONE:</b> Be one of the members of Wealth Circle by having exclusive one-on-one with our top coaches.</li>
                                    <li className='mb-4'><b>PROGRESS CHECKING:</b> By having one-on-one, you will get tracked especially your progress in one whole year.</li>
                                    <li className='mb-4'><b>SET YOUR OWN SCHEDULE:</b> You will be the one to set your own schedule on your own availability. The coaches will adjust for you.</li>
                                    <li className='mb-4'><b>CHOOSE THE TOPIC YOU WANT:</b> From the basic to advanced you are free to choose the topic you want to consult and be discussed during the session.</li>
                                    <li className='mb-4'><b>RECORDED SESSION:</b> The one-on-one will also be recorded for the student's personal benefit and advantage.</li>
                                </ul>

                                <p className='text-danger text-center'><del>Total Value: ₱ 50,000</del></p>
                                <h2 className='text-center text-light-blue'>Price Today: <b>₱ 3,997 ONLY</b></h2>
                                

                            </div>

                        </div>

                    </div>

                </div>

            </div>

            <FunnelFooter/>
            
            <ModalLoading show={processing}/>
            <ModalFreeTraining show={subscribing} onHide={()=>setSubscribing(false)}/>
        </>
    );

}