import { Helmet } from "react-helmet-async"
import { Footer } from "../../components/academy/Footer"
import { Header } from "../../components/general/Header"

import marketAnalysis from '../../img/icon/market-analysis.png'
import trend from '../../img/icon/trend.png'
import bank from '../../img/icon/bank.png'
import ModalEliteTraining from "../../components/academy/ModalEliteTraining"
import { useEffect, useState } from "react"
import { NavLink, useSearchParams } from "react-router-dom"

export const WhatsNext = ()=>{

    var [sending,setSending] = useState(false)
    var [success,setSuccess] = useState(false)
    let [searchParams] = useSearchParams();
    
    useEffect(() => {
      if(searchParams.get("success")){
        setSuccess(searchParams.get("success"))
      }
    }, [searchParams])

    return(
        <>
            <Helmet>
                <title>Trading Circle - What's Next</title>
            </Helmet>

            <div className="container-fluid animate__animated animate__fadeIn p-4">
                
                {success?

                <div className='row justify-content-center text-center animate__animated animate__fadeIn' style={{height:"74.9vh"}}>
                    <div className="col-12 align-self-center d-lg-block d-none">
                        <p><i className="fa-solid fa-envelope-circle-check text-blue" style={{fontSize:"120px"}}></i></p>
                        <h2 className="black text-blue m-0" style={{fontSize:"96px"}}>Thank You!</h2>
                        <h4 className="text-light-blue saira">An email has been sent to you with the link to the Free Advanced Training.</h4>
                        <h6 className="bold text-blue saira" style={{opacity:"0.5"}}>Check your spam folder if you haven’t received the email.</h6>
                        <NavLink to='/module'><button className="btn-blue px-5 py-2 mt-4"><i className="fa fa-check"/> Proceed</button></NavLink>
                    </div>

                    <div className="col-12 align-self-center d-lg-none d-block">
                        <p><i className="fa-solid fa-envelope-circle-check text-blue" style={{fontSize:"54px"}}></i></p>
                        <h2 className="black text-blue m-0" style={{fontSize:"36px"}}>Thank You!</h2>
                        <h6 className="text-light-blue saira" style={{fontSize:"14px"}}>An email has been sent to you with the link to the Free Advanced Training.</h6>
                        <h6 className="bold text-blue saira" style={{opacity:"0.5", fontSize:"12px"}}>Check your spam folder if you haven’t received the email.</h6>
                        <NavLink to='/module'><button className="btn-blue px-5 py-2 mt-4" style={{fontSize:"12px"}}><i className="fa fa-check"/> Proceed</button></NavLink>
                    </div>
                </div>
                :
                <>
                    <Header title="What's Next"/>
                    <div className='row justify-content-center mt-5'>
                        <div className="col-lg-8">
                            <h4 className="text-white bold text-center bg-blue p-3 mb-3 d-none d-lg-block" style={{borderRadius:'25px'}} data-aos='fade-down'>The Secrets that Massively Skyrocketed My Profits in Trading</h4>
                            <h6 className="text-white bold text-center bg-blue p-3 mb-3 d-block d-lg-none" style={{borderRadius:'25px'}} data-aos='fade-down'>The Secrets that Massively Skyrocketed My Profits in Trading</h6>
                            
                            <div className="video-container">
                                <iframe className='d-lg-block' src="https://player.vimeo.com/video/720948633?h=f3ae346e73&badge=0&autopause=0&player_id=0&app_id=58479" title="Invitation To Advance"/>
                            </div>
                        </div>

                        <div className="col-lg-9 col-12">
                            <div className='row justify-content-center d-flex mt-5 mb-5' style={{gap:"40px"}}>
                            
                                <div className='col-lg-3 col-10 card-container p-5 text-center secret-container' data-aos='fade-up' lang="en">
                                    <img className='mb-3' src={trend} alt='icon' style={{width:'60%'}}/>
                                    <p className='text-justify text-indent saira'><b>Secret #1:</b> Learn the <b>secrets of Professional Traders</b> so you can model them and increase your win rate up to 90%.</p>
                                </div>

                                <div className='col-lg-3 col-10 card-container p-5 text-center secret-container' data-aos='fade-up'>
                                    <img className='mb-3' src={marketAnalysis} alt='icon' style={{width:'60%'}}/>
                                    <p className='text-justify text-indent saira'><b>Secret #2:</b> Get more accurate entries by <b>analyzing the economics</b> and fundamental side of the Forex Market.</p>
                                </div>

                                <div className='col-lg-3 col-10 card-container p-5 text-center secret-container' data-aos='fade-up'>
                                    <img className='mb-3' src={bank} alt='icon' style={{width:'60%'}}/>
                                    <p className='text-justify text-indent saira'><b>Secret #3:</b> <b>Spot where banks and institutions enter</b> so you can ride with them at the same time avoiding stop hunts.</p>
                                </div>

                                <div className="row justify-content-center">
                                    <div className="col-11 text-center">
                                        <button className="btn-solid py-3 px-5 w-100" data-aos='fade-up' onClick={()=>setSending(true)}><i className="fa-solid fa-handshake-angle"/> Access Free Advanced Training</button>
                                        <p className="text-muted mt-2 text-start ms-lg-4 ms-1 d-lg-block d-none" data-aos='fade-up' style={{fontSize:'14px'}}>*Click the button above to send the Free Training directly to your Email Address.</p>
                                        <p className="text-muted mt-2 text-start ms-lg-4 ms-1 d-lg-none d-block" data-aos='fade-up' style={{fontSize:'12px'}}>*Click the button above to send the Free Training directly to your Email Address.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                }

               

                
            </div>
            <Footer/>
            <ModalEliteTraining show={sending} onHide={()=>setSending(false)}/>
        </>
    )
}