export const Footer = () =>{

    return(

        <footer className="container-fluid mt-5 px-5 py-3" style={{borderRadius:'25px 25px 0 0'}}>

            <div className="row">
                <div className='col-lg-6 text-lg-start text-center'>
                    <p className='text-white'>&copy; Trading Circle 2023, <span className='text-muted'>All Rights Reserved</span></p>
                </div>
                <div className='col-lg-6 text-lg-end text-center mt-1 mt-lg-0'>
                    <p className='text-white'><span className='text-muted'>Powered By </span> SDPT Solutions</p>
                </div>
            </div>

        </footer>

    );

}