
import { onAuthStateChanged } from 'firebase/auth';
import { onValue, ref } from 'firebase/database';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { studentAuth, studentDB } from '../../api/firebase-student-config';
import { Footer } from '../../components/academy/Footer';

import {Header} from '../../components/general/Header'

import analysisTemplate from '../../img/career/analysis-template.webp';
import compoundingPlan from '../../img/career/compounding-plan.webp';
import newsScoring from '../../img/career/news-scoring.webp';
import tradersReport from '../../img/career/traders-report.webp';
import tradingJournal from '../../img/career/trading-journal.webp';
import tradingPlanCommitment from '../../img/career/trading-plan-commitment.webp';
import tradingRulesTemplates from '../../img/career/trading-rules-templates.webp';
import tradingSystemTemplate from '../../img/career/trading-system-template.webp';


export const Career = () =>{

    var [student,setStudent] = useState(null);

    useEffect(()=>{

        onAuthStateChanged(studentAuth,(student)=>{
      
            if(student){
        
              onValue(ref(studentDB,"students/"+student.uid),(snapshot)=>{
                if(snapshot.exists()){
                    setStudent(snapshot.val());
                }
              })
        
            }
        
          })

    },[])

    return(
        <>
            <Helmet>
                <title>Trading Circle - Career</title>
            </Helmet>
            
            <div className="container-fluid animate__animated animate__fadeIn p-4">

                <Header title='Career'/>

                <div className='row justify-content-center mb-5 d-flex mt-5 px-lg-5 px-0' style={{gap:"50px"}}>
                    
                    <div className='col-lg-4 col-11' style={{maxWidth:"375px"}} data-aos="fade-up">

                        <div className='row justify-content-center'>

                            <div className='col-12 card-container-debossed px-5 py-4 text-center' style={{minHeight:"511px"}}>
                                <img className='pb-3' src={compoundingPlan} width="50%" alt='icon'/>
                                <h4 className='saira text-blue text-center bold'>One-Month Compounding Plan</h4>
                                <p className='text-blue text-justify mb-4 mt-3'>Build your concrete plan for your initial capital. This template will help you define your target pips and profit in one month. Knowing your target profit every trade is crucial for your growth as a trader.</p>
                            </div> 
                        
                            <div className='col-10' style={{marginTop:"-25px"}}>
                                <a target="_blank" rel="noreferrer" href='https://firebasestorage.googleapis.com/v0/b/tradingcircle-ph.appspot.com/o/career%2FOne-Month%20Compounding%20Plan.xlsx?alt=media&token=3a830478-8fab-4d70-a47a-6ecb78e69109'><button className='form-control btn-blue py-3'><span><i className='fa fa-download ms-1'/> Download</span></button></a>
                            </div>

                        </div>

                    </div>

                    <div className='col-lg-4 col-11' style={{maxWidth:"375px"}} data-aos="fade-up">

                        <div className='row justify-content-center'>

                            <div className='col-12 card-container-debossed px-5 py-4 text-center' style={{minHeight:"511px"}}>
                                <img className='pb-3' src={tradingPlanCommitment} width="50%" alt='icon'/>
                                <h4 className='saira text-blue text-center bold'>Trading Plan Commitment</h4>
                                <p className='text-blue text-justify mb-4 mt-3'>Being a Forex trader is not easy unless we see it as a long and sustainable career. This template will clearly define our goals as a trader and as a person.    </p>
                            </div> 
                        
                            <div className='col-10' style={{marginTop:"-25px"}}>
                                <a target="_blank" rel="noreferrer" href='https://firebasestorage.googleapis.com/v0/b/tradingcircle-ph.appspot.com/o/career%2FTrading%20Plan%20Commitment.xlsx?alt=media&token=2c73e7be-1603-4cf6-98f3-1f4ca062228f'><button className='form-control btn-blue py-3'><span><i className='fa fa-download ms-1'/> Download</span></button></a>
                            </div>

                        </div>

                    </div>

                    <div className='col-lg-4 col-11' style={{maxWidth:"375px"}} data-aos="fade-up">

                        <div className='row justify-content-center'>

                            <div className='col-12 card-container-debossed px-5 py-4 text-center' style={{minHeight:"511px"}}>
                                <img className='pb-3' src={tradingSystemTemplate} width="50%" alt='icon'/>
                                <h4 className='saira text-blue text-center bold'>Trading System Template</h4>
                                <p className='text-blue text-justify mb-4 mt-3'>Completing 100 trades is crucial in determining your win rate and will eventually build your confidence. Please watch Chapter 7 first before downloading this file.     </p>
                            </div> 
                        
                            <div className='col-10' style={{marginTop:"-25px"}}>
                                <a target="_blank" rel="noreferrer" href='https://firebasestorage.googleapis.com/v0/b/tradingcircle-ph.appspot.com/o/career%2FTrading%20System%20Tempate.xlsx?alt=media&token=cddf72e9-f855-4ce6-aae0-969118ce1201 '><button className='form-control btn-blue py-3'><span><i className='fa fa-download ms-1'/> Download</span></button></a>
                            </div>

                        </div>

                    </div>

                    <div className='col-lg-4 col-11' style={{maxWidth:"375px"}} data-aos="fade-up">

                        <div className='row justify-content-center'>

                            <div className='col-12 card-container-debossed px-5 py-4 text-center' style={{minHeight:"511px"}}>
                                <img className='pb-3' src={tradingRulesTemplates} width="50%" alt='icon'/>
                                <h4 className='saira text-blue text-center bold'>Trading Rules Template</h4>
                                <p className='text-blue text-justify mb-4 mt-3'>In order to spot a quality trade, you need to lay down a clear set of rules that will act as your checklist before entering. Please watch Chapter 7 before downloading this file.     </p>
                            </div> 
                        
                            <div className='col-10' style={{marginTop:"-25px"}}>
                                <a target="_blank" rel="noreferrer" href='https://firebasestorage.googleapis.com/v0/b/tradingcircle-ph.appspot.com/o/career%2FTrading%20Rules%20Template.xlsx?alt=media&token=11d3d48e-13a0-4c00-abe0-9249953e0268'><button className='form-control btn-blue py-3'><span><i className='fa fa-download ms-1'/> Download</span></button></a>
                            </div>

                        </div>

                    </div>
                    
                    <div className='col-lg-4 col-11' style={{maxWidth:"375px"}} data-aos="fade-up">

                        <div className='row justify-content-center'>

                            <div className='col-12 card-container-debossed px-5 py-4 text-center' style={{minHeight:"511px"}}>
                                <img className='pb-3' src={tradingJournal} width="50%" alt='icon'/>
                                <h4 className='saira text-blue text-center bold'>Trading Journal</h4>
                                <p className='text-blue text-justify mb-4 mt-3'>Journal will help us review our trades and spot our areas of improvement. Just input the necessary data and it will compute everything for you. Please watch Chapter 7 before downloading this file.       </p>
                            </div> 
                        
                            <div className='col-10' style={{marginTop:"-25px"}}>
                                <a target="_blank" rel="noreferrer" href='https://firebasestorage.googleapis.com/v0/b/tradingcircle-ph.appspot.com/o/career%2FTrading%20Journal%20Template.xlsx?alt=media&token=534c89d8-e0ef-49f2-984f-dd80b20cfce6'><button className='form-control btn-blue py-3'><span><i className='fa fa-download ms-1'/> Download</span></button></a>
                            </div>

                        </div>

                    </div>

                    <div className='col-lg-4 col-11' style={{maxWidth:"375px"}} data-aos="fade-up">

                        <div className='row justify-content-center'>

                            <div className='col-12 card-container-debossed px-5 py-4 text-center' style={{minHeight:"511px"}}>
                                <img className='pb-3' src={analysisTemplate} width="50%" alt='icon'/>
                                <h4 className='saira text-blue text-center bold'>Macroeconomic Analysis Template</h4>
                                <p className='text-blue text-justify mb-4 mt-3'>This template will make it convenient for you in analyzing all these economic data together to make your analysis stronger. Please watch Chapter 9 before downloading this file.    </p>
                            </div> 
                        
                            <div className='col-10' style={{marginTop:"-25px"}}>
                                {student && student.level === 'Elite'?
                                <a target="_blank" rel="noreferrer" href='https://firebasestorage.googleapis.com/v0/b/tradingcircle-ph.appspot.com/o/career%2FELITE-Macroeconomic%20Analysis%20Template.xlsx?alt=media&token=dce69061-2e1d-4acc-99db-d869b5ba5f09'><button className='form-control btn-blue py-3'><span><i className='fa fa-download ms-1'/> Download</span></button></a>
                                :
                                <button className='form-control btn-blue py-3' disabled><span><i className='fa fa-lock ms-1'/> Elite Only</span></button>
                                }
                            </div>

                        </div>

                    </div>

                    <div className='col-lg-4 col-11' style={{maxWidth:"375px"}} data-aos="fade-up">

                        <div className='row justify-content-center'>

                            <div className='col-12 card-container-debossed px-5 py-4 text-center' style={{minHeight:"511px"}}>
                                <img className='pb-3' src={tradersReport} width="50%" alt='icon'/>
                                <h4 className='saira text-blue text-center bold'>Commitment of Traders Report</h4>
                                <p className='text-blue text-justify mb-4 mt-3'>This template will automatically compute the data of COT based on the position supplied by reporting firms. Watch Chapter 9 first before downloading this file.       </p>
                            </div> 
                        
                            <div className='col-10' style={{marginTop:"-25px"}}>
                                {student && student.level === 'Elite'?
                                <a target="_blank" rel="noreferrer" href='https://firebasestorage.googleapis.com/v0/b/tradingcircle-ph.appspot.com/o/career%2FELITE-Commitment%20of%20Traders%20Report%20Template.xlsx?alt=media&token=b9375962-25d0-4406-ad1b-4343eda6cef9'><button className='form-control btn-blue py-3'><span><i className='fa fa-download ms-1'/> Download</span></button></a>
                                :
                                <button className='form-control btn-blue py-3' disabled><span><i className='fa fa-lock ms-1'/> Elite Only</span></button>
                                }
                            </div>

                        </div>

                    </div>

                    <div className='col-lg-4 col-11' style={{maxWidth:"375px"}} data-aos="fade-up">

                        <div className='row justify-content-center'>

                            <div className='col-12 card-container-debossed px-5 py-4 text-center' style={{minHeight:"511px"}}>
                                <img className='pb-3' src={newsScoring} width="50%" alt='icon'/>
                                <h4 className='saira text-blue text-center bold'>Weekly News Scoring</h4>
                                <p className='text-blue text-justify mb-4 mt-3'>This template is part of the trilogy of the fundamental analysis. This is where you put the overall strength of a currency based on the news. Please watch Chapter 9 first before downloading this file. </p>
                            </div> 
                        
                            <div className='col-10' style={{marginTop:"-25px"}}>
                                {student && student.level === 'Elite'?
                                <a target="_blank" rel="noreferrer" href='https://firebasestorage.googleapis.com/v0/b/tradingcircle-ph.appspot.com/o/career%2FELITE-Weekly%20News%20Scoring.xlsx?alt=media&token=7ff5cb67-fc68-4487-af98-0ccac2018ddd'><button className='form-control btn-blue py-3'><span><i className='fa fa-download ms-1'/> Download</span></button></a>
                                :
                                <button className='form-control btn-blue py-3' disabled><span><i className='fa fa-lock ms-1'/> Elite Only</span></button>
                                }
                            </div>

                        </div>

                    </div>

                </div>

            </div>

            <Footer/>
        </>
    );

}