
import { Helmet } from 'react-helmet-async';

import moment from 'moment'
import { useEffect, useState } from 'react';
import ModalFreeTraining from '../../components/general/ModalFreeTraining';
import blackWordLogo from '../../img/logo/black-word-logo.png';
import { FunnelFooter } from '../../components/landing/FunnelFooter';

import {set,ref,push} from 'firebase/database'
import { funnelDB } from '../../api/firebase-funnel-config';
import $ from 'jquery'
import { NavLink } from 'react-router-dom';

export const Sorry = (props) =>{

    var[subscribing,setSubscribing] = useState(false);

    const [pageID,setPageID] = useState()



    useEffect(()=>{

        if(pageID){
            var now = moment().format('MMMM DD YYYY')

            if(pageID){

                $.getJSON("https://api64.ipify.org?format=json", function(e){

                    var cleanedIP = String(e.ip).split('.').join('-')

                    push(ref(funnelDB,`analytics/impressions/${pageID}/${now}`),e.ip).then(()=>{

                        set(ref(funnelDB,`analytics/unique/${pageID}/${now}/${cleanedIP}`),'VISITED').then(()=>{

                        })

                    })

                });

            }
        }else setPageID('sorry')

    },[pageID])

    return(
        <>
            <Helmet>
                <title>Trading Circle - Sorry</title>
            </Helmet>
            <div className="container-fluid animate__animated animate__fadeIn px-lg-2 px-4 py-4">



                <div className='row justify-content-center mt-lg-3 mt-0'>

                    <div className='col-12 text-center mb-5'>
                        <img className='d-none d-lg-inline darkmode-logo' src={blackWordLogo} width='20%' alt='logo'/>
                        <img className='d-inline d-lg-none darkmode-logo' src={blackWordLogo} width='70%' alt='logo'/>
                        <div className='row justify-content-center'>
                            <div className='col-lg-6 col-12'>
                                <hr className='blue-hr my-3' style={{opacity:'0.5'}}/>
                            </div>
                        </div>
                        <div className='row justify-content-center'>
                            <div className='col-lg-6'>
                                <h1 className='bold mb-0 text-blue black'>UNFORTUNATELY, YOU ARE NOT QUALIFIED YET FOR OUR MENTORSHIP</h1>
                            </div>
                        </div>
                    </div>

                    <div className='row justify-content-center'>
                        <div className='col-lg-6' style={{fontSize:'18px',textJustify:'inter-word',textAlign:'justify'}}>
                            <p className='mb-4'>I appreciate your interest in joining our Forex mentorship program. However, after <b>careful consideration</b>, your current circumstances may <b>not align with the expectations and requirements of our program.</b></p>
                            
                            <p className='mb-4'>These are the following reasons you're not yet qualified to apply:</p>

                            <div className='ms-lg-5 ms-2 mb-5'>
                                <h5 className='text-light-blue bold'><i className='fa fa-circle-chevron-right'/> YOU HAVE NO COMMITMENT AND TIME</h5>
                                <p className='mb-3'>We strive to work with individuals who are committed, dedicated, and ready to invest time and capital into their Forex trading journey. Unfortunately, it appears that at this time, you may not have the necessary resources, time, or commitment to engage in our program fully. Once you have time and are finally fully committed, you may <NavLink to='/freetraining'>reapply here.</NavLink></p>
                            </div>

                            <div className='ms-lg-5 ms-2 mb-5'>
                                <h5 className='text-light-blue bold'><i className='fa fa-circle-chevron-right'/> YOU HAVE EASY MONEY MINDSET</h5>
                                <p className='mb-3'>Forex trading requires dedication, financial resources, and time commitment to learn and succeed. Our program is designed for individuals serious about developing their skills and building a sustainable trading career, rather than seeking quick and easy returns.</p>
                            </div>

                            <div className='ms-lg-5 ms-2 mb-5'>
                                <h5 className='text-light-blue bold'><i className='fa fa-circle-chevron-right'/> YOU WILL JUST WASTE OUR ONE-HOUR TRAINING</h5>
                                <p className='mb-3'>While we appreciate your interest, you must be fully prepared and committed before embarking on this journey. Our one-hour free training is only for those who wish to push through to Forex trading and will attend the masterclass with attention and seriousness. We encourage you to reassess your readiness and resources before participating in our Forex mentorship training. Once you have freed your one hour of your time and are finally serious about our one-hour training, you may <NavLink to='/freetraining'>reapply here.</NavLink></p>
                            </div>
                            
                            <p>Thank you for your understanding, and we wish you the best of luck in your future endeavors.</p>

                        </div>
                    </div>

                </div>

            </div>

            <FunnelFooter/>

            <ModalFreeTraining show={subscribing} onHide={()=>setSubscribing(false)} origin={props.origin}/>
        </>
    );

}