import Modal from 'react-bootstrap/Modal';

import $ from 'jquery'
import { useState } from 'react';
import ModalLoading from './ModalLoading';
import blueFullLogo from '../../img/logo/blue-full-logo.png';

export default function ModalFreeTrainingCommunity(props) {

  var [processing,setProcessing] = useState(false)

  function sendTraining(){

    setProcessing(true)
    $("#sendTrainingForm").trigger("submit");

  }

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop='static'
      >
        
        <Modal.Header className='bg-blue' closeButton>
        
          <h4 className='saira text-center text-white m-0'>Free Training</h4>

        </Modal.Header>

        <Modal.Body className='bg-white-blue'>

          <div className='row justify-content-center py-3'>

            
            <div className='col-10 animate__animated animate__fadeIn'>

              <div className='row mb-4 justify-content-center'>
                <div className='col-12 text-center'>
                  <img src={blueFullLogo} alt='logo' width="50%" />
                </div>
              </div>

              <form method='POST' action="https://tradingcircle.activehosted.com/proc.php" id="sendTrainingForm">
                
                
                <input type="hidden" name="u" value="19" />
                <input type="hidden" name="f" value="19" />
                <input type="hidden" name="s" />
                <input type="hidden" name="c" value="0" />
                <input type="hidden" name="m" value="0" />
                <input type="hidden" name="act" value="sub" />
                <input type="hidden" name="v" value="2" />
                <input type="hidden" name="or" value="ec6cf3af7356ac7eabeeb67830136aed"/>
                

                <p className='bold text-blue mt-3'>Full Name</p>
                <input name='name' id="txtName" className='form-control text-muted' type="text"/>

                <p className='bold text-blue mt-3'>Email Address</p>
                <input name='email' id="txtEmailAddress" className='form-control text-muted' type="text"/>
                
              </form>
              
              {/*
              <form method='POST' action="https://app.kartra.com/process/add_lead/508jpB9W6LlF" id="sendTrainingForm">

                <p className='bold text-blue mt-3'>First Name</p>
                <input name='first_name' id="txtName" value={firstName} readOnly className='form-control text-muted' type="text"/>

                <p className='bold text-blue mt-3'>First Name</p>
                <input name='last_name' id="txtName" value={lastName} readOnly className='form-control text-muted' type="text"/>

                <p className='bold text-blue mt-3'>Email Address</p>
                <input name='email' id="txtEmailAddress" value={emailAddress} readOnly className='form-control text-muted' type="text"/>

                <p className='bold text-blue mt-3'>Contact Number</p>
                <input name='phone' id="txtPhoneNumber" value={phone} readOnly className='form-control text-muted' type="text"/>

              </form>*/}
            


              <button onClick={()=>sendTraining()} className='form-control btn-blue py-2 mt-4'><i class="fa-solid fa-send me-1"></i>Send Free Training</button>
             

            
            </div>
          
          </div>

        </Modal.Body>

        <Modal.Footer className='bg-blue'>
        </Modal.Footer>

      </Modal>

      <ModalLoading show={processing} />

    </>
  );
}