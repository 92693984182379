
import { Helmet } from 'react-helmet-async';
import {Header} from '../../components/general/Header'
import { Footer } from '../../components/academy/Footer';
import { MonthlyDemoChallenge } from '../../components/academy/MonthlyDemoChallenge';
import { useState, useEffect } from 'react';
import { QuarterlyDemoChallenge } from '../../components/academy/QuarterlyDemoChallenge';
import {onValue,ref,get} from 'firebase/database'
import {onAuthStateChanged} from 'firebase/auth'
import { adminDB } from '../../api/firebase-admin-config';
import moment from 'moment';
import { studentAuth } from '../../api/firebase-student-config';

export const DemoChallenge = () =>{

    const [selected,setSelected] = useState('monthly')

    const [quarterOpen, setQuarterOpen] = useState(false);
    const [eligibleIDs, setEligibleIDs] = useState([]);   
    const [UID,setUID] = useState()
    

    useEffect(()=>{

        onAuthStateChanged(studentAuth,(student)=>{
            if(student){
                setUID(student.uid);
            }
        })

        onValue(ref(adminDB,"quarterlyChallenge"),(snapshot)=>{
            if(snapshot.exists()){

                var m = moment(snapshot.val().date);

                get(ref(adminDB,'hallOfFame')).then((snapshot)=>{
                    if(snapshot.exists()){
                        
                        var elMonths = [];

                        elMonths.push(snapshot.val()[m.add(-1,'month').format("YYYY MM MMMM")]);
                        elMonths.push(snapshot.val()[m.add(-1,'month').format("YYYY MM MMMM")]);
                        elMonths.push(snapshot.val()[m.add(-1,'month').format("YYYY MM MMMM")]);

                        elMonths = elMonths.filter((e)=>{
                            return e !== undefined;
                        });

                        var elid = [];

                        for(var k in elMonths){
                            for(var x in elMonths[k]){
                                if(!elid.includes(x)) elid.push(x);
                            }
                        }

                        //console.log(elid)
                        setEligibleIDs(elid);

                    }
                })

            }else{
                setQuarterOpen(false);
                setSelected('Monthly');
            }
        });

    },[])

    useEffect(()=>{

        if(eligibleIDs.length > 0 && UID){
            setQuarterOpen(eligibleIDs.includes(UID));
        }

    },[eligibleIDs,UID])

    return(
        <>
            <Helmet>
                <title>Trading Circle - Demo Challenge</title>
            </Helmet>
            
            <div className="container-fluid animate__animated animate__fadeIn p-4" style={{overflowX:'hidden'}}>

                <Header title='Demo Challenge'/>

                <div className='row justify-content-center mb-5 mt-3' style={{gap:"40px"}}>
                    <div onClick={()=>setSelected('monthly')} className='col-4 text-center pointer smooth-transition' style={{borderBottom:selected === 'monthly'?"3px solid var(--primary)":"3px solid rgba(0,0,0,0.2)"}}>
                       
                        <div className='d-lg-block d-none'><h5 className={selected === 'monthly'?"bold text-blue smooth-transition":"bold smooth-transition"}><i className='fa fa-calendar'/> Monthly</h5></div>
                        <div className='d-lg-none d-block'><h6 className={selected === 'monthly'?"bold text-blue smooth-transition":"bold smooth-transition"}><i className='fa fa-calendar'/> M</h6></div>

                    </div>
                    
                    {quarterOpen?
                    <div onClick={()=>setSelected('quarterly')} className='col-4 text-center pointer smooth-transition' style={{borderBottom:selected === 'quarterly'?"3px solid var(--primary)":"3px solid rgba(0,0,0,0.2)"}}>
                        <div className='d-lg-block d-none'><h5 className={selected === 'quarterly'?"bold text-blue smooth-transition":"bold smooth-transition"}><i className="fa-solid fa-calendar-plus"/> Quarterly</h5></div>
                        <div className='d-lg-none d-block'><h6 className={selected === 'quarterly'?"bold text-blue smooth-transition":"bold smooth-transition"}><i className="fa-solid fa-calendar-plus"/> Q</h6></div>
                    </div>
                    :
                    <div className='col-4 text-center smooth-transition' style={{borderBottom:selected === 'quarterly'?"3px solid var(--primary)":"3px solid rgba(0,0,0,0.2)",opacity:'0.5'}}>
                        <div className='d-lg-block d-none'><h5 className={selected === 'quarterly'?"bold text-blue smooth-transition":"bold smooth-transition"}><i className="fa-solid fa-lock"/> Quarterly</h5></div>
                        <div className='d-lg-none d-block'><h6 className={selected === 'quarterly'?"bold text-blue smooth-transition":"bold smooth-transition"}><i className="fa-solid fa-lock"/> Q</h6></div>
                    </div>
                    }

                </div>

                <div className='row justify-content-center mb-5'>

                    {selected === 'monthly' && <MonthlyDemoChallenge/>}

                    {selected === 'quarterly' && <QuarterlyDemoChallenge/>}

                </div>

            </div>
            
            <Footer/>

        </>
    );

}