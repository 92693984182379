import Modal from 'react-bootstrap/Modal';
export default function ModalViewImage(props) {

  return (
    <>
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        contentClassName='bg-transparent'
        backdropClassName='top-most'
      > 

        <Modal.Header className='pb-1 px-2' style={{border:'0'}} closeButton>

        </Modal.Header>

        <Modal.Body style={{borderRadius:"30px"}}>

          <img alt='view' style={{borderRadius:'10px'}} src={props.image} width={'100%'}/>
          <p className='text-white pointer text-end mt-2' onClick={()=>window.open(props.image)}>View Image</p>
        </Modal.Body>
        
      </Modal>

    </>
  );
}