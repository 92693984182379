import { Helmet } from "react-helmet-async"
import { Footer } from "../../components/landing/Footer"
import { Header } from "../../components/general/Header"

import tradingCircleCommunity from "../../img/about/trading-circle-community.png"
import bgShapeDark from "../../img/bg/bg-shape-dark.png"

import alexBalingit from "../../img/people/alex-balingit.png"
import edmarRosel from "../../img/people/edmar-rosel.png"
import ivanEsguerra from "../../img/people/ivan-esguerra.png"
import karlOcampo from "../../img/people/karl-ocampo.png"
import lukeLangomez from "../../img/people/luke-langomez.webp"

import mission from "../../img/about/mission.webp"
import vision from "../../img/about/vision.webp"
import values from "../../img/about/values.webp"

import a from "../../img/about/gallery/1.webp"
import b from "../../img/about/gallery/2.webp"
import c from "../../img/about/gallery/3.webp"
import d from "../../img/about/gallery/4.webp"
import e from "../../img/about/gallery/5.webp"
import f from "../../img/about/gallery/6.webp"
import g from "../../img/about/gallery/7.webp"
import h from "../../img/about/gallery/8.webp"
import i from "../../img/about/gallery/9.webp"
import FsLightbox from 'fslightbox-react'


import { useState } from "react"




export const AboutUs = ()=>{

    var [images] = useState([a,b,c,d,e,i])

    var [toggler,setToggler] = useState(true)

    return(
        <>
            <Helmet>
                <title>Trading Circle - About Us</title>
            </Helmet>

            <div className="container-fluid animate__animated animate__fadeIn p-0 my-5" style={{overflow:'hidden'}}>
                <Header title='About Us'/>

                <div className="row justify-content-center mt-5" style={{gap:'30px'}}>

                    <div className="col-lg-5 align-self-center px-5" data-aos="fade-right" style={{maxWidth:'550px'}}>

                        <h2 className="text-lg-end text-center bold text-light-blue">Get Connected</h2>
                        <hr style={{borderTop:'2px solid'}}/>
                        <p className="text-lg-end text-center text-blue saira" style={{fontSize:'18px'}}>Your net worth is your network. You need to surround yourself with successful people, people you can learn from.</p>
                        <p className="text-lg-end text-center bold text-blue saira mt-lg-3 mt-4">We are better together.</p>

                    </div>

                    <div className="col-lg-5 text-lg-start text-center" data-aos="fade-left" style={{maxWidth:'600px'}}>
                        <img src={tradingCircleCommunity} alt='community' width="85%"/>
                    </div>

                    <div className="col-12 p-5 pt-lg-0 pt-5 mt-4" style={{background:`url('${bgShapeDark}')`,backgroundSize:'cover',backgroundPosition:'center',minHeight:'1300px'}}>
                        <div className="row h-100">
                            <div className="col-12 align-self-center">
                                
                                <div className="row justify-content-center mt-lg-0 mt-5 mb-5" data-aos="fade-up">
                                    <div className="col-12">
                                        <h3 className="text-center text-white">
                                            <i style={{fontSize:'48px'}} className="fa fa-users"/>
                                            <br/>
                                            Meet Our Leaders
                                        </h3>
                                    </div>
                                    <div className="col-lg-6" data-aos="fade-up">
                                        <hr className="my-1" style={{borderTop:'2px solid white'}}/>
                                    </div>
                                    <div className="col-lg-8 mt-2">
                                        <p className="text-white text-center" style={{opacity:'0.5'}}>The Trading Circle leaders have experience in high-level technical analysis and fundamental analysis.</p>
                                    </div>
                                </div>

                                <div className="row justify-content-center text-center" style={{gap:'50px'}}>

                                    <div className="col-lg-3 about-leader-container" style={{minWidth:'200px'}} data-aos="fade-up">
                                        <img src={alexBalingit} width="100%" alt='Alex Balingit' style={{maxWidth:'200px',borderRadius:'50%'}}/>
                                        <h4 className="text-center text-white bold saira mt-3 mb-0">Alex Balingit</h4>
                                        <p className="text-center text-white">Founder and Lead Mentor</p>
                                        <p className="text-center text-white black mt-2" style={{fontSize:'14px'}}>VISIONARY</p>
                                    </div> 
                                    
                                    <div className="col-lg-3 about-leader-container" data-aos="fade-up">
                                        <img src={edmarRosel} width="100%" alt='Alex Balingit' style={{maxWidth:'200px',borderRadius:'50%'}}/>
                                        <h4 className="text-center text-white bold saira mt-3">Edmar Rosel</h4>
                                        <p className="text-center text-white">Head Institutional Analyst</p>
                                        <p className="text-center text-white black mt-2" style={{fontSize:'14px'}}>BELIEVER</p>
                                    </div> 

                                    <div className="col-lg-3 about-leader-container" data-aos="fade-up">
                                        <img src={karlOcampo} width="100%" alt='Alex Balingit' style={{maxWidth:'200px',borderRadius:'50%'}}/>
                                        <h4 className="text-center text-white bold saira mt-3">Karl Ocampo</h4>
                                        <p className="text-center text-white">Head Fundamental Analyst</p>
                                        <p className="text-center text-white black mt-2" style={{fontSize:'14px'}}>PERSEVERING</p>
                                    </div> 

                                    <div className="col-lg-3 about-leader-container" data-aos="fade-up">
                                        <img src={ivanEsguerra} width="100%" alt='Alex Balingit' style={{maxWidth:'200px',borderRadius:'50%'}}/>
                                        <h4 className="text-center text-white bold saira mt-3">Ivan Esguerra</h4>
                                        <p className="text-center text-white">Head Technical Analyst</p>
                                        <p className="text-center text-white black mt-2" style={{fontSize:'14px'}}>ASPIRATIONAL</p>
                                    </div>

                                    <div className="col-lg-3 about-leader-container" data-aos="fade-up">
                                        <img src={lukeLangomez} width="100%" alt='Alex Balingit' style={{maxWidth:'200px',borderRadius:'50%'}}/>
                                        <h4 className="text-center text-white bold saira mt-3">Luke Langomez</h4>
                                        <p className="text-center text-white">Money Management Coach</p>
                                        <p className="text-center text-white black mt-2" style={{fontSize:'14px'}}>INNOVATIVE</p>
                                    </div>

                                    <div className="col-lg-6" data-aos="fade-up">

                                        <p className="text-white text-center" style={{opacity:'0.5'}}>As early adopters, they have a strong foundation in the world of financial markets, and the Trading Circle team is uniquely positioned to educate the Philippines on trading, investing, and entrepreneurship.</p>

                                    </div>

                                    <div className="col-lg-7"  data-aos="fade-up">
                                        <hr className="my-0" style={{borderTop:'2px solid white'}}/>
                                    </div>

                                    <div className="col-12 d-block d-lg-none" style={{marginBottom:'200px'}}/>

                                </div>
                            </div>
                            
                        </div>
                    </div>

                    <div className="col-12">

                        <div className="row justify-content-center text-blue" style={{gap:'20px'}}>

                            <div className="col-lg-3 col-10 about-mvv-container card-container-debossed px-lg-3 py-5 text-center" data-aos="fade-up">
                                <img className="darkmode-logo" src={mission} width="100px" alt='mission'/>
                                <h3 className="black saira">Mission</h3>
                                <div className="row justify-content-center">
                                    <div className="col-lg-5 col-8">
                                        <hr className="blue-hr m-0" style={{opacity:'0.5'}}/>
                                    </div>
                                </div>
                                <p className="px-5 mt-4 d-none d-lg-block" style={{fontSize:'18px'}}>To provide a strong support system of community while delivering pure quality education for professional growth of the members.</p>
                                <p className="px-4 mt-3 d-block d-lg-none" style={{fontSize:'16px'}}>To provide a strong support system of community while delivering pure quality education for professional growth of the members.</p>
                            </div>

                            <div className="col-lg-3 col-10 about-mvv-container card-container-debossed px-lg-3 py-5 text-center" data-aos="fade-up">
                                <img className="darkmode-logo" src={vision} width="100px" alt='mission'/>
                                <h3 className="black saira">Vision</h3>
                                <div className="row justify-content-center">
                                    <div className="col-lg-5 col-8">
                                        <hr className="blue-hr m-0" style={{opacity:'0.5'}}/>
                                    </div>
                                </div>
                                <p className="px-5 mt-4 d-none d-lg-block" style={{fontSize:'18px'}}>To bring financial freedom to every Filipino around the world by introducing Forex market while leading the local competition of financial institutions.</p>
                                <p className="px-4 mt-4 d-block d-lg-none" style={{fontSize:'16px'}}>To bring financial freedom to every Filipino around the world by introducing Forex market while leading the local competition of financial institutions.</p>
                            </div>

                            <div className="col-lg-3 col-10 about-mvv-container card-container-debossed px-lg-3 py-5 text-center" data-aos="fade-up">
                                <img className="darkmode-logo" src={values} width="100px" alt='mission'/>
                                <h3 className="black saira">Values</h3>
                                <div className="row justify-content-center">
                                    <div className="col-lg-5 col-8">
                                        <hr className="blue-hr m-0" style={{opacity:'0.5'}}/>
                                    </div>
                                </div>

                                <div className="text-center mt-4 d-lg-block d-none" style={{fontSize:'18px'}}>
                                    <p>Commitment</p> 
                                    <p>Impact</p> 
                                    <p>Relationship</p> 
                                    <p>Collaboration</p>
                                    <p>Loyalty</p>
                                    <p>Ethics</p>     
                                </div>

                                <div className="text-center mt-4 d-lg-none d-block" style={{fontSize:'16px'}}>
                                    <p>Commitment</p> 
                                    <p>Impact</p> 
                                    <p>Relationship</p> 
                                    <p>Collaboration</p>
                                    <p>Loyalty</p>
                                    <p>Ethics</p>     
                                </div>
                                        
                            </div>

                            <div className="col-12 mt-5">

                                <h2 className="text-center bold saira" data-aos='fade-up'>Gallery</h2>

                                <div className="row justify-content-center" data-aos='fade-up'>
                                    <div className="col-lg-10 col-10">
                                        <hr className="blue-hr mt-1 mb-4"/>

                                            <div className="row justify-content-center" style={{gap:'10px'}}>
                                                {images.map((image)=>{

                                                    return(
                                                        <div className="col-lg-3 col-10" key={image} style={{minWidth:'100px'}}>
                                                            <img src={image} alt={image} width="100%" style={{borderRadius:'25px'}} onClick={()=>setToggler(true)}/>
                                                        </div>
                                                    )

                                                })}
                                            </div>
                                            
                                        <hr className="blue-hr mt-3 mb-1"/>

                                    </div>
                                </div>
                                

                            </div>

                        </div>

                    </div>

                </div>
            </div>
            <Footer/>

            <FsLightbox
				toggler={toggler}
                sourceIndex={0}
				sources={images}
			/>

        </>
    )
}