import { onAuthStateChanged } from 'firebase/auth';
import { onValue, push, ref, set } from 'firebase/database';
import { studentAuth, studentDB } from '../../api/firebase-student-config';

import { useEffect, useState } from "react"
import ModalLoading from '../../components/general/ModalLoading';
import $ from 'jquery'

import { chatDB, chatStorage } from '../../api/firebase-chat-config';
import { getFileExt, shortenDescription } from '../../helper/formatter';
import { uploadBytes, ref as sref, getDownloadURL } from 'firebase/storage';
import ModalViewImage from '../../components/general/ModalViewImage';
import ModalAlert from '../../components/general/ModalAlert';
import ModalAttachmentConfirmation from '../../components/general/ModalAttachmentConfirmation';
import { globalCoaches, globalDevelopers, reactEmoji } from '../../helper/globals';
import ModalViewReaction from '../../components/academy/ModalViewReaction';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';

export const GroupChat = (props) =>{

    var [uid,setUID] = useState(null);
    var [student,setStudent] = useState(null);
    var [status,setStatus] = useState(null)

    var [processing,setProcessing] = useState(false)
    var [deleting,setDeleting] = useState(false)

    var [message,setMessage] = useState()
    var [reply,setReply] = useState()

    var [chatLimit,setChatLimit] = useState(25)
    //var [oldestID,setOldestID] = useState()

    var [mediaLimit,setMediaLimit] = useState(12)

    var [mediaCount,setMediaCount] = useState(0)
    var [allChats,setAllChats] = useState([])

    var [mediaChats,setMediaChats] = useState([])
    var [chats,setChats] = useState([])

    var [bottom,setBottom] = useState(false)

    var [students,setStudents] = useState()


    var [allowedImages] = useState(['png','jpg','jpeg','gif','webp'])
    var [allowedFiles] = useState(['doc','docx','xls','xlsx','csv','pdf'])


    var [viewingImage,setViewingImage] = useState(false)
    var [selectedImage,setSelectedImage] = useState()

    var [newChats,setNewChats] = useState(0)

    var [currentLevel,setCurrentLevel] = useState()
    var [currentIcon,setCurrentIcon] = useState()

    var [firstTime,setFirstTime] = useState(true)

    var [prevLength,setPrevLength] = useState(0)

    var [alertMessage,setAlertMessage] = useState()

    var [attachment,setAttachment] = useState()
    var [attachmentType,setAttachmentType] = useState()

    const [reacting, setReacting] = useState()
    
    const [selectedReaction, setSelectedReaction] = useState()

    const [params] = useSearchParams()

    useEffect(()=>{

        if(prevLength !== allChats.length){
            
            if(!firstTime){
                setChatLimit(chatLimit + (allChats.length - prevLength))
            }

            setPrevLength(allChats.length)
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[allChats.length])

    useEffect(()=>{

        if(params.get("id") && allChats.length > 0){
            setTimeout(()=>{
                scrollToID(params.get("id"));
            },2000)
        }

    },[params, allChats])

    useEffect(()=>{

        if(reply){
            $('#txtMessage').trigger('focus')
            //$("html, body").animate({ scrollTop: $(document).height() }, 10);
        }

    },[reply])

    useEffect(()=>{
    
        onValue(ref(studentDB,'students'),(snapshot)=>{
            if(snapshot.exists()){
                setStudents(snapshot.val())
            }
        })   

        checkBottom()

        $(window).on('scroll',()=>{
            checkBottom()
        });
                
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    function checkBottom(){
        
        if($(window).scrollTop() + $(window).height() === $(document).height()) {
            setBottom(true)
            setNewChats(0)
        }else{
            setBottom(false)
        }

    }

    function loadMoreChat(){

        if(chatLimit < allChats.length){

            setProcessing(true)

            chatLimit += 15
            setChatLimit(chatLimit)

            setTimeout(()=>{
                setProcessing(false)
            },100)

        }

    }

    function loadMoreAttachment(){

        if(mediaLimit < mediaCount){
            setProcessing(true)
            mediaLimit += 3
            setMediaLimit(mediaLimit)
            setTimeout(()=>{
                setProcessing(false)
            },100)
        }

    }

    useEffect(()=>{

        if(props.level && currentLevel){

            window.location.reload()

        }else if(props.level){

            setCurrentLevel(props.level)
            setCurrentIcon(props.icon)

            setProcessing(true)

            onValue(ref(chatDB,`${props.level.toLowerCase()}`),(snapshot)=>{
                
                if(snapshot.exists()){

                    var c = []

                    for(var key in snapshot.val()){
                        var x = snapshot.val()[key]
                        x.key = key
                        c.push(x)
                    }

                    setAllChats(c.reverse())

                }else setAllChats([])

                setProcessing(false)

            })

        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.level])

    useEffect(()=>{

        if(allChats.length > 0){

            var c = []

            for(var i = 0, x = 0; i < chatLimit;i++){
                if(!allChats[i]) break
                else c.push(allChats[i])
            }

            c.reverse()

            //setOldestID(c[0].key)

            setChats(c)


            var mc = []

            for(x = 0, i = 0; x < mediaLimit;i++){

                if(allChats[i]){

                    if(allChats[i].attachment && allChats[i].attachment.type === 'image'){
                        mc.push(allChats[i])
                        x++
                    }

                }else break   

            }

            var mCount = 0
            for(i = 0; i < allChats.length;i++){
                if(allChats[i] && allChats[i].attachment && allChats[i].attachment.type === 'image') mCount++
            }

            mc.reverse()

            setMediaCount(mCount)
            setMediaChats(mc)


        }else setChats([])


    },[allChats,chatLimit,mediaLimit])

    useEffect(()=>{

        if(chats.length > 0){
            if(bottom) $("html, body").animate({ scrollTop: $(document).height() }, 10);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[chats])

    useEffect(()=>{

        if(allChats.length > 0){

            if(firstTime){
                setFirstTime(false)
                setProcessing(true)

                setTimeout(()=>{
                    scrollToBottom()
                    setProcessing(false)
                },1000)

            }
            else if(reacting) setReacting(false);
            else if(chats[chats.length-1].from !== uid && !bottom){
                var c = newChats + 1
                setNewChats(c)
            }

        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[allChats])

    useEffect(()=>{

        onAuthStateChanged(studentAuth,(student)=>{
        
            if(student){

                onValue(ref(studentDB,'partnershipRequests/' + student.uid),(snap)=>{
                    if(snap.exists()){
                        setStatus(snap.val().status)
                        if(snap.val().status !== 'Approved' && props.level === 'Inner Circle') window.location.href = '/chat/standard'
                    }
                    else setStatus('N/A')
                })
        
                onValue(ref(studentDB,"students/"+student.uid),(snapshot)=>{
                    if(snapshot.exists()){
                        setUID(student.uid)
                        setStudent(snapshot.val());
                        if(snapshot.val().level !== 'Elite' && props.level === 'Elite') window.location.href = '/chat/standard'
                    }
                })
        
            }
    
        })

    },[props])

    function tryMessage(e){
        
        if(e.key === 'Enter' && !e.shiftKey){
            e.preventDefault()
            if(message) sendMessage()
        }

    }

    function sendAttachment(){

        if(!processing){
            setProcessing(true)
            setAttachment(null)
            setAttachmentType(null)
        }else return

        var chatID = parseInt(allChats[0].key)+1

        var fileAttachment = attachment
        var type = allowedImages.includes(getFileExt(fileAttachment.name).toLowerCase())?'image':fileAttachment.name

        var body = {}

        body.attachment = {
            url:'placeholder',
            type:type
        }

        body.time = moment().format("MMMM DD, YYYY hh:mm:ss a")
        body.from = uid

        if(reply){

            body.to = {
                ID:reply.from,
                key:reply.key,
                message:shortenDescription(reply.message,150) || null
            }

        }

        set(ref(chatDB,`${props.level.toLowerCase()}/${chatID}`),body).then(()=>{
            
            uploadBytes(sref(chatStorage,`${props.level.toLowerCase()}/${chatID}`),fileAttachment).then(()=>{

                getDownloadURL(sref(chatStorage,`${props.level.toLowerCase()}/${chatID}`)).then((url)=>{

                    set(ref(chatDB,`${props.level.toLowerCase()}/${chatID}/attachment/url`),url).then(()=>{
                        
                        setProcessing(false)
                        setReply(null)
                        $('#fileAttachment').val(null)
                        $("html, body").animate({ scrollTop: $(document).height() }, 10);

                    })

                })

            }).catch((error)=>{

            })
            

        })


    }

    function sendMessage(){

        var txtMessage = $('#txtMessage')
        txtMessage.html(" ")

        if(!processing){
            setProcessing(true)
        }else return

        var body = {}

        body.message = message
        body.from = uid
        body.time = moment().format("MMMM DD, YYYY hh:mm:ss a")

        

        if(reply){
            
            body.to = {
                ID:reply.from,
                key:reply.key,
                message:shortenDescription(reply.message,150) || null
            }

            var d = moment().format("MMMM DD, YYYY hh:mm:ss a");

            let notification = {
                icon:"fa fa-reply",
                image:student.profile.profilePictureURL,
                link:`/chat/${props.level.toLowerCase()}?id=${parseInt(allChats[0].key)+1}`,
                message:`<b>${student.profile.name.firstName} ${student.profile.name.lastName}</b> replied to your message: ${shortenDescription(body.message,30)}`,
                read:false,
                time:d
            }
            push(ref(studentDB,`notifications/${body.to.ID}`),notification)

            setReply(null)

        }

        set(ref(chatDB,`${props.level.toLowerCase()}/${parseInt(allChats[0].key)+1}`),body).then(()=>{

            setProcessing(false)
            setMessage(null)
            $('#txtMessage').html('')
            $("html, body").animate({ scrollTop: $(document).height() }, 10);

        })

    }

    function verifyMessage(){

        var txtMessage = $('#txtMessage')

        if(txtMessage.text().trim().length > 0) setMessage(txtMessage.html())
        else setMessage(null)

    }

    function verifyAttachment(){

        var fileAttachment = $('#fileAttachment').prop('files')[0]

        if(allowedFiles.includes(getFileExt(fileAttachment.name).toLowerCase()) || allowedImages.includes(getFileExt(fileAttachment.name).toLowerCase())){
            
            if(parseFloat(fileAttachment.size) / 1000000 > 5){
                setAlertMessage(`Attachment size is too large only <b>5MB</b> is allowed.<br/><br/> File being uploaded is <b>${parseFloat(fileAttachment.size/1000000).toFixed(2)}MB</b>`)
                $('#fileAttachment').val(null)
            }else{
                setAttachment(fileAttachment)
                if(allowedFiles.includes(getFileExt(fileAttachment.name).toLowerCase())) setAttachmentType('FILE')
                else setAttachmentType('IMAGE')
            }

        }else{
            setAlertMessage(`File formats such as <b>"${getFileExt(fileAttachment.name).toUpperCase()}"</b> are not allowed.`)
            $('#fileAttachment').val(null)
        }

    }

    function verifyPasteAttachment(fileAttachment){

        if(allowedFiles.includes(getFileExt(fileAttachment.name).toLowerCase()) || allowedImages.includes(getFileExt(fileAttachment.name).toLowerCase())){
            
            if(parseFloat(fileAttachment.size) / 1000000 > 5){
                setAlertMessage(`Attachment size is too large only <b>5MB</b> is allowed.<br/><br/> File being uploaded is <b>${parseFloat(fileAttachment.size/1000000).toFixed(2)}MB</b>`)
                $('#fileAttachment').val(null)
            }else{
                setAttachment(fileAttachment)
                if(allowedFiles.includes(getFileExt(fileAttachment.name).toLowerCase())) setAttachmentType('FILE')
                else setAttachmentType('IMAGE')
            }

        }else{
            setAlertMessage(`File formats such as <b>"${getFileExt(fileAttachment.name).toUpperCase()}"</b> are not allowed.`)
            $('#fileAttachment').val(null)
        }

    }

    function prepareImageView(url){
        setViewingImage(true)
        setSelectedImage(url)
    }

    function scrollToBottom(){
        $("html, body").animate({ scrollTop: $(document).height() }, 100);
        setNewChats(0)
    }

    function scrollToID(id){

        if($(`#${id}`).offset() ){
            $('html, body').animate({
                scrollTop: $(`#${id}`).offset().top - 500
            }, 10);

            $(`#${id}`).css('background','var(--faded-accent)')
            $(`#${id}`).css('border-radius','25px')
            $(`#${id}`).css('padding','25px 15px')

            setTimeout(() => {

                $(`#${id}`).css('background','transparent')
                $(`#${id}`).css('border-radius','0')
                $(`#${id}`).css('padding','0')
                
            }, 2000);
        }


    }

    function deleteMessage(c){

        setDeleting(true)

        set(ref(chatDB,`${props.level.toLowerCase()}/${c.key}`),null).then(()=>{
            setDeleting(false)
        })

        console.log(c)
    }

    function regulatePaste(e){

        e.preventDefault();

        var item = e.clipboardData.items[0];
        console.log(e.clipboardData.items)
    
        if (item.type.indexOf("image") === 0)
        {

            var blob = item.getAsFile();
            verifyPasteAttachment(blob);

        }else{

            let paste = (e.clipboardData || window.clipboardData).getData('text/html');

            const selection = window.getSelection();
            if (!selection.rangeCount) return;
            
            selection.deleteFromDocument();

            var newDiv = document.createElement('span')
            newDiv.innerHTML = paste

            var links = newDiv.getElementsByTagName('a')
            
            if(links.length > 0){

                for(var i = 0; i < links.length;i++){
                    links[i].innerHTML = links[i].href
                    links[i].target = '_blank'
                }

            }else{
                paste = (e.clipboardData || window.clipboardData).getData('text');
                newDiv.innerHTML = paste
            }

            selection.getRangeAt(0).insertNode(newDiv);
            selection.collapseToEnd();

        }
    
    }

    function chatReact(reaction,chat){

        setReacting(true)

        if(chat.reaction && chat.reaction[uid] && chat.reaction[uid] === reaction){

            set(ref(chatDB,`${props.level.toLowerCase()}/${chat.key}/reaction/${uid}`),null).then(()=>{
                setProcessing(false)
            })

        }else{
            set(ref(chatDB,`${props.level.toLowerCase()}/${chat.key}/reaction/${uid}`),reaction).then(()=>{
                setProcessing(false)
            })
        }

    }

    return(
        <>
            <div className='row m-0'>

                <div className='col-lg-9 col-12 m-0 p-0'>

                    <div className='row mx-0 mt-0 chat-container py-lg-3 px-lg-3 px-1 py-3 justify-content-center' style={{gap:'10px',marginBottom:`${reply?'125px':'90px'}`}}>

                        {(allChats.length === 0 || chatLimit > allChats.length)?
                        <>
                            <div className='col-lg-12 text-blue'>
                                <h4 className='text-center bold' style={{fontSize:'calc(0.9rem + 0.75vw)'}}>
                                    <i style={{fontSize:'calc(1.25rem + 1.5vw)'}} className={`fa ${currentIcon} d-block`}/>
                                    {currentLevel} Group Chat
                                </h4>
                            </div>
                            <div className='col-10'>
                                <hr className='mt-0'/>
                            </div>
                        </>
                        :
                        !processing?
                        <div className='col-10 see-more-container pointer' onClick={()=>loadMoreChat()}>
                            <p className='text-blue text-center m-0'><i className='fa fa-arrow-up'/> See More</p>
                        </div>
                        :
                        <></>
                        }

                        

                        {chats.length === 0 && <h5 className='text-muted text-center' style={{opacity:'0.5'}}><i className='fa fa-message'/> No Messages</h5>}

                        {students && chats.map((c)=>{

                            var emoji = ''
                            var reactCount = 0

                            if(c.reaction){
                                reactCount = Object.keys(c.reaction).length
                                var reacts ={
                                    'Like':0,
                                    'Love':0,
                                    'Haha':0,
                                    'Wow':0,
                                    'Sad':0,
                                    'Angry':0
                                }

                                for(var react in c.reaction){
                                    reacts[c.reaction[react]] += 1
                                }

                                let sortable = [];
                                for (var r in reacts) {
                                    sortable.push([r, reacts[r]]);
                                }

                                sortable.sort(function(a, b) {
                                    return b[1] - a[1];
                                });
                                
                                if(sortable[0][1] !== 0) emoji += reactEmoji[sortable[0][0]]
                                //if(sortable[1][1] !== 0) emoji += reactEmoji[sortable[1][0]]

                            }


                            if(c.from === uid){ 

                                return(
                                    <div className={`col-lg-12 chat-message-container animate__animated you ${reactCount > 0 && 'mb-4'}`} id={c.key} key={c.key}>
                                        <div className='row ms-2 py-2 align-self-center your-message justify-content-center' style={{background:`${c.attachment?c.to?'':'transparent':''}`}}>
                                            
                                            <p dangerouslySetInnerHTML={{__html:c.message}}></p>

                                            {c.attachment?
                                            c.attachment.type === 'image'?
                                            <div onClick={()=>prepareImageView(c.attachment.url)} className='chat-image-attachment pointer' style={{backgroundImage:`url(${c.attachment.url})`}}/>
                                            :
                                            <a rel='noreferrer' className='your-attachment-message py-2 text-white underline' href={`${c.attachment.url}`} target='_blank' download><i className='fa fa-file'/> {c.attachment.type}</a>
                                            :
                                            <></>
                                            }
                                            
                                            {c.to &&
                                            <>
                                                <p className='small text-white mt-2' style={{opacity:'0.35'}}><i className='fa fa-message'/> Replying to <b>{c.to.ID === uid?'Yourself':`${students[c.to.ID].profile.name.firstName} ${students[c.to.ID].profile.name.lastName}`}</b></p>
                                                <div className='col-12 reply-message text-white pointer' style={{opacity:'0.5'}} onClick={()=>scrollToID(c.to.key)}>
                                                    {c.to.message?<p dangerouslySetInnerHTML={{__html:c.to.message}}></p>:<span><i className='fa fa-paperclip'/> Attachment</span>}
                                                </div>
                                            </>
                                            }

                                            {reactCount > 0 &&
                                            <span className="reaction-count animate__animated animate__tada" onClick={()=>setSelectedReaction(c.key)}>
                                                {emoji} {reactCount}
                                            </span>
                                            }

                                            {c.time &&
                                            <>
                                                <hr className='mb-2 mt-0'/>
                                                <span style={{fontSize:'12px',opacity:'0.25'}}>{moment(c.time).fromNow()}</span>
                                            </>
                                            }

                                        </div>

                                        <div className='row h-100' style={{float:'right'}}>
                                            <div className='col-12 align-self-center ms-2 chat-action-container'>
                                                
                                                <i className='fa fa-smile pointer mx-1'/>
                                                <div className="chat-react-container py-1 justify-content-center px-3 animate__animated animate__fadeIn">
                                                    <picture className="react-item" onClick={(e)=>chatReact('Like',c)}>
                                                        <source srcset="https://fonts.gstatic.com/s/e/notoemoji/latest/1f44d_1f3fb/512.webp" type="image/webp"/>
                                                        <img src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f44d_1f3fb/512.gif" alt="👍" width="32" height="32"/>
                                                    </picture>
                                                    <picture className="react-item" onClick={(e)=>chatReact('Love',c)}>
                                                        <source srcset="https://fonts.gstatic.com/s/e/notoemoji/latest/2764_fe0f/512.webp" type="image/webp"/>
                                                        <img src="https://fonts.gstatic.com/s/e/notoemoji/latest/2764_fe0f/512.gif" alt="❤️" width="32" height="32"/>
                                                    </picture>
                                                    <picture className="react-item" onClick={(e)=>chatReact('Haha',c)}>
                                                        <source srcset="https://fonts.gstatic.com/s/e/notoemoji/latest/1f606/512.webp" type="image/webp"/>
                                                        <img src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f606/512.gif" alt="😆" width="32" height="32"/>
                                                    </picture>
                                                    <picture className="react-item" onClick={(e)=>chatReact('Wow',c)}>
                                                        <source srcset="https://fonts.gstatic.com/s/e/notoemoji/latest/1f62f/512.webp" type="image/webp"/>
                                                        <img src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f62f/512.gif" alt="😯" width="32" height="32"/>
                                                    </picture>
                                                    <picture className="react-item" onClick={(e)=>chatReact('Sad',c)}> 
                                                        <source srcset="https://fonts.gstatic.com/s/e/notoemoji/latest/1f622/512.webp" type="image/webp"/>
                                                        <img src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f622/512.gif" alt="😢" width="32" height="32"/>
                                                    </picture>  
                                                    <picture className="react-item" onClick={(e)=>chatReact('Angry',c)}>
                                                        <source srcset="https://fonts.gstatic.com/s/e/notoemoji/latest/1f620/512.webp" type="image/webp"/>
                                                        <img src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f620/512.gif" alt="😠" width="32" height="32"/>
                                                    </picture>
                                                </div>


                                                <i onClick={()=>deleteMessage(c)} className='fa fa-trash pointer mx-1'/>
                                                <i onClick={()=>setReply(c)} className='fa fa-reply pointer mx-1'/>
                                            </div>
                                        </div>
                                    </div>
                                )

                            }else if(students[c.from]){
                            
                                var isCoach = globalCoaches.includes(students[c.from].profile.emailAddress.toLowerCase()) || globalDevelopers.includes(students[c.from].profile.emailAddress.toLowerCase())
                                
                                return(
                                    <div className='col-lg-12 chat-message-container animate__animated them' id={c.key} key={c.key}>
                                        <div className='row d-inline' style={{float:'left'}}>
                                            <img alt='Profile' className='chat-profile-image' src={students[c.from].profile.profilePictureURL}/>
                                        </div>
                                        
                                        <p className={`small chat-message-name ${isCoach?'text-light-blue bold':'text-muted'}`}>
                                            {students[c.from].profile.name.firstName} {students[c.from].profile.name.lastName} {isCoach && <span className='admin-badge'><i className='fa fa-circle-check'/></span>}
                                        </p>

                                        <div className={`row ms-2 pt-2 align-self-center chat-message justify-content-center ${isCoach && 'admin'} ${reactCount > 0 && 'mb-4'}`} style={{background:`${c.attachment?c.to?'':'transparent':''}`}}>
                                            
                                            <p className='mb-2' dangerouslySetInnerHTML={{__html:c.message}}></p>

                                            
                                            {c.attachment?
                                            c.attachment.type === 'image'?
                                            <div onClick={()=>prepareImageView(c.attachment.url)} className='chat-image-attachment pointer' style={{backgroundImage:`url(${c.attachment.url})`}}/>
                                            :
                                            <a rel='noreferrer' className={`attachment-message py-2 underline ${isCoach?'text-light-blue bold':'text-muted'}`} href={`${c.attachment.url}`} target='_blank' download><i className='fa fa-file'/> {c.attachment.type}</a>
                                            :
                                            <></>
                                            }

                                            {c.to &&
                                            <>
                                                <p className='small mt-2' style={{opacity:'0.6'}}><i className='fa fa-message'/> Replying to <b>{c.to.ID === uid?<span className='text-light-blue'>You</span>:`${students[c.to.ID].profile.name.firstName} ${students[c.to.ID].profile.name.lastName}`}</b></p>
                                                <div className='col-12 reply-message pointer' style={{opacity:'0.5'}} onClick={()=>scrollToID(c.to.key)}>
                                                    <p>{c.to.message || <span><i className='fa fa-paperclip'/> Attachment</span>}</p>
                                                </div>
                                            </>
                                            }

                                            {reactCount > 0 &&
                                            <span className="reaction-count animate__animated animate__tada" onClick={()=>setSelectedReaction(c.key)}>
                                                {emoji} {reactCount}
                                            </span>
                                            }

                                            {c.time &&
                                            <>
                                                <hr className='mb-2 mt-0'/>
                                                <span className='mb-2' style={{fontSize:'12px',opacity:'0.5'}}>{moment(c.time).fromNow()}</span>
                                            </>
                                            }

                                        </div>  

                                        <div className='row h-75'>
                                            <div className='col-12 align-self-center ms-2 chat-action-container'>

                                                <i onClick={()=>setReply(c)} className='fa fa-reply pointer mx-1'/>
                                                <i className='fa fa-smile pointer mx-1'/>
                                                <div className="chat-react-container py-1 justify-content-center px-3 animate__animated animate__fadeIn">
                                                    <picture className="react-item" onClick={(e)=>chatReact('Like',c)}>
                                                        <source srcset="https://fonts.gstatic.com/s/e/notoemoji/latest/1f44d_1f3fb/512.webp" type="image/webp"/>
                                                        <img src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f44d_1f3fb/512.gif" alt="👍" width="32" height="32"/>
                                                    </picture>
                                                    <picture className="react-item" onClick={(e)=>chatReact('Love',c)}>
                                                        <source srcset="https://fonts.gstatic.com/s/e/notoemoji/latest/2764_fe0f/512.webp" type="image/webp"/>
                                                        <img src="https://fonts.gstatic.com/s/e/notoemoji/latest/2764_fe0f/512.gif" alt="❤️" width="32" height="32"/>
                                                    </picture>
                                                    <picture className="react-item" onClick={(e)=>chatReact('Haha',c)}>
                                                        <source srcset="https://fonts.gstatic.com/s/e/notoemoji/latest/1f606/512.webp" type="image/webp"/>
                                                        <img src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f606/512.gif" alt="😆" width="32" height="32"/>
                                                    </picture>
                                                    <picture className="react-item" onClick={(e)=>chatReact('Wow',c)}>
                                                        <source srcset="https://fonts.gstatic.com/s/e/notoemoji/latest/1f62f/512.webp" type="image/webp"/>
                                                        <img src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f62f/512.gif" alt="😯" width="32" height="32"/>
                                                    </picture>
                                                    <picture className="react-item" onClick={(e)=>chatReact('Sad',c)}> 
                                                        <source srcset="https://fonts.gstatic.com/s/e/notoemoji/latest/1f622/512.webp" type="image/webp"/>
                                                        <img src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f622/512.gif" alt="😢" width="32" height="32"/>
                                                    </picture>  
                                                    <picture className="react-item" onClick={(e)=>chatReact('Angry',c)}>
                                                        <source srcset="https://fonts.gstatic.com/s/e/notoemoji/latest/1f620/512.webp" type="image/webp"/>
                                                        <img src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f620/512.gif" alt="😠" width="32" height="32"/>
                                                    </picture>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }else return null;
                        })}


                    </div>

                    <div className='row m-0'>

                        <div className='col-lg-9 col-12 chat-footer'>

                            {newChats > 0 &&
                            <div className='row justify-content-center animate__animated animate__fadeInUp'>
                                <p className='new-chat-badge mb-3 bold pointer' onClick={()=>scrollToBottom()}> {newChats} New Message/s <i className='fa fa-arrow-down'/> </p>
                            </div>
                            }
                            

                            <div className={`row chat-footer-bg ${!reply && 'pt-4'}`}>
                                
                                {reply &&
                                <>
                                    <p className='ms-lg-5 ms-3 px-lg-5 px-4 text-muted mt-3'><i className='fa fa-message'/> Replying to <b>{reply.from === uid?'Yourself':`${students[reply.from].profile.name.firstName} ${students[reply.from].profile.name.lastName}`}</b> <i className='fa fa-close small pointer ms-2' onClick={()=>setReply(null)}/></p>
                                    <p className='ms-lg-5 ms-3 px-lg-5 px-4 small text-muted mb-3'>{reply.message?<span dangerouslySetInnerHTML={{__html:shortenDescription(reply.message,150)}}/>:<span><i className='fa fa-paperclip'/> Attachment</span>}</p>
                                </>
                                }
                                
                                <div className='col-12 px-lg-5 px-4 pb-4 d-flex align-items-center'>
                                    <input onChange={()=>verifyAttachment()} id='fileAttachment' type='file' style={{display:'none'}} accept='image/*,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'/>
                                    <i onClick={()=>{$('#fileAttachment').trigger('click')}} className='fa fa-paperclip me-3 text-light-blue pointer' style={{fontSize:'20px'}}/> 

                                    <div className='form-control rounded-input' style={{position:'relative',overflowY:'auto'}}>
                                        <p id='txtMessage' onKeyUp={(e)=>{verifyMessage()}} onKeyDown={(e)=>tryMessage(e)} onPaste={(e)=>regulatePaste(e)} contentEditable style={{maxWidth:'100%'}}></p>
                                        {!message && <p className='text-muted' style={{position:'absolute',top:'5px'}}>Aa</p>}
                                    </div>

                                    {/*<input onChange={()=>verifyMessage()} onKeyUp={(e)=>tryMessage(e)} id='txtMessage' className='form-control rounded-input' type='text' placeholder='Aa'/>*/}
                                    
                                    {!message?
                                    <i className='fa fa-send ms-3 text-light-blue' style={{fontSize:'20px',opacity:'0.5'}}/>
                                    :
                                    <i onClick={()=>sendMessage()} className='fa fa-send ms-3 text-light-blue pointer' style={{fontSize:'20px'}}/>
                                    }
                                </div>

                            </div>
                            

                        </div>
                        
                    </div>

                </div>

                <div className='col-3 d-lg-block d-none chat-side-bar py-4'>
                    
                    <h3 className='text-blue text-center' style={{fontSize:'calc(0.5rem + 0.75vw)'}}>
                        <i className={`fa ${currentIcon}`} style={{fontSize:'calc(1rem + 2vw)'}}/><br/>
                        {currentLevel} Group Chat
                    </h3>

                    <div className='row justify-content-center'>
                        <div className='col-10'>
                            <hr className='m-0 mb-1'/>
                        </div>
                    </div>

                    <p className='text-center text-blue mb-2' style={{fontSize:'calc(0.5rem + 0.5vw)'}}>Shared Media</p>

                    <div className='row justify-content-center chat-shared-media-container' style={{gap:'10px'}}>

                        <div className='col-12 edge-fade top'><div className='top'/></div>
                        <div className='col-12 edge-fade bottom'><div className='bottom'/></div>

                        {(mediaLimit < mediaCount) &&
                            <div className='col-10 pointer mb-3' onClick={()=>loadMoreAttachment()}>
                                <p className='text-blue text-center m-0'><i className='fa fa-arrow-down'/> See More</p>
                        </div>
                        }            
                        
                        {mediaChats.map((c)=>{

                            if(c.attachment && c.attachment.type === 'image'){

                                return(
                                    <div key={c.key} onClick={()=>prepareImageView(c.attachment.url)} className='col-3 chat-shared-media' style={{backgroundImage:`url(${c.attachment.url})`}}></div>
                                )

                            }else return null

                        })}

                        {mediaChats.length === 0 && <div className='col-12 h-100 align-self-center' style={{opacity:'0.4'}}><h4 className='text-muted text-center'><i className='fa fa-image'/> No Shared Media</h4></div>}

                    </div>

                </div>
                

            </div>

            <ModalAttachmentConfirmation show={attachment} attachment={attachment} type={attachmentType} onHide={()=>setAttachment(null)} onConfirm={()=>sendAttachment()}/>
            <ModalViewImage show={viewingImage} onHide={()=>setViewingImage(false)} image={selectedImage}/>
            <ModalLoading show={processing}/>
            <ModalLoading show={deleting}/>        
            <ModalAlert show={alertMessage} onHide={()=>setAlertMessage()} icon='fa fa-circle-exclamation' title='Attachment Error' message={alertMessage}/>
            <ModalViewReaction show={selectedReaction} onHide={()=>setSelectedReaction(null)} chatKey={selectedReaction} level={props.level}/>

        </>
    )

}
