import ModalLoading from '../general/ModalLoading';
import { useEffect, useState } from 'react';
import { onValue, ref, set } from 'firebase/database';
import { onAuthStateChanged } from 'firebase/auth';
import { studentAuth, studentDB } from '../../api/firebase-student-config';
import demoChallenge from '../../img/demo-challenge/quarterly-demo.png'
import { adminDB } from '../../api/firebase-admin-config';
import ModalConfirmation from '../general/ModalConfirmation';
import moment from "moment"

import ModalDemoAcount from './ModalDemoAccount';
import axios from 'axios'
import ModalClaimFund from './ModalClaimFund';

export const QuarterlyDemoChallenge = ()=>{

    const [processing,setProcessing] = useState(false);

    var [uid,setUID] = useState(null);
    var [student,setStudent] = useState(null);

    var [challengeStatus,setChallengeStatus] = useState();
    var [challengeDate,setChallengeDate] = useState();
    var [joining,setJoining] = useState(false)
    var [joined,setJoined] = useState(false);
    var [demoAccount,setDemoAccount] = useState()
    var [checkingAccount,setCheckingAccount] = useState(false)

    var [studentIDs,setStudentIDs] = useState({})
    var [students,setStudents] = useState([])

    var [claimingPrize,setClaimingPrize] = useState(false)
    var [claimData,setClaimData] = useState(false)
    var [winStatus,setWinStatus] = useState('N/A')
    var [winData,setWinData] = useState();


    const [eligibleIDs, setEligibleIDs] = useState([]);   
    const [eligible, setEligible] = useState(false);


    useEffect(()=>{

        if(uid && challengeDate){
            onValue(ref(adminDB,`hallOfFameQuarterly`),(snapshot)=>{

                if(snapshot.exists()){

                    for(var key in snapshot.val()){
                        
                        if(snapshot.val()[key][uid] != null){

                            setWinData(key)
              
                            if(student['quarterlyChallenge']){
                                setWinStatus(student['quarterlyChallenge']['status'])
                                setClaimData(student['quarterlyChallenge'])
                            }
                            else setWinStatus('WIN')
                        }

                    }
                }

            })

            var m = moment(challengeDate);

            onValue(ref(adminDB,'hallOfFame'),(snapshot)=>{
                if(snapshot.exists()){
                    
                    var elMonths = [];

                    elMonths.push(snapshot.val()[m.add(-1,'month').format("YYYY MM MMMM")]);
                    elMonths.push(snapshot.val()[m.add(-1,'month').format("YYYY MM MMMM")]);
                    elMonths.push(snapshot.val()[m.add(-1,'month').format("YYYY MM MMMM")]);

                    elMonths = elMonths.filter((e)=>{
                        return e !== undefined;
                    });

                    var elid = [];
                    
                    for(var k in elMonths){
                        for(var x in elMonths[k]){
                            if(!elid.includes(x)) elid.push(x);
                        }
                    }

                    setEligibleIDs(elid);

                }
            })

        }

    },[uid,student,challengeDate])

    useEffect(()=>{

        onAuthStateChanged(studentAuth,(student)=>{
      
            if(student){
        
              onValue(ref(studentDB,"students/"+student.uid),(snapshot)=>{
                if(snapshot.exists()){
                    setUID(student.uid);
                    setStudent(snapshot.val());
                    setProcessing(false);
                }
              })
        
            }else setProcessing(false);
        
          })

        onValue(ref(adminDB,"quarterlyChallenge"),(snapshot)=>{
            if(snapshot.exists()){

                var cInfo = snapshot.val()

                if(cInfo.status) setChallengeStatus(cInfo.status)
                if(cInfo.date) setChallengeDate(cInfo.date)

                if(cInfo.participants && cInfo.participants[uid]) setJoined(true)
                else setJoined(false)

                
            }else{
                setChallengeStatus('closed')
                setJoined(false)
            }   
        })

    },[challengeStatus,joined,uid])

    useEffect(()=>{

        if(joined && uid){

            onValue(ref(studentDB,`/quarterlyChallengeAccount/${uid}`),(snapshot)=>{
                if(snapshot.exists()){
                    setDemoAccount(snapshot.val())
                }else setDemoAccount(null)
            });

        }

    },[joined,uid])

    useEffect(()=>{

        onValue(ref(adminDB,'/hallOfFame'),(snapshot)=>{

            if(snapshot.exists()){

                var dates = snapshot.val() 
                
                var studs = '{'

                for(var key in dates){

                    if(moment(key).format('MMMM') === moment().add(-1,'months').format('MMMM') || moment(key).format('MMMM') === moment().format('MMMM')){
                        
                        for(var id in dates[key]){
                            studs += `"${id}":"${dates[key][id]}",`
                        }

                    }

                }

                studs = studs.substring(0,studs.length-1)
                studs += '}'

                setStudentIDs(JSON.parse(studs))

            }
        })

    },[])

    useEffect(()=>{
        
        onValue(ref(studentDB,'/students'),(snapshot)=>{
            
            if(snapshot.exists()){

                var studentsInfo = snapshot.val()
                var winningStudents = []

                    for(var key in studentIDs){
                    if(studentsInfo[key]){
                        studentsInfo[key].winningDate = studentIDs[key]
                        winningStudents.push(studentsInfo[key])                
                    }
                }

                winningStudents.reverse()
                setStudents(winningStudents)
                
            }

        })

    },[studentIDs])

    useEffect(()=>{

        if(eligibleIDs.length > 0 && uid){
            setEligible(eligibleIDs.includes(uid));
        }

    },[eligibleIDs,uid])


    function joinChallenge(){

        setProcessing(true)
        var d = moment().format()

        set(ref(adminDB,'quarterlyChallenge/participants/'+uid),d).then(()=>{
            
            sendJoinConfirmation()
    
        }).catch((error)=>{
            setProcessing(false)
            setJoining(false)
            alert(error)
        })

    }

    function sendJoinConfirmation(){
        setProcessing(true)
        axios.post('https://us-central1-trading-circle-ph-admin.cloudfunctions.net/app/api/email/support',{
        subject:`Registered Successfully - ${moment().format('MMMM YYYY')} Quarterly Demo Challenge`,
        email:student.profile.emailAddress,
        html:`        
        <div style='display:table;margin:0 auto;'>
        <div style='max-width:500px;margin:0 auto;'>
            
            <p style='text-align:center;'>
            <img src='https://tradingcircle-ph.web.app/static/media/black-word-logo.1bf10b226314dd33a7ab.png' width='350px'/>
            </p>

            <hr/>

            <h2 style='white-space:pre;color:#01245A;margin-bottom:0;'>Registered Successfully - ${moment().format('MMMM YYYY')} Demo Challenge</h2>
            <p style='color:#01245A;margin-top:0;margin-bottom:0;'>Hi ${student.profile.name.firstName}, We would like to inform you that you are now successfully part of this quarter's Demo Challenge. Please wait for your demo account credentials on our website, we will also notify you via email when the credentials are released.</p>
            <br/>

            <p style='text-align:center;'>
                <img style="border-radius:25px" src='https://firebasestorage.googleapis.com/v0/b/trading-circle-ph-admin.appspot.com/o/email%2Fdemo-challenge-register.png?alt=media&token=9d2b6d4e-a0fb-4cca-b7bf-55128166dbf3' width='100%'/>
            </p>

            <p style='color:#01245A;margin-top:0;margin-bottom:0;'>You will be receiving an Account ID and a password in our Demo Challenge tab found on the link below: </p>
            <p style='color:#01245A;margin-top:0;margin-bottom:0;'><a href='https://www.tradingcircle.com.ph/demo-challenge?type=quarterly' target='_blank'>www.tradingcircle.com.ph/demo-challenge</a>.</p>
            
            <br/>
            
            <p style='color:#01245A;margin-top:0;margin-bottom:0;'>Make sure you are logged in with your correct account specifically the email we used to send this message.</p>

        </div>
        </div>
        `
    }).then((res)=>{

        if(res.status === 200){

            setProcessing(false)
            setJoining(false)

        }else{
            alert(res.text)
            setProcessing(false)
        }

    })

    }

    return(
    <>
        <div className='col-lg-10'>

            <div className='row justify-content-center'>

                {challengeStatus === 'closed' &&
                <div className='col-lg-12 text-center align-self-center'>
                    <img className='animate__animated animate__fadeIn' src={demoChallenge} alt='demo icon' width="95%" style={{borderRadius:'25px'}}/>
                </div>
                }

                {eligible && challengeStatus === 'open' &&
                <div className='col-lg-10 text-center align-self-center'>
                    <div className='video-container' style={{padding:"56.25% 0 0 0",position:"relative"}}><iframe src="https://player.vimeo.com/video/870145284?h=c86a062197&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{position:"absolute",top:"0",left:"0",width:"100%",height:"100%"}} title="Website Walkthrough"></iframe></div>
                    <script src="https://player.vimeo.com/api/player.js"></script>
                </div>
                }


                <div className='col-lg-6 px-lg-5 py-lg-3 px-4 py-3 align-self-center' style={{maxWidth:'600px'}}>
                   
                    {winStatus === 'N/A'?
                    <></>
                    :
                    winStatus === 'WIN'?
                    <button className='btn-solid w-100 py-2 mt-3' style={{width:'fit-content'}} onClick={()=>setClaimingPrize(true)}><i className='fa fa-check'/> Claim Prize</button>
                    :
                    winStatus === 'SHIPPED'?
                    <button className='btn-solid w-100 py-2 mt-3' style={{width:'fit-content'}} disabled><i className='fa fa-box'/> Shipped</button>
                    :
                    winStatus === 'PENDING'?
                    <button className='btn-solid w-100 py-2 mt-3' style={{width:'fit-content'}} onClick={()=>setClaimingPrize(true)}><i className='fa fa-edit'/> Edit Details</button>
                    :
                    <button className='btn-solid w-100 py-2 mt-3' style={{width:'fit-content'}} disabled><i className='fa fa-check'/> Claimed</button>
                    }

                    {claimData && 
                    <>
                        <h5 className='text-blue bold mb-0 mt-3'>Status</h5>
                        <p className={`small saira px-2 mt-1 bold ${claimData.status ==='PENDING'?'text-pending':'text-success'}`}>{claimData.status}</p>
                        
                        <h5 className='text-blue bold mb-0 mt-3'>Bank / E-Wallet Name</h5>
                        <p className={`saira px-2 mt-1 text-blue`}>{claimData.bankWalletName}</p>

                        <h5 className='text-blue bold mb-0 mt-3'>Account Name</h5>
                        <p className='text-blue saira px-2 mt-1'>{claimData.accountName}</p>

                        <h5 className='text-blue bold mb-0 mt-3'>Account / Phone Number</h5>
                        <p className='text-blue saira px-2 mt-1'>{claimData.accountPhoneNumber}</p>
                    </>
                    }

                </div>

                {!eligible &&
                <div className='col-lg-10 text-center background-faded-error py-3 my-4 animate__animated animate__fadeIn' style={{borderRadius:"30px"}}>
                    <p className='text-error saira'>You will be invited in the <b>Opening</b> if you are <b>Eligible</b> to join the <b>Quarterly Demo Challenge.</b></p>
                </div>
                }
                

                {challengeStatus === 'closed' && !joined && eligible &&
                <div className='col-lg-10 text-center background-faded-error py-3 my-4 animate__animated animate__fadeIn' style={{borderRadius:"30px"}}>
                    <p className='text-error saira'>The <b>Quarterly Demo Challenge</b> is <b>CLOSED</b>. If you are <b>eligible</b> you will be invited in its opening.</p>
                </div>
                }

                {!joined && challengeStatus === 'open' && eligible &&
                <div className='col-lg-10 text-center background-faded-success py-3 my-4 animate__animated animate__fadeIn' style={{borderRadius:"30px"}}>
                    <p className='text-success saira'>You are <b>Eligible</b> to Join the <b>Quarterly Demo Challenge</b>. Join Now</p>
                </div>
                }

                {joined && challengeStatus === 'open' && eligible &&
                <div className='col-lg-10 text-center background-faded-light-blue py-3 my-4 animate__animated animate__fadeIn' style={{borderRadius:"30px"}}>
                    <p className='text-blue saira'>Successfully <b>REGISTERED</b> for the <b>Quarterly Demo Challenge</b></p>
                </div>
                } 

                {joined && challengeStatus === 'closed' && eligible &&
                <>
                    <div className='col-lg-10 text-center background-faded-light-blue py-3 mt-4 animate__animated animate__fadeIn' style={{borderRadius:"30px"}}>
                        <p className='text-blue saira'><b>Quarterly Demo Challenge</b> is <b>ONGOING</b>.</p>
                        
                    </div>
                    {demoAccount?
                    <>
                        <div className='col-lg-4 col-10 mb-4 mt-3'>
                            <button className='btn-solid w-100 py-2' style={{width:'fit-content'}} onClick={()=>setCheckingAccount(true)}><i className='fa fa-id-card'/> Account Credentials</button>
                        </div>
                        <div className='col-12'/>
                    </>
                    :
                    <p className='text-muted text-center mb-4 mt-3 bold'><i className='fa fa-lock'/> Wait for your Demo Account</p>
                    }
                </>
                }   

                {/*START: DESKTOP CHALLENGE REQUIREMENTS*/}
                <div className='col-lg-4 card-container-debossed py-4 px-5 d-none d-lg-block' style={{minWidth:"525px"}}>

                    <h5 className='text-center bold saira text-blue mb-3'><i className="fa-solid fa-file-circle-check"/> Requirements</h5>

                    <div className='row' style={{fontSize:"20px",rowGap:"10px"}}>
                        <div className='col-8 text-blue'>
                            <p className='saira'><i className='fa fa-circle-check text-light-blue me-2'/> Capital</p>
                        </div>
                        <div className='col-4 text-blue'>
                            <p className='saira text-light-blue text-end'>$500</p>
                        </div>

                        <div className='col-8 text-blue'>
                            <p className='saira'><i className='fa fa-circle-check text-light-blue me-2'/> Risk per Trade</p>
                        </div>
                        <div className='col-4 text-blue'>
                            <p className='saira text-light-blue text-end'>2-5%</p>
                        </div>

                        <div className='col-8 text-blue'>
                            <p className='saira'><i className='fa fa-circle-check text-light-blue me-2'/> Target Profit</p>
                        </div>
                        <div className='col-4 text-blue'>
                            <p className='saira text-light-blue text-end'>$2,000</p>
                        </div>

                        <div className='col-8 text-blue'>
                            <p className='saira'><i className='fa fa-circle-check text-light-blue me-2'/> Duration</p>
                        </div>
                        <div className='col-4 text-blue'>
                            <p className='saira text-light-blue text-end'>60 Days</p>
                        </div>

                        <div className='col-8 text-blue'>
                            <p className='saira'><i className='fa fa-circle-check text-light-blue me-2'/> Maximum Drawdown</p>
                        </div>
                        <div className='col-4 text-blue'>
                            <p className='saira text-light-blue text-end'>$250</p>
                        </div>

                        <div className='col-8 text-blue'>
                            <p className='saira'><i className='fa fa-circle-check text-light-blue me-2'/> Maximum Trades</p>
                        </div>
                        <div className='col-4 text-blue'>
                            <p className='saira text-light-blue text-end'>50</p>
                        </div>

                        <hr style={{borderTop:"2px black solid"}}/>

                        {!joined && eligible && challengeStatus === 'open'?
                        <button className='form-control btn-solid' onClick={()=>setJoining(true)}>Join</button>
                        :
                        <button className='form-control btn-solid' disabled>{joined?"Already Registered":"Join"}</button>
                        }

                    </div>

                </div>
                {/*END: DESKTOP CHALLENGE REQUIREMENTS*/}

                {/*START: MOBILE CHALLENGE REQUIREMENTS*/}
                <div className='col-lg-4 card-container-debossed py-4 px-4 d-block d-lg-none' style={{maxWidth:"475px"}}>

                    <h5 className='text-center bold saira text-blue mb-3'><i className="fa-solid fa-file-circle-check"/> Requirements</h5>

                    <div className='row' style={{fontSize:"14px",rowGap:"10px"}}>
                        <div className='col-8 text-blue'>
                            <p className='saira'><i className='fa fa-circle-check text-light-blue me-2'/> Capital</p>
                        </div>
                        <div className='col-4 text-blue'>
                            <p className='saira text-light-blue text-end'>$500</p>
                        </div>

                        <div className='col-8 text-blue'>
                            <p className='saira'><i className='fa fa-circle-check text-light-blue me-2'/> Risk per Trade</p>
                        </div>
                        <div className='col-4 text-blue'>
                            <p className='saira text-light-blue text-end'>2-5%</p>
                        </div>

                        <div className='col-8 text-blue'>
                            <p className='saira'><i className='fa fa-circle-check text-light-blue me-2'/> Target Profit</p>
                        </div>
                        <div className='col-4 text-blue'>
                            <p className='saira text-light-blue text-end'>$2,000</p>
                        </div>

                        <div className='col-8 text-blue'>
                            <p className='saira'><i className='fa fa-circle-check text-light-blue me-2'/> Duration</p>
                        </div>
                        <div className='col-4 text-blue'>
                            <p className='saira text-light-blue text-end'>60 Days</p>
                        </div>

                        <div className='col-8 text-blue'>
                            <p className='saira'><i className='fa fa-circle-check text-light-blue me-2'/> Maximum Drawdown</p>
                        </div>
                        <div className='col-4 text-blue'>
                            <p className='saira text-light-blue text-end'>$250</p>
                        </div>

                        <div className='col-8 text-blue'>
                            <p className='saira'><i className='fa fa-circle-check text-light-blue me-2'/> Maximum Trades</p>
                        </div>
                        <div className='col-4 text-blue'>
                            <p className='saira text-light-blue text-end'>50</p>
                        </div>
                        <hr style={{borderTop:"2px black solid"}}/>

                        {!joined && challengeStatus === 'open'?
                        <button className='form-control btn-solid' onClick={()=>setJoining(true)}>Join</button>
                        :
                        <button className='form-control btn-solid' disabled>{joined?"Already Registered":"Join"}</button>
                        }

                    </div>

                </div>
                {/*END: MOBILE CHALLENGE REQUIREMENTS*/}

                <div className='col-12'/>
                
                
                {<div className='col-lg-6 mt-5'>
                    
                    <h4 className='text-center saira text-blue bold m-0'><i className="fa-solid fa-person-circle-question me-2"/>Frequently Asked Questions</h4>
                    
                    <div className='row justify-content-center'>
                        <div className='col-6'>
                            <hr className='px-5 blue-hr' style={{opacity:"0.5"}}/>
                        </div>
                    </div>

                    <div className="accordion" id="accordionFAQ">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne">
                                What will I receive if I passed the challenge?
                            </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                You will be <b>funded</b> by the academy a <b>starting capital</b> of <b>$100</b> in your <b>live account</b>. 
                            </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo">
                                What if I didn't pass the challenge?
                            </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                You need to <b>retake</b> another <b>monthly demo challenge</b> that will be <b>covered by the next quarterly demo challenge</b>. <br/><br/><span className='text-muted'>For example, you <b>failed</b> the <b>first quarterly demo challenge (April)</b> and all the <b>eligible participants</b> are the <b>last three monthly winners (January - March)</b>, you need to take the <b>monthly demo challenge</b> at least one from <b>June – March</b> to be <b>eligible</b> to take the <b>second quarterly demo challenge</b> to be held on July. And the same criteria apply to third and fourth quarters.</span>
                            </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree">
                                How many times can I join?
                            </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                You are <b>eligible</b> to join if you <b>pass</b> the <b>monthly demo challenge</b> covered by the <b>quarter</b> where you want to join in.
                            </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFour">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour">
                                Will I be receiving rewards everytime I win the Demo Challenge.
                            </button>
                            </h2>
                            <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                No. Rewards can only be claimed once.
                            </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingSeven">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven">
                                I won the Quarterly Demo Challenge. Can I still join the next quarterly demo challenge?
                            </button>
                            </h2>
                            <div id="collapseSeven" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                No. You are not eligible to take any of the demo challenges either monthly or quarterly because you will automatically be moved to <b>Quarter Circle.</b>
                            </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingEight">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight">
                                What is this Quarter Circle?                            
                            </button>
                            </h2>
                            <div id="collapseEight" className="accordion-collapse collapse" data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                This is the group where we separate those students who receive funds from Trading Circle. An intense risk management and live strategizing will be tackled during extensive trainings.                            </div>
                            </div>
                        </div>
                    </div>

                </div>}

            </div>

        </div>

        <ModalDemoAcount show={checkingAccount} onHide={()=>setCheckingAccount(false)} demoAccount={demoAccount} />
        <ModalClaimFund show={claimingPrize} onHide={()=>setClaimingPrize(false)} student={student} uid={uid} claimData={claimData} />
        
        <ModalLoading show={processing} />
        <ModalConfirmation show={joining} onConfirm={()=>joinChallenge()} onHide={()=>setJoining(false)} message={student && `Join the Quarterly Demo Challenge using this account (${student.profile.emailAddress})?`}/>
    
    </>
    )
}