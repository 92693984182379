import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const funnelConfig = {
  apiKey: "AIzaSyAwuYlCSMhgEc_Zlj8ZbkosYMR2SFteX1E",
  authDomain: "trading-circle-ph-admin.firebaseapp.com",
  databaseURL: "https://trading-circle-ph-funnel.firebaseio.com",
  projectId: "trading-circle-ph-admin",
  storageBucket: "trading-circle-ph-admin.appspot.com",
  messagingSenderId: "902115128441",
  appId: "1:902115128441:web:8a64161362776141e690a8"
};

const app = initializeApp(funnelConfig,'Funnel Configuration');

export const funnelDB = getDatabase(app);
