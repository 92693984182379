
import { NavLink } from 'react-router-dom'
import blueFullLogo from '../../img/logo/blue-full-logo.png'
import whiteFullLogo from '../../img/logo/white-full-logo.png'

export const PolicySidebar = ()=>{
    return(
        <>
            <div className='col-lg-4 d-lg-block d-none'>
                <div className="policy-sidebar text-end">

                    <a href='/'><img src={blueFullLogo} alt='logo' width="40%"/></a>
                    <h3 className='text-blue saira bold mt-3'>Policy Center</h3>

                    <div className='policy-sidebar-container mt-4'>

                        <div>
                            <NavLink to="/policies/privacy-policy">
                                <i className='fa fa-lock'/> Privacy Policy <span className='fa fa-chevron-right ms-3'/>
                            </NavLink>
                        </div>

                        <div>
                            <NavLink to="/policies/terms-and-conditions">
                                <i className='fa-solid fa-file-circle-question'/> Terms and Conditions<span className='fa fa-chevron-right ms-3'/>
                            </NavLink>
                        </div>

                        <div>
                            <NavLink to="/policies/video-copyright">
                                <i className='fa-solid fa-video'/> Video Copyright<span className='fa fa-chevron-right ms-3'/>
                            </NavLink>
                        </div>

                        <div>
                            <NavLink to="/policies/refund-policy">
                                <i className='fa-solid fa-money-bill'/> Refund Policy<span className='fa fa-chevron-right ms-3'/>
                            </NavLink>
                        </div>

                        <div>
                            <NavLink to="/policies/disclaimer">
                                <i className='fa-solid fa-warning'/> Disclaimer<span className='fa fa-chevron-right ms-3'/>
                            </NavLink>
                        </div>

                        <div>
                            <NavLink to="/policies/legal-documents">
                                <i className='fa-solid fa-scroll'/> Legal Documents<span className='fa fa-chevron-right ms-3'/>
                            </NavLink>
                        </div>

                        <hr/>
                        
                        <div>
                            <NavLink to="/">
                                <i className='fa-solid fa-house'/> Back to Home
                            </NavLink>
                        </div>

                    </div>

                </div>
            </div>

            <nav class="navbar navbar-expand-lg navbar-dark bg-blue d-block d-lg-none sticky-top py-3" style={{borderRadius:'0px 0px 25px 25px'}}>
                <div class="container-fluid">

                    <a href='/'><img src={whiteFullLogo} width="75%" alt='logo' style={{maxWidth:'235px'}}/></a>
                    <button class="navbar-toggler bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon" style={{backdropFilter:'brightness(100%)'}}></span>
                    </button>

                    <div class="collapse navbar-collapse navbar-policies" id="navbarScroll">
                        <ul class="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
                            <li class="nav-item">
                                <NavLink to="/policies/privacy-policy">
                                    <i className='fa fa-lock'/> Privacy Policy <span className='fa fa-chevron-right ms-3'/>
                                </NavLink>
                            </li>
                            <li class="nav-item">
                                <NavLink to="/policies/terms-and-conditions">
                                    <i className='fa-solid fa-file-circle-question'/> Terms and Conditions<span className='fa fa-chevron-right ms-3'/>
                                </NavLink>
                            </li>
                            <li className='nav-item'>
                                <NavLink to="/policies/refund-policy">
                                    <i className='fa-solid fa-money-bill'/> Refund Policy<span className='fa fa-chevron-right ms-3'/>
                                </NavLink>
                            </li>
                            <li className='nav-item'>
                                <NavLink to="/policies/disclaimer">
                                    <i className='fa-solid fa-warning'/> Disclaimer<span className='fa fa-chevron-right ms-3'/>
                                </NavLink>
                            </li>
                            <li className='nav-item'>
                                <NavLink to="/policies/legal-documents">
                                    <i className='fa-solid fa-scroll'/> Legal Documents<span className='fa fa-chevron-right ms-3'/>
                                </NavLink>
                            </li>

                            <hr className='white-hr m-0 my-2'/>

                            <li className='nav-item'>
                                <NavLink to="/">
                                    <i className='fa-solid fa-house'/> Back to Home
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            
        </>
    )
}