
import { Helmet } from 'react-helmet-async';
import { Footer } from '../../components/academy/Footer';
import { useEffect, useState } from 'react';

import {Header} from '../../components/general/Header'

import { getFileExt, randomIntFromInterval, shortenDescription } from '../../helper/formatter';
import bgShapeDark from "../../img/bg/bg-shape-dark.png"
import irregShape from "../../img/bg/irregular-bg.png"
import $ from 'jquery'
import { allowedVideos } from '../../helper/globals';
import ModalLoading from '../../components/general/ModalLoading';
import { onValue, ref, set } from 'firebase/database';
import { getDownloadURL, ref as sref } from 'firebase/storage';

import { onAuthStateChanged } from 'firebase/auth';
import { studentAuth, studentDB, studentStorage } from '../../api/firebase-student-config';
import { uploadBytes } from 'firebase/storage';

import moment from 'moment'

export const TestimonialSubmission = () =>{


    var [processing,setProcessing] = useState(false);
    var [videoIDs] = useState(['es-bGGXpse0', '87ZRTlwniec', 's2dkYYvqLsI', 'TRcVe8G4cbs', '0yOG0AejxMM', 's3ERRrmDzD8'])
    var [uploadedVideo,setUploadedVideo] = useState();
    var [student,setStudent] = useState(null);

    var [submission,setSubmission] = useState();

    useEffect(()=>{

        onAuthStateChanged(studentAuth,(student)=>{
      
            if(student){
        
              onValue(ref(studentDB,"students/" + student.uid),(snapshot)=>{
                if(snapshot.exists()){

                    var s = snapshot.val()
                    s.uid = student.uid

                    setStudent(s);
                    setProcessing(false);
                }
              })
        
            }else setProcessing(false);
        
        })

    },[])

    useEffect(()=>{

        if(student){

            onValue(ref(studentDB,`testimonialSubmissions/${student.uid}`),(snapshot)=>{
                if(snapshot.exists()){
                    setSubmission(snapshot.val());
                }else setSubmission(null);
            })
        }

    },[student])

    function uploadVideo(){
        $("#btnUploadVideo").trigger('click')
    }

    function verifyVideo(){
        var f = $("#btnUploadVideo").prop("files")[0];
        setUploadedVideo(null);
        

        if(!allowedVideos.includes(getFileExt(f.name.toUpperCase()))){
            alert("You can only upload " + allowedVideos);
        }else if(f.size / 1000 / 1000 > 3000){
            alert("You can only upload files 3GB below.");
        }else{
            setUploadedVideo(f);
        }   
    }

    function submitVideo(){

        setProcessing(true);

        

        uploadBytes(sref(studentStorage,`/students/testimonialSubmissions/${student.uid}.${getFileExt(uploadedVideo.name)}`),uploadedVideo).then((result)=>{
            
            getDownloadURL(sref(studentStorage,`/students/testimonialSubmissions/${student.uid}.${getFileExt(uploadedVideo.name)}`)).then((url)=>{

                var d = moment().format('YYYY-MM-DD');

                set(ref(studentDB,`testimonialSubmissions/${student.uid}`),{status:'PENDING',name:uploadedVideo.name,videoURL:url,date:d}).then(()=>{
                    setProcessing(false);
                });
            })
            
            
        })

    }

    return(
        <>
            <Helmet>
                <title>Trading Circle - Testimonial Submission</title>
            </Helmet>
            
            <div className="container-fluid animate__animated animate__fadeIn py-4">

                <Header title='Testimonial Submission'/>

                <div className='row justify-content-center mb-5 d-flex mt-3' >
                    <div className="col-lg-8">
                        <div className="video-container">
                            <iframe className='d-lg-block' src="https://player.vimeo.com/video/872592650?h=2e1e8796c2&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" title="Advanced Training"/>
                        </div>
                    </div>

                    {!uploadedVideo && !submission &&
                    <>
                        <div className='col-9 d-lg-block d-none mt-4' data-aos="fade-up">
                            <button onClick={()=>uploadVideo()} className='form-control btn-solid bold py-4 px-5 w-100 mt-3 animate__animated animate__pulse animate__infinite animate__slow btn-funnel' style={{width:"fit-content",fontSize:'28px'}}><i class="fa-solid fa-upload"></i> Submit My Testimonial Video!</button>
                        </div>
                        <div className='col-11 d-lg-none d-block mt-4' data-aos="fade-up">
                            <button onClick={()=>uploadVideo()} className='form-control btn-solid bold py-4 px-5 w-100 mt-3 animate__animated animate__pulse animate__infinite animate__slow btn-funnel' style={{width:"fit-content",fontSize:'20px'}}><i class="fa-solid fa-upload"></i> Submit My Testimonial Video!</button>
                        </div>
                    </>
                    }

                    <input onChange={()=>verifyVideo()} id='btnUploadVideo' accept="video/mp4,video/x-m4v,video/*" type="file" className='d-none'/>
                    
                    {uploadedVideo && !submission &&
                    <>
                        <div className='col-lg-6 col-11 mt-5 mb-3 text-light-blue outline'>
                            <h4 className='text-center my-2 d-lg-block d-none' ><i className='fa fa-video'/> {shortenDescription(uploadedVideo.name,50)}</h4>
                            <h5 className='text-center my-2 d-lg-none d-block' ><i className='fa fa-video'/> {shortenDescription(uploadedVideo.name,50)}</h5>
                        </div>
                        <div className='col-12'/>
                        <div className='col-lg-2 mb-lg-0 mb-2'>
                            <button className='btn-solid px-3 py-2 w-100' onClick={()=>uploadVideo()}><i className='fa fa-upload'/> Re-Select Testimonial</button>
                        </div>
                        <div className='col-lg-4'>
                            <button className='btn-solid px-3 py-2 w-100' onClick={()=>submitVideo()}><i className='fa fa-check'/> Submit Testimonial</button>
                        </div>
                    </>
                    }

                    {submission &&
                    <>
                        <div className='col-lg-6 col-11 mt-5 mb-3 text-light-blue outline px-5'>
                            <h4 className='text-center my-2 d-lg-block d-none' ><i className='fa fa-video'/> {shortenDescription(submission.name,50)}</h4>
                            <h5 className='text-center my-2 d-lg-none d-block' ><i className='fa fa-video'/> {shortenDescription(submission.name,50)}</h5>
                        </div>
                        <div className='col-12'/>

                        <div className={`col-lg-4 col-9 mb-3 pill text-white ${submission.status !== 'PENDING' && 'animate__animated animate__tada'}`} style={{borderRadius:'100px',opacity:submission.status === 'PENDING'?0.5:1}}>
                            <h5 className={`text-center my-2 d-lg-block d-none`}><b><i className='fa fa-circle-info'/> STATUS:</b> <span>{submission.status}</span></h5>
                            <h6 className={`text-center my-2 d-lg-none d-block`}><b><i className='fa fa-circle-info'/> STATUS:</b> <span>{submission.status}</span></h6>

                        </div>
                    </>
                    }

                    <div className='col-12 mt-5 pt-5 text-white' style={{background:`url('${bgShapeDark}')`,backgroundSize:'cover',backgroundPosition:'center',minHeight:'950px'}}>

                        <h2 className='text-center bold mt-5' data-aos="fade-up"><i className='fa fa-star'/> <i className='fa fa-star'/> <i className='fa fa-star'/> <i className='fa fa-star'/> <i className='fa fa-star'/></h2>
                        <h2 className='text-center bold black' style={{fontSize:'calc(1.25rem + 0.95vw)',marginBottom:'75px'}} data-aos="fade-up">Testimonials</h2>

                        <div className='row text-center mt-5 justify-content-center' style={{gap:"50px 50px"}}>

                            {videoIDs &&
                            videoIDs.map((id)=>{
                                return(
                                <div className='col-lg-3 col-11 services-video' data-aos="fade-up">
                                    <div style={{"height":"0px","overflow": "hidden","paddingTop":"56.25%","position":"relative","width": "100%",'borderRadius':'25px',rotate:`${randomIntFromInterval(-2,2)}deg`}}>
                                        <iframe
                                            style={{"position":"absolute","top":"0px","left":"0px","width":"100%", "height":"100%"}}
                                            src={`https://tube.rvere.com/embed?v=${id}`}
                                            title="YouTube video player"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            sandbox="allow-scripts allow-forms allow-same-origin allow-presentation"
                                            allowFullScreen>
                                        </iframe>
                                    </div>
                                </div>
                                )
                            })}
                            
                        </div>

                    </div>
                </div>

            </div>

            <div className='d-lg-block d-none' style={{background:`url('${irregShape}')`,width:'125%',height:'1507px',position:'absolute',top:'0',zIndex:'-999',backgroundPosition:'center center',right:'-12.5%',backgroundSize:'cover',opacity:'0.3'}}/>
            <div className='d-lg-none d-block' style={{background:`url('${irregShape}')`,width:'100%',height:'1005px',position:'absolute',top:'-100px',zIndex:'-999',backgroundPosition:'center center',backgroundSize:'cover',opacity:'0.2'}}/>
            
            <Footer/>

            <ModalLoading show={processing}/>
        </>
    );

}