
import { Helmet } from 'react-helmet-async';
import blackWordLogo from '../../img/logo/black-word-logo.png';
import { FunnelFooter } from '../../components/landing/FunnelFooter';
import irregShape from "../../img/bg/irregular-bg.png"

import allShirt from '../../img/merch/shirt-all.png'
import allShirt2 from '../../img/merch/shirt-all-2.png'

import xmasBlue from '../../img/merch/blue.png'
import xmasBlack from '../../img/merch/black.png'
import xmasRed from '../../img/merch/red.png'
import xmasGreen from '../../img/merch/green.png'
import xmasWhite from '../../img/merch/white.png'
import xmasYellow from '../../img/merch/yellow.png'

import nyBlack from '../../img/merch/ny-black.png'
import nyBlue from '../../img/merch/ny-blue.png'
import nyWhite from '../../img/merch/ny-white.png'

import { useEffect, useState } from 'react';
import $ from 'jquery'
import { numberWithCommas } from '../../helper/formatter';
import ModalPreOrder from '../../components/funnel/ModalPreOrder';

export const TradingCircleFamilyShirt = () =>{

    const [shirtCount] = useState([1,2,3,4,5])

    const merchImage = {
        'xmasBlue':xmasBlue,
        'xmasBlack':xmasBlack,
        'xmasRed':xmasRed,
        'xmasGreen':xmasGreen,
        'xmasWhite':xmasWhite,
        'xmasYellow':xmasYellow,
        'nyWhite':nyWhite,
        'nyBlue':nyBlue,
        'nyBlack':nyBlack
    }

    const discount ={
        3:200,
        4:200,
        5:300
    }


    const shippingFee = {
        1:75
    }

    const mugs = {
        5:1
    }

    const [cart,setCart] = useState([{
        color:'xmasBlue',
        size:'M',
        key:0
    }]);

    const [preOrdering,setPreOrdering] = useState(false)

    function addItem(){

        var newItem1 = {
            color:'xmasBlue',
            size:'M',
            key:cart.length
        }

        var c = [...cart];
        c.push(newItem1);
        setCart(c);
    }

    function changeColor(c,e){
        c.color = $(e.target).val();
        setCart([...cart])
    }

    function changeSize(c,e){
        c.size = $(e.target).val();
        setCart([...cart])
    }

    function removeItem(c){        
        cart.splice(c.key, 1); 
        setCart([...cart])
    }

    useEffect(()=>{

        for(var i = 0;i < cart.length;i++){
            cart[i].key = i;
        }

    },[cart])

    return(
        <>
            <Helmet>
                <title>Trading Circle - Family Shirt</title>
            </Helmet>
            
            <div className="container-fluid animate__animated animate__fadeIn py-4">

                <div className='row justify-content-center mt-lg-3 mt-0'>

                    <div className='col-12 text-center mb-4'>
                        <img className='d-none d-lg-inline darkmode-logo' src={blackWordLogo} width='20%' alt='logo'/>
                        <img className='d-inline d-lg-none darkmode-logo' src={blackWordLogo} width='70%' alt='logo'/>
                        <div className='row justify-content-center mb-3'>
                            <div className='col-lg-6 col-12'>
                                <hr className='blue-hr my-3' style={{opacity:'0.5'}}/>
                            </div>
                        </div>
                        <h1 className='bold mb-2 text-blue black'>Limited Edition Family Shirt</h1>
                        <h4 className='bold' style={{fontSize:'calc(1rem + 0.5vw)'}}>Reserve your order now!</h4>
                    </div>

                    <div className='col-12 text-center'>
                        <img className='d-lg-inline d-none pt-5 pb-2' src={allShirt} width='40%' alt='shirt'/>
                        <img className='d-lg-none d-inline pt-5 pb-2' src={allShirt} width='95%' alt='shirt'/>
                    </div>

                    <div className='col-12 text-center'>
                        <img className='d-lg-inline d-none pb-5 pt-2' src={allShirt2} width='40%' alt='shirt'/>
                        <img className='d-lg-none d-inline pb-5 pt-2' src={allShirt2} width='95%' alt='shirt'/>
                    </div>

                    <div className='col-lg-10 col-12 justify-content-center'>

                        <div className='row justify-content-center' style={{rowGap:'15px',columnGap:'15px'}}>
                            {shirtCount.map((s)=>{
                                return(
                                <div className='card-container px-5 py-5 text-center' style={{maxWidth:'fit-content'}}>
                                    <h4 className='text-blue bold'><i className='fa fa-shirt'/> {s} Shirt/s</h4>
                                    <p>Original Price : <b>₱ {numberWithCommas(s * 399)}</b></p>
                                    <p className={shippingFee[s]?`text-error`:'text-success'}>Shipping Fee : <b>{shippingFee[s]?`₱ ${numberWithCommas(shippingFee[s])}`:`FREE`}</b></p>
                                    <p className={discount[s]?`text-success`:'text-error'}>Discount : <b>{discount[s]?`₱ ${numberWithCommas(discount[s])}`:`₱ 0`}</b></p>
                                    {mugs[s] && <p className='text-success'>Freebie : <b>{mugs[s]} TC Mug</b></p>}
                                    <hr/>
                                    <p style={{fontSize:'20px'}}>Total Price</p>
                                    {discount[s] && <p className='text-error' style={{fontSize:'20px',textDecoration:'line-through'}}>₱ {numberWithCommas((s * 399) + (shippingFee[s]?shippingFee[s]:0))}</p>}
                                    <p className='bold' style={{fontSize:'24px'}}>₱ {numberWithCommas((s * 399) + (shippingFee[s]?shippingFee[s]:0) - (discount[s]?discount[s]:0))}</p>
                                </div>
                                )
                            })}
                        </div>

                        

                    </div>

                    <div className='col-12 text-center mb-4'>
                        <div className='row justify-content-center mb-3'>
                            <div className='col-lg-6 col-12'>
                                <hr className='blue-hr my-3' style={{opacity:'0.5'}}/>
                            </div>
                        </div>
                        <h1 className='bold mb-2 text-blue black'><i className='fa fa-shopping-cart'/> Cart</h1>
                        <h5 className='bold' style={{fontSize:'calc(1rem + 0.5vw)'}}>Place Your Order Here</h5>
                        <p style={{fontSize:'calc(0.65rem + 0.4vw)'}}>You can order up to <b>10 shirts</b> per transaction, so that you can avail our <b>DISCOUNT!</b></p>
                    </div>

                    {cart.length === 0 && <h3 className='text-muted pt-4 pb-5 text-center black animate__animated animate__fadeIn' style={{opacity:'0.5'}}>CART EMPTY</h3>}

                    {cart.map((c)=>{
                        return(
                        <div className='text-center my-5 mx-5 animate__animated animate__fadeIn' style={{maxWidth:'300px',position:'relative'}}>
                            <img className='mb-3' src={merchImage[c.color]} width='250px' alt='shirt'/>
                            <label className='saira bold text-blue mb-1' htmlFor="slcType">Color</label>
                            <select defaultValue={c.color} className='form-select' id='slcType' onChange={(e)=>changeColor(c,e)}>
                                <option value='xmasBlue'>Christmas Blue</option>
                                <option value='xmasBlack'>Christmas Black</option>
                                <option value='xmasRed'>Christmas Red</option>
                                <option value='xmasGreen'>Christmas Green</option>
                                <option value='xmasWhite'>Christmas White</option>
                                <option value='xmasYellow'>Christmas Yellow</option>
                                <option value='nyBlack'>New Year Black</option>
                                <option value='nyBlue'>New Year Blue</option>
                                <option value='nyWhite'>New Year White</option>
                            </select>

                            <label className='saira bold text-blue mt-2' htmlFor="slcSize">Size</label>
                            <select defaultValue={c.size} className='form-select' id='slcSize' onChange={(e)=>changeSize(c,e)}>
                                <option value='XS'>Extra Small (XS)</option>
                                <option value='S'>Small (S)</option>
                                <option value='M'>Medium (M)</option>
                                <option value='L'>Large (L)</option>
                                <option value='XL'>Extra Large (XL)</option>
                                <option value='XXL'>Double Extra Large (XXL)</option>
                                <option value='XXXL'>Triple Extra Large (XXXL)</option>
                            </select>
                            
                            <i className='text-blue fa fa-times pointer' onClick={()=>removeItem(c)} style={{position:'absolute',top:'0',right:'0',fontSize:'20px'}}/>
                        </div>
                        )
                    })}

                    <div className='col-12 justify-content-center text-center'>
                        {cart.length < 5 && <button onClick={()=>addItem()} className='btn-outline' style={{width:'fit-content',padding:'10px 100px',fontSize:'16px'}}><i className='fa fa-add'/> ADD MORE SHIRTS TO AVAIL THE DISCOUNT</button>}
                    </div>

                    <div className='row justify-content-center mb-3'>
                        <div className='col-lg-6 col-12'>
                            <hr className='blue-hr my-3' style={{opacity:'0.5'}}/>
                        </div>
                        <div className='col-12'/>
                        <div className='col-lg-6 col-12 ps-5'>

                            {cart.length > 0 &&
                            <div className={`row ${shippingFee[cart.length]?`text-error`:`text-success`} animate__animated animate__fadeIn`}>
                                <div className='col-lg-4 col-6'>
                                    <h4 style={{fontSize:'calc(1rem + 0.3vw)'}}>Shipping Fee : </h4>
                                </div>
                                <div className='col-lg-8 col-6'>
                                    <h4 className='black' style={{fontSize:'calc(1rem + 0.3vw)'}}>{shippingFee[cart.length]?`₱ ${numberWithCommas(shippingFee[cart.length])}`:`FREE`}</h4>
                                </div>
                            </div>
                            }


                            <div className='row text-blue animate__animated animate__tada'>
                                <div className='col-lg-4 col-6'>
                                    <h4 style={{fontSize:'calc(1rem + 0.5vw)'}}>Original Price : </h4>
                                </div>
                                <div className='col-lg-8 col-6'>
                                    <h4 className='black' style={{fontSize:'calc(1rem + 0.5vw)'}}>₱ {numberWithCommas(cart.length * 399)} ({cart.length} Shirt/s)</h4>
                                </div>
                            </div>

                            {discount[cart.length] &&
                            <>
                                <div className='row text-success animate__animated animate__fadeIn'>
                                    <div className='col-lg-4 col-6'>
                                        <h4 style={{fontSize:'calc(1rem + 0.5vw)'}}>Discount : </h4>
                                    </div>
                                    <div className='col-lg-8 col-6'>
                                        <h4 className='black' style={{fontSize:'calc(1rem + 0.5vw)'}}>₱ {numberWithCommas(discount[cart.length])}</h4>
                                    </div>
                                </div>
                            </>
                            }

                            {mugs[cart.length] &&
                            <>
                                <div className='row text-success animate__animated animate__fadeIn'>
                                    <div className='col-lg-4 col-6'>
                                        <h4 style={{fontSize:'calc(1rem + 0.5vw)'}}>Freebie : </h4>
                                    </div>
                                    <div className='col-lg-8 col-6'>
                                        <h4 className='black' style={{fontSize:'calc(1rem + 0.5vw)'}}>{mugs[cart.length]} TC Mug</h4>
                                    </div>
                                </div>
                            </>
                            }

                            <hr/>
                            <div className='row text-light-blue animate__animated animate__fadeIn'>
                                <div className='col-lg-4 col-6'>
                                    <h4 style={{fontSize:'calc(1rem + 0.65vw)'}}>Total Price : </h4>
                                </div>
                                <div className='col-lg-8 col-6'>
                                    {discount[cart.length] && !shippingFee[cart.length] && <h4 className='black' style={{fontSize:'calc(1rem + 0.65vw)'}}>₱ {numberWithCommas((cart.length * 399) - discount[cart.length])}</h4>}
                                    {shippingFee[cart.length] && !discount[cart.length] && <h4 className='black' style={{fontSize:'calc(1rem + 0.65vw)'}}>₱ {numberWithCommas((cart.length * 399) + shippingFee[cart.length])}</h4>}
                                    {shippingFee[cart.length] && discount[cart.length] && <h4 className='black' style={{fontSize:'calc(1rem + 0.65vw)'}}>₱ {numberWithCommas((cart.length * 399) + shippingFee[cart.length] - discount[cart.length])}</h4>}
                                    {!shippingFee[cart.length] && !discount[cart.length] && <h4 className='black' style={{fontSize:'calc(1rem + 0.65vw)'}}>₱ {numberWithCommas((cart.length * 399))}</h4>}
                                </div>
                            </div>
                            
                            {cart.length !== 0 && <button onClick={()=>setPreOrdering(true)} className='btn-solid w-100 mt-3' style={{padding:'10px 100px',fontSize:'16px'}}><i className='fa fa-shopping-bag'/> CONFIRM ORDER & PAYMENT</button>}

                            
                        </div>
                    </div>



                    
                </div>

            </div>

            <div className='d-lg-block d-none' style={{background:`url('${irregShape}')`,width:'125%',height:'1507px',position:'absolute',top:'0',zIndex:'-999',backgroundPosition:'center center',right:'-12.5%',backgroundSize:'cover',opacity:'0.05'}}/>
            <div className='d-lg-none d-block' style={{background:`url('${irregShape}')`,width:'100%',height:'1005px',position:'absolute',top:'-100px',zIndex:'-999',backgroundPosition:'center center',backgroundSize:'cover',opacity:'0.05'}}/>
            
            <ModalPreOrder show={preOrdering} onHide={()=>setPreOrdering(false)} cart={cart} pricing={{originalPrice:cart.length * 399,discount:discount[cart.length]?discount[cart.length]:0,shippingFee:shippingFee[cart.length]?shippingFee[cart.length]:0,mugs:mugs[cart.length]?mugs[cart.length]:0}}/>
            <FunnelFooter/>
        </>
    );

}