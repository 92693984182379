
import { Helmet } from 'react-helmet-async';
import blackWordLogo from '../../img/logo/black-word-logo.png';
import { FunnelFooter } from '../../components/landing/FunnelFooter';
import { useState } from 'react';
import irregShape from "../../img/bg/irregular-bg.png"

import { useEffect } from 'react';
import moment from 'moment'
import $ from 'jquery'
import {set,ref,push} from 'firebase/database'
import { funnelDB } from '../../api/firebase-funnel-config';

export const PriceIncrease = (props) =>{

    function goToEnrollment(){
        
        if(props.origin === 'YouTube') window.location.href = '/enrollment-yt'
        else window.location.href = '/enrollment'

    }

    const [pageID,setPageID] = useState()

    useEffect(()=>{

        if(pageID){
            var now = moment().format('MMMM DD YYYY')

            if(pageID){

                $.getJSON("https://api64.ipify.org?format=json", function(e){

                    var cleanedIP = String(e.ip).split('.').join('-')

                    push(ref(funnelDB,`analytics/impressions/${pageID}/${now}`),e.ip).then(()=>{

                        set(ref(funnelDB,`analytics/unique/${pageID}/${now}/${cleanedIP}`),'VISITED').then(()=>{

                        })

                    })

                });

            }
        }else setPageID('services')

    },[pageID])

    return(
        <>
            <Helmet>
                <title>Trading Circle - Free Training</title>
                <script>
                    window.fbq("track", "FreeTraining");
                </script>
            </Helmet>
            
            <div className="container-fluid animate__animated animate__fadeIn py-4">

                <div className='row justify-content-center mt-lg-3 mt-0'>

                    <div className='col-12 text-center mb-4'>
                        <img className='d-none d-lg-inline darkmode-logo' src={blackWordLogo} width='20%' alt='logo'/>
                        <img className='d-inline d-lg-none darkmode-logo' src={blackWordLogo} width='70%' alt='logo'/>
                        <div className='row justify-content-center'>
                            <div className='col-lg-6 col-12'>
                                <hr className='blue-hr my-3' style={{opacity:'0.5'}}/>
                                <h1 className='bold text-blue black' style={{fontSize:'calc(1rem + 0.75vw)'}}>THE BIG DAY ANNOUNCEMENT</h1>
                                <h4 className='bold' style={{fontSize:'calc(1rem + 0.35vw)'}}>May Mawawala, May Mamamaalam, May Mababago.</h4>
                            </div>
                        </div>
                        
                    </div>

                    <div className='col-lg-8 col-12' data-aos="fade-up">
                        <div className='video-container' style={{padding:"56.25% 0 0 0",position:"relative"}}><iframe src="https://player.vimeo.com/video/905503528?h=a2c5214b0d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{position:"absolute",top:"0",left:"0",width:"100%",height:"100%"}} title="Website Walkthrough"></iframe></div>
                        <script src="https://player.vimeo.com/api/player.js"></script>
                    </div>

                    <div className='col-9 d-lg-block d-none mt-4' data-aos="fade-up">
                        <button onClick={()=>goToEnrollment()} className='form-control btn-solid bold py-4 px-5 w-100 mt-3 animate__animated animate__pulse animate__infinite animate__slow btn-funnel' style={{width:"fit-content",fontSize:'28px'}}><i class="fa-solid fa-tag"></i> GRAB THE ₱ 4,997 BEFORE FEBRUARY 1</button>
                    </div>

                    <div className='col-11 d-lg-none d-block mt-4'>
                        <button onClick={()=>goToEnrollment()} style={{fontSize:'calc(0.75rem + 1 vw)'}} className='form-control btn-solid bold py-3 px-5 mt-4 w-100 animate__animated animate__pulse animate__infinite animate__slow btn-funnel'><i class="fa-solid fa-tag"></i> GRAB THE ₱ 4,997 BEFORE FEBRUARY 1</button>
                    </div>

                </div>

            </div>

            <div className='d-lg-block d-none' style={{background:`url('${irregShape}')`,width:'125%',height:'1507px',position:'absolute',top:'0',zIndex:'-999',backgroundPosition:'center center',right:'-12.5%',backgroundSize:'cover',opacity:'0.3'}}/>
            <div className='d-lg-none d-block' style={{background:`url('${irregShape}')`,width:'100%',height:'1005px',position:'absolute',top:'-100px',zIndex:'-999',backgroundPosition:'center center',backgroundSize:'cover',opacity:'0.2'}}/>
            
            <FunnelFooter/>
        </>
    );

}