import { Helmet } from "react-helmet-async"
import { PolicySidebar } from "../../components/general/PolicySidebar"


export const VideoCopyright = () =>{

    return(
        <div className="container-fluid">

            <Helmet>
                <title>Trading Circle - Video Copyright</title>
            </Helmet>

            <div className="row justify-content-center p-lg-5 p-0">

                <PolicySidebar/>

                <div className="col-lg-6 text-blue policy-container ps-lg-4 px-5 animate__animated animate__fadeIn">
                    <h3 className="bold saira mt-lg-5 mt-4">Copyright of Videos</h3>
                    <h5 className="bold saira">Introduction</h5>

                    <div className="content mt-3 mb-5">
                        <p className="mb-4 text-justify">These terms and conditions ("Agreement") govern the use of private videos and related content ("Content") accessible through the Trading Circle platform ("Platform"). By accessing or using the Platform, you agree to comply with and be bound by these terms.</p>
                    </div>

                    <h5 className="bold saira">Copyright & Usage</h5>
                    <div className="content mt-3 mb-5">
                        <p className="mb-4 text-justify">All private videos and content on the Platform are protected by copyright laws. Users are granted a non-exclusive, non-transferable license to access and view the private videos for personal, non-commercial use only. Any unauthorized use, reproduction, or distribution of the Content is strictly prohibited.</p>
                    </div>

                    <h5 className="bold saira">Downloads Prohibited</h5>
                    <div className="content mt-3 mb-5">
                        <p className="mb-4 text-justify">Users are expressly prohibited from downloading, saving, or otherwise creating copies of private videos. The Content is intended for streaming and viewing only, and any attempt to download or store the videos is a violation of these terms and may result in legal action.</p>
                    </div>

                    <h5 className="bold saira">Account Sharing</h5>
                    <div className="content mt-3 mb-5">
                        <p className="mb-4 text-justify">Sharing of user accounts is strictly prohibited. Each account is intended for the use of the individual subscriber only. Sharing login credentials with others is a violation of these terms and may result in the immediate termination of the account.</p>
                    </div>

                    <h5 className="bold saira">Penalty for Violation</h5>
                    <div className="content mt-3 mb-5">
                        <p className="mb-4 text-justify">In the event of an account termination due to violation of these terms, the user agrees to pay a penalty of 3 million pesos as liquidated damages. This penalty is intended to compensate Trading Circle for the harm caused by the violation and is not a penalty for punitive purposes.</p>
                    </div>

                    <h5 className="bold saira">Dispute Resolution</h5>
                    <div className="content mt-3 mb-5">
                        <p className="mb-4 text-justify">Any disputes arising out of or in connection with this Agreement will be resolved through arbitration in accordance with the laws of the Republic of the Philippines. The prevailing party in any such arbitration shall be entitled to recover its reasonable attorneys' fees and costs.</p>
                    </div>

                    <h5 className="bold saira">Changes to Terms</h5>
                    <div className="content mt-3 mb-5">
                        <p className="mb-4 text-justify">Trading Circle reserves the right to modify or update these terms and conditions at any time. Users will be notified of any changes, and continued use of the Platform constitutes acceptance of the revised terms.</p>
                    </div>

                    <h5 className="bold saira">Governing Law</h5>
                    <div className="content mt-3 mb-5">
                        <p className="mb-4 text-justify">This Agreement shall be governed by and construed in accordance with the laws of the Republic of the Philippines, without regard to its conflict of law principles.</p>
                    </div>

                    <h5 className="bold saira">Contact Information</h5>
                    <div className="content mt-3 mb-5">
                        <p className="mb-4 text-justify">For questions or concerns regarding these terms and conditions, please contact Coach Alex Balingit at 0929-853-5283.</p>
                        <p className="mb-4 text-justify">By using the Platform, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions.</p>

                    </div>

                </div>

            </div>

        </div>
    )

}