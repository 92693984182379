
import { onAuthStateChanged } from 'firebase/auth';
import { onValue, ref, set } from 'firebase/database';
import { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { studentAuth, studentDB } from '../../api/firebase-student-config';
import whiteFullLogo from '../../img/logo/white-full-logo.png';
import profilePlaceholder from '../../img/placeholder/profile-placeholder.png';
import $ from 'jquery'
import ModalLoading from '../general/ModalLoading';
export const LandingNavbar = ()=>{

    var [student,setStudent] = useState(null);
    var [uid,setUID] = useState(null)

    var location = useLocation()

    var [darkMode,setDarkMode] = useState(false)
    var [processing,setProcessing] = useState(false)

    useEffect(()=>{

        onAuthStateChanged(studentAuth,(student)=>{
        
            if(student){

                setUID(student.uid)
        
                onValue(ref(studentDB,"students/"+student.uid),(snapshot)=>{
                    if(snapshot.exists()){
                        setStudent(snapshot.val());
                    }
                })
        
            }
    
        })

        $("html, body").animate({ 
            scrollTop: 0 
        }, "fast");

        if($('.navbar-collapse').hasClass('show')) $('.navbar-toggler').trigger('click')
    
    },[location])

    useEffect(()=>{

        if(uid){
            onValue(ref(studentDB,`students/${uid}/theme`),(snapshot)=>{
                if(snapshot.exists()){
                    if(snapshot.val() === 'DARK') setDarkMode(true)
                    else setDarkMode(false)
                }else setDarkMode(false)
            })
        }

    },[uid])

    function logOut(){
        studentAuth.signOut().then(()=>window.location.reload());
    }

    function toggleTheme(){
        setProcessing(true)
        set(ref(studentDB,`students/${uid}/theme`),darkMode?'LIGHT':'DARK').then(()=>{
            window.location.reload()
        })
    }


    if(location && (location.pathname !== '/about' && location.pathname !== '/home' && location.pathname !== '/support' && location.pathname !== '/login' && location.pathname !== '/register')) return(null)

    if(location && location.pathname.includes('/try')) return null
    
    return(
        <>
        <nav className="navbar sticky-top navbar-expand-lg bg-blue px-lg-5 px-3 py-1 animate__animated animate__fadeInDown" style={{borderRadius:"0 0 15px 15px"}}>
            <div className="container-fluid">
                
                <a className="navbar-brand mb-0" href="/home">
                    <img src={whiteFullLogo} width="200px" alt="logo"/>
                </a>
                
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="fa fa-bars"></span>
                </button>
                
                <div className="collapse navbar-collapse" id="navbarText">
                    
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    </ul>

                    <span className="navbar-text d-block d-lg-none nav-mobile">

                        <div className="row me-auto mb-2 mb-lg-0 justify-content-center" style={{gap:"20px"}}>
                            
                            <div className="nav-item text-center col-3">
                                <NavLink className={({ isActive }) =>isActive ? 'nav-link active': 'nav-link'} to='/home'>
                                    <i className='fa fa-home' style={{fontSize:"24px"}}/>
                                    <br/>
                                    Home
                                </NavLink>
                            </div>
                            
                            <div className="nav-item text-center col-3">
                                <NavLink className={({ isActive }) =>isActive ? 'nav-link active': 'nav-link'} to='/about'>
                                    <i className='fa fa-user' style={{fontSize:"24px"}}/>
                                    <br/>
                                    About
                                </NavLink>
                            </div>

                            <div className="nav-item text-center col-3">
                                <NavLink className={({ isActive }) =>isActive ? 'nav-link active': 'nav-link'} to='/support'>
                                    <i className='fa fa-phone' style={{fontSize:"24px"}}/>
                                    <br/>
                                    Contact Us
                                </NavLink>
                            </div>

                            {!student &&
                            <div className="nav-item text-center col-3">
                                <NavLink className={({ isActive }) =>isActive ? 'nav-link active': 'nav-link'} to='/login'>
                                    <i className='fa fa-right-to-bracket' style={{fontSize:"24px"}}/>
                                    <br/>
                                    Log In
                                </NavLink>
                            </div>
                            }

                            {student?
                            <div className='col-3 text-center mt-3'>
                                <div className='row h-100 align-self-center justify-content-center'>
                                    <div className="dropdown align-self-center">
                                        <p className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <img className='me-2 mb-1' style={{borderRadius:"20rem"}} src={student?student.profile.profilePictureURL:profilePlaceholder} alt='profile' width="36px"/>
                                        </p>

                                        <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                            <li><a className="dropdown-item pointer" href="/"><i className="fa-solid fa-user"/> Profile</a></li>
                                            <li><a className="dropdown-item pointer" href="/policies"><i className="fa-solid fa-shield-halved"/> Policies</a></li>
                                            <li><hr className="dropdown-divider"/></li>
                                            <li className="dropdown-item pointer" onClick={()=>toggleTheme()}><i className={`fa-solid ${darkMode?'fa-sun':'fa-moon'}`}/> {darkMode?'Light Mode':'Dark Mode'}</li>
                                            <li><hr className="dropdown-divider"/></li>
                                            <li onClick={()=>logOut()}><p className="dropdown-item pointer bold"><i className="fa-solid fa-right-from-bracket"/> Sign Out</p></li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                            :
                            <div className='row align-self-center text-center'>
                                <NavLink to='/register'><button className='btn-outline-white px-4 py-2'>Register Now <i className='ms-2 fa fa-arrow-right'/></button></NavLink>
                            </div>
                            }

                        </div>

                    </span>

                    <span className="navbar-text d-none d-lg-block">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0" style={{gap:"30px"}}>
                            
                            <li className="nav-item text-center">
                                <NavLink className={({ isActive }) =>isActive ? 'nav-link active': 'nav-link'} to='/home'>
                                    <i className='fa fa-home' style={{fontSize:"24px"}}/>
                                    <br/>
                                    Home
                                </NavLink>
                            </li>

                            <li className="nav-item text-center">
                                <NavLink className={({ isActive }) =>isActive ? 'nav-link active': 'nav-link'} to='/about'>
                                    <i className='fa fa-user' style={{fontSize:"24px"}}/>
                                    <br/>
                                    About
                                </NavLink>
                            </li>

                            <li className="nav-item text-center">
                                <NavLink className={({ isActive }) =>isActive ? 'nav-link active': 'nav-link'} to='/support'>
                                    <i className='fa fa-phone' style={{fontSize:"24px"}}/>
                                    <br/>
                                    Contact Us
                                </NavLink>
                            </li>

                            {!student &&
                            <li className="nav-item text-center me-4">
                                <NavLink className={({ isActive }) =>isActive ? 'nav-link active': 'nav-link'} to='/login'>
                                    <i className='fa fa-right-to-bracket' style={{fontSize:"24px"}}/>
                                    <br/>
                                    Log In
                                </NavLink>
                            </li>
                            }

                            {student?
                            <div className='row h-100 align-self-center'>
                                <li className="nav-item dropstart">
                                    <p className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <img className='me-2' style={{borderRadius:"20rem"}} src={student?student.profile.profilePictureURL:profilePlaceholder} alt='profile' width="36px"/>
                                    </p>

                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item pointer" href="/"><i className="fa-solid fa-user"/> Profile</a></li>
                                        <li><a className="dropdown-item pointer" href="/policies"><i className="fa-solid fa-shield-halved"/> Policies</a></li>
                                        <li><hr className="dropdown-divider"/></li>
                                        <li className="dropdown-item pointer" onClick={()=>toggleTheme()}><i className={`fa-solid ${darkMode?'fa-sun':'fa-moon'}`}/> {darkMode?'Light Mode':'Dark Mode'}</li>
                                        <li><hr className="dropdown-divider"/></li>
                                        <li onClick={()=>logOut()}><p className="dropdown-item pointer bold"><i className="fa-solid fa-right-from-bracket"/> Sign Out</p></li>
                                    </ul>
                                </li>

                                
                            </div>
                            :
                            <div className='row align-self-center'>
                                <NavLink to='/register'><button className='btn-outline-white px-4 py-2'>Register Now <i className='ms-2 fa fa-arrow-right'/></button></NavLink>
                            </div>
                            }

                        </ul>
                    </span>

                </div>

            </div>
        </nav>
        <ModalLoading show={processing}/>
        </>
    )
}