import Modal from 'react-bootstrap/Modal';

import $ from 'jquery'
import { useState } from 'react';
import ModalLoading from '../general/ModalLoading';
import blueFullLogo from '../../img/logo/blue-full-logo.png';
import { hasNumber, hasUppercase } from '../../helper/formatter';
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from 'firebase/auth';
import { studentAuth } from '../../api/firebase-student-config';

export default function ModalChangePassword(props) {

  var [processing,setProcessing] = useState(false)
  var [changed,setChanged] = useState(false);

  var [oldPassword,setOldPassword] = useState()
  var [newPassword,setNewPassword] = useState()
  var [confirmPassword,setConfirmPassword] = useState()

  var [emailAddress,setEmailAddress] = useState()

  function verifyOldPassword(){

    var txtOldPassword = $('#txtOldPassword').val();
    var errOldPassword = $('#errOldPassword');

    errOldPassword.text('')
    setOldPassword(null);

    if(txtOldPassword.trim().length <= 0) errOldPassword.text('Blank spaces are not allowed.')
    else setOldPassword(txtOldPassword)

  }

  function verifyNewPassword(){

    var txtNewPassword = $('#txtNewPassword').val();
    var errNewPassword = $('#errNewPassword');
    
    errNewPassword.text('')
    setNewPassword(null);

    if(txtNewPassword.trim().length === 0) errNewPassword.text('Blank spaces are not allowed.');
    else if(txtNewPassword.length < 8) errNewPassword.text('Atleast 8 characters.');
    else if(!hasUppercase(txtNewPassword)) errNewPassword.text('Should have atleast 1 uppercase letter.')
    else if(!hasNumber(txtNewPassword)) errNewPassword.text('Should have atleast 1 number.')
    else setNewPassword(txtNewPassword);

    verifyConfirmPassword()

  }

  function verifyConfirmPassword(){

    var txtNewPassword = $('#txtNewPassword').val();

    var txtConfirmPassword = $('#txtConfirmPassword').val();
    var errConfirmPassword = $('#errConfirmPassword');
      
    errConfirmPassword.text('')
    setConfirmPassword(null);

    if(txtConfirmPassword !== txtNewPassword) errConfirmPassword.text('Password does not match.')
    else setConfirmPassword(txtConfirmPassword);

  }

  function changePassword(){

    setProcessing(true)

    var errOldPassword = $('#errOldPassword');

    const user = studentAuth.currentUser

    setEmailAddress(user.email)

    const credential = EmailAuthProvider.credential(
      user.email,
      oldPassword
    )

    reauthenticateWithCredential(user,credential).then((creds)=>{

      if(creds){
        
        updatePassword(user,newPassword).then(()=>{
          setProcessing(false)
          setChanged(true)
        })

      }else setProcessing(false)

    }).catch((error)=>{

      setProcessing(false)
      if(error.code === 'auth/wrong-password') errOldPassword.text('Wrong Password')
      else if(error.code === 'auth/too-many-requests') errOldPassword.text('Too Many Requests')
      else errOldPassword.text(error.message)

    })
    

  }

  function setDefault(){
    setNewPassword(null)
    setConfirmPassword(null)
    setConfirmPassword(null)
    setChanged(false)
  }

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop='static'
        onShow={()=>setDefault()}
      >
        
        <Modal.Header className='bg-blue' closeButton>
        
          <h4 className='saira text-center text-white m-0'>Change Password</h4>

        </Modal.Header>

        <Modal.Body className='bg-white-blue'>

          <div className='row justify-content-center py-3'>

            {changed?
            <div className='col-10 animate__animated animate__fadeIn'>

              <div className='row mb-2 justify-content-center'>
                <div className='col-12 text-center'>
                  <img src={blueFullLogo} alt='logo' width="50%" />
                </div>
              </div>

              <p className='text-center'><i className='fa fa-check text-blue' style={{fontSize:"72px",marginBottom:"-50px"}}/></p>
              <p className='text-center text-blue'>Password Changed for the Email <b className='text-light-blue'>({emailAddress})</b></p>
            
            </div>
            :
            <div className='col-10 animate__animated animate__fadeIn'>

              <div className='row mb-4 justify-content-center'>
                <div className='col-12 text-center'>
                  <img src={blueFullLogo} alt='logo' width="50%" />
                </div>
              </div>
              

              <p className='bold text-blue mt-3'>Old Password</p>
              <input id="txtOldPassword" className='form-control' type="password" onChange={()=>verifyOldPassword()}/>
              <small id="errOldPassword" class="form-text text-danger text-center mb-3 mt-2"></small>

              <p className='bold text-blue mt-5'>New Password</p>
              <input id="txtNewPassword" className='form-control' type="password" onChange={()=>verifyNewPassword()}/>
              <small id="errNewPassword" class="form-text text-danger text-center mb-3 mt-2"></small>

              <p className='bold text-blue mt-3'>Confirm Password</p>
              <input id="txtConfirmPassword" className='form-control' type="password" onChange={()=>verifyConfirmPassword()}/>
              <small id="errConfirmPassword" class="form-text text-danger text-center mb-3 mt-2"></small>

              {oldPassword && newPassword && confirmPassword?
              <button onClick={()=>changePassword()} className='form-control btn-blue py-2 mt-4'><i class="fa-solid fa-key me-1"></i> Change Password</button>
              :
              <button className='form-control btn-blue py-2 mt-4' disabled><i class="fa-solid fa-key me-1"></i> Change Password</button>
              }
            
            </div>
            }
            

          </div>

        </Modal.Body>

        <Modal.Footer className='bg-blue'>
        </Modal.Footer>

      </Modal>

      <ModalLoading show={processing} />

    </>
  );
}