
import { Helmet } from 'react-helmet-async';

import ModalLoading from '../../components/general/ModalLoading';
import { useEffect, useState } from 'react';

import {Header} from '../../components/general/Header'
import { onValue, ref, set } from 'firebase/database';
import { onAuthStateChanged } from 'firebase/auth';
import { studentAuth, studentDB } from '../../api/firebase-student-config';
import { hasLetter} from '../../helper/formatter'

import fxPrimusLogo from '../../img/logo/fxprimus-logo.png'

import $ from 'jquery'
import { Footer } from '../../components/academy/Footer';

import {adminDB} from '../../api/firebase-admin-config'

import moment from 'moment'

export const InnerCircle = () =>{

    const [processing,setProcessing] = useState(false);

    var [uid,setUID] = useState(null);
    var [status,setStatus] = useState()
    var [reason,setReason] = useState()

    var [accountNumber,setAccountNumber] = useState()
    var [amount,setAmount] = useState()
    var [dateDeposited,setDateDeposited] = useState()

    var [oldStudent,setOldStudent] = useState(false)
    

    useEffect(()=>{

        setAccountNumber(null)
        setAmount(null)
        setDateDeposited(null)

        onAuthStateChanged(studentAuth,(student)=>{
      
            if(student){
        
              onValue(ref(studentDB,"students/"+student.uid),(snapshot)=>{
                if(snapshot.exists()){
                    setUID(student.uid);
                    setProcessing(false);
                }
              })
        
            }else setProcessing(false);
        
        })

        if(uid){

            onValue(ref(studentDB,'partnershipRequests/' + uid),(snap)=>{

                if(snap.exists()){
                    
                    setStatus(snap.val().status)

                    if(snap.val().reason !== undefined) setReason(snap.val().reason)
                    else setReason(null)

                }
                else{
                    setStatus('N/A')
                    setReason(null)
                }

            })

        }

    },[uid,status])

    useEffect(()=>{

        if(accountNumber && accountNumber.length > 0){

            onValue(ref(adminDB,`oldInnerCircle/${accountNumber}`),(snapshot)=>{
                if(snapshot.exists()){

                    setAmount(snapshot.val())
                    $('#txtAmount').val(snapshot.val())
                    $('#txtAmount').prop('disabled',true)
                    verifyAmount()

                    setDateDeposited(moment().format('YYYY-MM-DD'))
                    $('#txtDateDeposited').val(moment().format('YYYY-MM-DD'))
                    $('#txtDateDeposited').prop('disabled',true)
                    verifyDateDeposited()

                    $('#infoAccountNumber').text(`Existing Student Detected.`)
                    setOldStudent(true)

                }else{
                    
                    setAmount(null)
                    $('#txtAmount').val('')
                    $('#txtAmount').prop('disabled',false)

                    setDateDeposited(null)
                    $('#txtDateDeposited').val('')
                    $('#txtDateDeposited').prop('disabled',false)

                    $('#infoAccountNumber').text('')
                    setOldStudent(false)

                }
            })
        }else{
            setAmount(null)
            $('#txtAmount').val('')
            $('#txtAmount').prop('disabled',false)

            setDateDeposited(null)
            $('#txtDateDeposited').val('')
            $('#txtDateDeposited').prop('disabled',false)

            $('#infoAccountNumber').text('')
            setOldStudent(false)

        }

    },[accountNumber])

    function verifyAccountNumber(){

        var txtAccountNumber = $('#txtAccountNumber').val()
        var errAccountNumber = $('#errAccountNumber')
        txtAccountNumber = txtAccountNumber.trim()

        errAccountNumber.text('')
        setAccountNumber(null)
        
        if(txtAccountNumber.trim().length <= 0) errAccountNumber.text('Blank spaces are not allowed.')
        else if(txtAccountNumber.includes(' ')) errAccountNumber.text('Spaces are not allowed.')
        else if(hasLetter(txtAccountNumber)) errAccountNumber.text('Letters are not allowed.')
        else if(txtAccountNumber.trim().length !== 7) errAccountNumber.text('Account # must be a 7-digit format.')
        else{
            
            setAccountNumber(txtAccountNumber)

        }

    }

    function verifyAmount(){

        var txtAmount = $('#txtAmount').val()
        var errAmount = $('#errAmount')

        errAmount.text('')
        setAmount(null)

        if(txtAmount.trim().length <= 0) errAmount.text('Blank spaces are not allowed.')
        else if(hasLetter(txtAmount)) errAmount.text('Letters are not allowed.')
        else{

            setAmount(txtAmount)
            
        }

    }

    function verifyDateDeposited(){

        var txtDateDeposited = $('#txtDateDeposited').val();
        var errDateDeposited = $('#errDateDeposited');
        
        errDateDeposited.text('')
        setDateDeposited(null);

        if(txtDateDeposited.trim().length === 0) errDateDeposited.text('Select a Date.');
        else setDateDeposited(txtDateDeposited);

    }

    function submitPartnershipRequest(){

        setProcessing(true)
        var body = {
            accountNumber:accountNumber,
            amount:amount,
            dateDeposited: dateDeposited,
            status:oldStudent?"Approved":"Pending"
        }

        set(ref(studentDB,'partnershipRequests/'+ uid),body).then(()=>{
            
            if(!oldStudent) setProcessing(false)
            else{
                set(ref(adminDB,`oldInnerCircle/${body.accountNumber}`),null).then(()=>{
                    setProcessing(false)
                }).catch((error)=>{
                    console.log(error)
                    setProcessing(false)
                })
            }

        }).catch((error)=>{
            alert(error.message)
            setProcessing(false)
        })
        

    }

    return(
        <>
            <Helmet>
                <title>Trading Circle - Inner Circle</title>
            </Helmet>
            
            <div className="container-fluid animate__animated animate__fadeIn p-4">

                <Header title='Inner Circle'/>

                <div className='row justify-content-center mb-5 mt-4 px-lg-5'>

                    <div className='col-lg-6 mb-lg-0 mb-5 align-self-center'>

                        <div className='row justify-content-center'>

                            {/*START: FX Primus Section DESKTOP*/}
                            <div className='col-lg-8 col-11 card-container-debossed py-3 px-4 d-none d-lg-block' style={{minWidth:"485px"}}>
                                <div className='row justify-content-center'>
                                    <div className='col-lg-10 text-center'>
                                        
                                        <img className='darkmode-logo' src={fxPrimusLogo} width='50%' alt='logo'/>

                                        <p className='text-center text-primus'>Trading Circle recommends FX Primus because it has low trading and non-trading fees, and account opening is easy and fast.</p>
                                        <p className='text-center text-primus mt-4'>Its customer service is great, providing fast and relevant answers.</p>

                                        {(status === 'N/A' || status === 'Rejected') && <a href='https://clients.fxprimus.com/en/register?ref=50011965' target="_blank" rel='noreferrer'><button className='btn-primus form-control mt-4 py-2'>{status === 'Rejected'?"Register Again":"Register Here"}</button></a>}
                                        {status === 'Pending' && <button className='btn-primus form-control mt-4 py-2' disabled>Pending Approval</button>}
                                        {status === 'Approved' && <button className='btn-primus form-control mt-4 py-2' disabled><i className='fa fa-check'/> Partnership Approved</button>}
                                        
                                        <hr className='mt-4 mb-4' style={{opacity:"0.1"}}/>
                            
                                        {status !== 'Approved' && <p className='text-primus saira mt-4 mb-4 bold text-justify' style={{fontSize:"14px"}}>If you signed up under the link posted above and deposited at least $100, you will receive the following benefits:</p>}
                                        {status === 'Approved' && <p className='text-primus saira mt-4 mb-4 bold text-justify' style={{fontSize:"14px"}}>You are now entitled to the following benefits:</p>}
                                        
                                        <div className='perks-container px-lg-4 mb-5'>
                                            <p className='mb-2 text-primus text-start saira'><i className='fa-solid fa-circle-check text-primus-light'/> Financial Literacy Training Every Friday</p>
                                            <p className='mb-2 text-primus text-start saira'><i className='fa-solid fa-circle-check text-primus-light'/> Access to Q and A group chat</p>
                                            <p className='mb-2 text-primus text-start saira'><i className='fa-solid fa-circle-check text-primus-light'/> Free Copytrading Opportunities</p>
                                            <p className='mb-2 text-primus text-start saira'><i className='fa-solid fa-circle-check text-primus-light'/> Free One-on-One 1-hour Consultation</p>
                                            <p className='mb-2 text-primus text-start saira'><i className='fa-solid fa-circle-check text-primus-light'/> Join Traders on Live Accounts</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/*END: FX Primus Section DESKTOP*/}

                            {/*START: FX Primus Section MOBILE*/}
                            <div className='col-lg-8 col-11 card-container-debossed py-3 px-4 d-block d-lg-none' style={{maxWidth:"500px"}}>
                                <div className='row justify-content-center'>
                                    <div className='col-lg-10 text-center'>
                                        
                                        <img className='darkmode-logo' src={fxPrimusLogo} width='75%' alt='logo'/>

                                        <p className='text-center text-primus' style={{fontSize:"15px"}}>Trading Circle recommends FX Primus because it has low trading and non-trading fees, and account opening is easy and fast.</p>
                                        <p className='text-center text-primus mt-4' style={{fontSize:"15px"}}>Its customer service is great, providing fast and relevant answers.</p>
                                    
                                        {status === 'N/A' && <a href='https://clients.fxprimus.com/en/register?ref=50011965' target="_blank" rel='noreferrer'><button className='btn-primus form-control mt-4 py-2'>Register Here</button></a>}
                                        {status === 'Pending' && <button className='btn-primus form-control mt-4 py-2' disabled>Pending Approval</button>}
                                        {status === 'Approved' && <button className='btn-primus form-control mt-4 py-2' disabled><i className='fa fa-check'/> Partnership Approved</button>}
                                        
                                        <hr className='mt-4 mb-4' style={{opacity:"0.1"}}/>

                                        {status !== 'Approved' && <p className='text-primus saira mt-4 mb-4 bold text-justify' style={{fontSize:"14px"}}>If you signed up under the link posted above and deposited at least $100, you will receive the following benefits:</p>}
                                        {status === 'Approved' && <p className='text-primus saira mt-4 mb-4 bold text-justify' style={{fontSize:"14px"}}>You are now entitled to the following benefits:</p>}                                        
                                        
                                        <div className='perks-container ps-3 mb-4'>
                                            <p className='mb-2 text-primus text-start saira' style={{fontSize:"14px"}}><i className='fa-solid fa-circle-check text-primus-light'/> Financial Literacy Training Every Friday</p>
                                            <p className='mb-2 text-primus text-start saira' style={{fontSize:"14px"}}><i className='fa-solid fa-circle-check text-primus-light'/> Access to Q and A group chat</p>
                                            <p className='mb-2 text-primus text-start saira' style={{fontSize:"14px"}}><i className='fa-solid fa-circle-check text-primus-light'/> Free Copytrading Opportunities</p>
                                            <p className='mb-2 text-primus text-start saira' style={{fontSize:"14px"}}><i className='fa-solid fa-circle-check text-primus-light'/> Free one-on-one 1-hour Consultation</p>
                                            <p className='mb-2 text-primus text-start saira' style={{fontSize:"14px"}}><i className='fa-solid fa-circle-check text-primus-light'/> Join Traders on Live Accounts</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/*END: FX Primus Section MOBILE*/}

                        </div>

                    </div>

                    <div className='col-lg-6 align-self-center'>
                        <div className='row justify-content-center'>

                            <div className='col-lg-10 col-11'>

                                <div className='video-container' style={{padding:"56.25% 0 0 0",position:"relative"}}><iframe src="https://player.vimeo.com/video/759044389?h=7afb3102dc&badge=0&autopause=0&player_id=0&app_id=58479/embed" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{position:"absolute",top:"0",left:"0",width:"100%",height:"100%"}} title="Website Walkthrough"></iframe></div>
                                <script src="https://player.vimeo.com/api/player.js"></script>
                                
                                <hr/>
                                
                                {status === 'Approved' && 
                                <div className='row' style={{opacity:"0.75"}}>
                                    <div className='col-12 mb-lg-3 mb-2 py-5 text-center animate__animated animate__fadeIn'>
                                        <h3 className='text-success' style={{fontSize:"64px"}}><i className="fa-solid fa-user-check"></i></h3>
                                        <h4 className='saira text-center text-success bold'>Approved Partnership with Trading Circle</h4>
                                    </div>
                                </div>
                                }

                                {status === 'Pending' &&
                                <div className='row' style={{opacity:"0.5"}}>
                                    <div className='col-12 mb-lg-3 mb-2 py-5 text-center animate__animated animate__fadeIn'>
                                        <h3 className='text-blue' style={{fontSize:"64px"}}><i className="fa-solid fa-person-circle-question"></i></h3>
                                        <h4 className='saira text-center text-blue bold'>Your Request is Pending for Approval</h4>
                                    </div>
                                </div>
                                }   

                                {(status === 'N/A' || status === 'Rejected') &&
                                <div className='row animate__animated animate__fadeIn'>

                                    <div className='col-12 mb-lg-3 mb-2'>
                                        <label className='saira bold text-blue mb-1' htmlFor="txtAccountNumber">Account Number</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"><i className='fa fa-user'/></span>
                                            </div>
                                            <input maxLength={7} id="txtAccountNumber" onChange={()=>verifyAccountNumber()} type="text" className="form-control" placeholder="Enter Account Number"/>
                                        </div>
                                        <small id="errAccountNumber" className="form-text text-danger text-center mb-3 mt-2"></small>
                                        <small id="infoAccountNumber" className="form-text text-light-blue text-center mb-3 mt-2"></small>
                                    </div>

                                    <div className='col-lg-12 mb-3'>
                                        <label className='saira bold text-blue mb-1' htmlFor="txtAmount">Amount <span style={{fontSize:"12px"}}>(USD)</span></label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">$</span>
                                            </div>
                                            <input id="txtAmount" onChange={()=>verifyAmount()} type="text" className="form-control" placeholder="Enter Amount"/>
                                        </div>
                                        <small id="errAmount" className="form-text text-danger text-center mb-3 mt-2"></small>
                                    </div>

                                    <div className='col-lg-12'>
                                        <label className='saira bold text-blue mb-1' htmlFor="txtDateDeposited">Date Deposited</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"><i className='fa fa-calendar'/></span>
                                            </div>
                                            <input id="txtDateDeposited" onChange={()=>verifyDateDeposited()} type="date" className="form-control"/>
                                        </div>
                                        <small id="errDateDeposited" className="form-text text-danger text-center mb-3 mt-2"></small>
                                    </div>

                                    <div className='col-12 mt-4'>
                                        {reason?
                                        <p className='text-error' style={{fontSize:"14px"}}><strong><i className='fa fa-times'/> REJECTED</strong> : {reason}</p>
                                        :
                                        <p className='text-muted' style={{fontSize:"14px"}}>*To receive these benefits, the academy will verify that your account is under the partnership of Trading Circle and FX Primus. Submit your funded account number and wait for the confirmation. Thank you!</p>
                                        }
                                    </div>

                                    <div className='col-12'>
                                        {accountNumber && amount && dateDeposited?
                                        <button className='btn-blue form-control py-2 mt-4' onClick={()=>submitPartnershipRequest()}>Submit</button>
                                        :
                                        <button className='btn-blue form-control py-2 mt-4' disabled>Submit</button>
                                        }
                                    </div>
                                </div>
                                }

                            </div>

                        </div>
                    </div>

                </div>

            </div>
            
            <Footer/>
            <ModalLoading show={processing} />
        </>
    );

}