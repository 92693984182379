import { Helmet } from "react-helmet-async"
import { PolicySidebar } from "../../components/general/PolicySidebar"


export const Disclaimer = () =>{

    return(
        <div className="container-fluid">

            <Helmet>
                <title>Trading Circle - Disclaimer</title>
            </Helmet>

            <div className="row justify-content-center p-lg-5 p-0">

                <PolicySidebar/>

                <div className="col-lg-6 text-blue policy-container ps-lg-4 px-5 animate__animated animate__fadeIn">
                    <h3 className="bold saira mt-lg-5 mt-4">Disclaimer</h3>
                    <h5 className="bold saira">Forex is extremely volatile</h5>

                    <div className="content mt-3 mb-5">
                        <p className="mb-4 text-justify">Trading on any foreign currency exchange, trading cryptocurrency is extremely volatile, carries a high level of risk and is not suitable for everyone. It most likely that You will sustain losses of some or all of your Initial capital, and therefore You should not invest money that You cannot afford to lose.</p>
                        <p className="mb-4 text-justify">You are solely responsible for Your own investment and trading decisions. Any trading decision should ONLY be made by you according to your own opinion, knowledge and experience. You assume the sole risk of any trade or Investment you decide to make based on your use of the information and training materials contained on this site or on any of our Social Media channels.</p>
                        <p className="mb-4 text-justify">You are encouraged to perform your own research on any currency. No representation or guarantee of any kind is being made that You will or should achieve profits similar to the results demonstrated In any hypothetical or live trade.</p>
                        <p className="mb-4 text-justify">Circle Online Educational Consultancy Services is NOT a financial broker/dealer or an investment advisor as defined under all laws/regulations of any applicable jurisdiction. The purchase, sale or advice regarding a currency can only be performed by a licensed broker.</p>
                        <p className="mb-4 text-justify">You must be aware of the risks and be willing to accept them to invest in the financial markets. Never trade with capital You cannot afford to lose.</p>
                        <p className="mb-4 text-justify">The Circle Online Educational Consultancy Services founders and/or any employees or contributors may hold positions in certain investments that are discussed or demonstrated on our Site, communicated via Telegram and Facebook group.</p>
                    </div>

                    <h5 className="bold saira">Impersonations</h5>
                    <div className="content mt-3 mb-5">
                        <p className="mb-4 text-justify">We are aware that there are third-parties, numerous websites and social media platforms Impersonating us or attempting to impersonate us. Our Website is where we receive money from the public. Please note that We do not provide any Investment advice or Investment service and do not offer daily, weekly or monthly returns.</p>
                        <p className="mb-4 text-justify">The public should be very vigilant when approached by these third parties. We will not accept any responsibility or liability for any claim or loss sustained by the public as a result of such third-party actions.</p>
                        <p className="mb-4 text-justify">We solely provide education to our members.</p>
                        <p className="mb-4 text-justify">We make every attempt to get these websites or social media accounts removed, however, this is not always possible. We urge you to do your due diligence prior to sending your money or subscribing to any services offered by such third-party websites.</p>
                    </div>

                    
                </div>

            </div>

        </div>
    )

}