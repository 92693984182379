
import {useEffect, useState} from 'react'
import {onValue, ref} from 'firebase/database'
import {studentDB} from '../../api/firebase-student-config'
import { adminDB } from '../../api/firebase-admin-config'

export const AssessmentResultList = (props) =>{

    var [assessments,setAssessments] = useState([])
    var [hasLimit, setHasLimit] = useState(true)
    var [canLimit, setCanLimit] = useState(false)

    useEffect(()=>{
        onValue(ref(studentDB,`/assessments/${props.uid}`),(snapshot)=>{
            if(snapshot.exists()){
                
                var asmnts = []
                var chapters = []
                for(var key in snapshot.val()){
                    
                    var curr = snapshot.val()[key]
                    curr.key = key
                    asmnts.push(curr)
                    chapters.push(key)

                }

                setCanLimit(asmnts.length > 3)

                if(chapters.length > 0){

                    onValue(ref(adminDB,`/courses`),(courses)=>{
                        
                        if(courses.exists()){

                            for(var i in chapters){
                                asmnts[i]['course'] = courses.val()[chapters[i]]
                            }
                        }

                        if(asmnts.length > 3 && hasLimit){
                            
                            while(asmnts.length > 3){
                                asmnts.pop()
                            }

                        }

                        setAssessments(asmnts)
                    })

                }

            }
        })      
    },[props.uid,hasLimit])

    return(
        <>
            {assessments.length > 0 &&
            <div className='row justify-content-center mt-5 mb-5 animate__animated animate__fadeIn' style={{gap:"20px"}}>

                <div className='col-12 d-lg-flex d-block mb-lg-3 mb-0'>
                    <h5 className='bold saira text-lg-start text-center text-blue m-0'>Assessment Results</h5>
                </div>

                {assessments.map((a)=>{

                    if(a.answers){
                        return(
                            <a href={`/module/assessment/results?chapter=${a.key}`} className="col-lg-3 col-md-5 col-11 animate__animated animate__fadeIn assessment-result-container">
                                <p className='text-center module-chapter' style={{background:`url(${a.course.thumbnailURL})`,fontVariant:'small-caps',fontSize:'24px'}}>Chapter {a.key}</p>
                                <img alt='icon' className='course-thumbnail animate__animated animate__fadeIn' src={a.course.thumbnailURL}/>
                                <p className='text-center module-chapter saira' style={{fontSize:'18px',background:`url(${a.course.thumbnailURL})`,fontVariant:'small-caps'}}>{a.score} / {Object.keys(a.answers).length}</p>
                            </a>
                        )
                    }else{
                        return(
                            <a href={`/module/assessment/results?chapter=${a.key}`} className="col-lg-3 col-md-5 col-11 animate__animated animate__fadeIn assessment-result-container">
                                <p className='text-center module-chapter' style={{background:`url(${a.course.thumbnailURL})`,fontVariant:'small-caps',fontSize:'24px'}}>Chapter {a.key}</p>
                                <img alt='icon' className='course-thumbnail animate__animated animate__fadeIn' src={a.course.thumbnailURL}/>
                                <p className='text-center module-chapter saira' style={{fontSize:'18px',background:`url(${a.course.thumbnailURL})`,fontVariant:'small-caps'}}>Retaking</p>
                            </a>
                        )
                    }

                })}

                
                {hasLimit && canLimit && <p className='pointer text-center text-blue animate__animated animate__fadeIn' style={{textDecoration:'underline'}} onClick={()=>setHasLimit(false)}>View More</p>}
                

            </div>
            }
        </>
    )

}