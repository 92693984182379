import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import $ from 'jquery'

import { globalRegions, globalProvinces } from '../../helper/globals';
import {hasCorrectEmail, hasLetter, hasSpecial, hasSpecialDot, numberWithCommas, toTitleCase} from '../../helper/formatter'
import ModalLoading from '../general/ModalLoading';
import { push, ref } from 'firebase/database';
import { studentDB } from '../../api/firebase-student-config';
import axios from 'axios';
import moment from 'moment';

export default function ModalPreOrder(props) {

  const [emailAddress,setEmailAddress] = useState();
  const [phoneNumber,setPhoneNumber] = useState();
  const [fullName,setFullName] = useState();

  const [region,setRegion] = useState('Metro Manila')
  const [province,setProvince] = useState('Metro Manila')
  const [postalCode,setPostalCode] = useState()
  const [city,setCity] = useState()
  const [barangay,setBarangay] = useState()
  const [address,setAddress] = useState()

  const [processing,setProcessing] = useState(false)

  const [ordered,setOrdered] = useState(false)
  const [trackingKey,setTrackingKey] = useState()

  function setDefault(){
    $('#txtFullName').val(fullName)
    $('#txtPhoneNumber').val(phoneNumber)
    $('#txtEmailAddress').val(emailAddress)

    $('#slcRegion').val(region)
    $('#slcProvince').val(province)
    $('#txtPostalCode').val(postalCode)
    $('#txtCity').val(city)
    $('#txtBarangay').val(barangay)
    $('#txtAddress').val(address)
  }


  function verifyRegion(){

    var r = $('#slcRegion').find(":selected").val();
    setRegion(r)

    setProvince(globalProvinces[r][0])

  }

  function verifyProvince(){

    var p = $('#slcProvince').find(":selected").val();
    setProvince(p)

  }

  function verifyFullName(){

    var txtFullName = $('#txtFullName').val();
    var errFullName = $('#errFullName');
    
    errFullName.text('')
    setFullName(null);

    if(txtFullName.trim().length === 0) errFullName.text('Blank spaces are not allowed.');
    else if(txtFullName.length <= 4) errFullName.text('Atleast 4 Characters.');
    else{
      setFullName(toTitleCase(txtFullName));
      $('#txtFullName').val(toTitleCase(txtFullName));
    }
}

  function verifyPostalCode(){

    var txtPostalCode = $('#txtPostalCode').val()
    var errPostalCode = $('#errPostalCode')

    errPostalCode.text('')
    setPostalCode('')

    if(txtPostalCode.length <= 0) errPostalCode.text('Do not leave blank fields.')
    else if(hasLetter(txtPostalCode) || hasSpecial(txtPostalCode) || txtPostalCode.length !== 4) errPostalCode.text('Invalid Postal Code')
    else setPostalCode(txtPostalCode)
    

  }

  function verifyCity(){

    var txtCity = $('#txtCity').val()
    var errCity = $('#errCity')

    errCity.text('')
    setCity('')

    if(txtCity.length <= 0) errCity.text('Do not leave blank fields.')
    else if(txtCity.length < 3) errCity.text('Atleast 3 Characters.')
    else setCity(toTitleCase(txtCity))

  }

  function verifyBarangay(){

    var txtBarangay = $('#txtBarangay').val()
    var errBarangay = $('#errBarangay')

    errBarangay.text('')
    setBarangay('')

    if(txtBarangay.length <= 0) errBarangay.text('Do not leave blank fields.')
    else if(txtBarangay.length < 3) errBarangay.text('Atleast 3 Characters.')
    else setBarangay(toTitleCase(txtBarangay))
    
  }

  function verifyAddress(){

    var txtAddress = $('#txtAddress').val()
    var errAddress = $('#errAddress')

    errAddress.text('')
    setAddress('')

    if(txtAddress.length <= 0) errAddress.text('Do not leave blank fields.')
    else if(txtAddress.length < 10) errAddress.text('Atleast 10 Characters.')
    else setAddress(toTitleCase(txtAddress))
    
  }

  function verifyEmailAddress(){

    var txtEmailAddress = $('#txtEmailAddress').val();
    var errEmailAddress = $('#errEmailAddress');
    
    txtEmailAddress = txtEmailAddress.trim()

    errEmailAddress.text('')
    //$('#infoEmailAddress').text('')  
    setEmailAddress(null);

    if(txtEmailAddress.trim().length === 0) errEmailAddress.text('Blank spaces are not allowed.');
    else if(!hasCorrectEmail(txtEmailAddress.trimEnd())) errEmailAddress.text('Invalid Email Format.');
    else{
        
        setEmailAddress(txtEmailAddress)

    }

  }

  function verifyPhoneNumber(){

    var txtPhoneNumber = $('#txtPhoneNumber').val().trim();
    var errPhoneNumber = $('#errPhoneNumber');
    
    errPhoneNumber.text('')
    setPhoneNumber(null);

    if(txtPhoneNumber.trim().length === 0) errPhoneNumber.text('Blank spaces are not allowed.');
    else if(hasLetter(txtPhoneNumber)) errPhoneNumber.text('Letters are not allowed.');
    else if(hasSpecialDot(txtPhoneNumber)) errPhoneNumber.text('Only Numbers are allowed.');
    else if(txtPhoneNumber.length < 11) errPhoneNumber.text('Invalid Format.');
    else if(txtPhoneNumber.includes(' ')) errPhoneNumber.text('Spaces are not allowed')
    else setPhoneNumber(txtPhoneNumber);

  }

  function submitDetails(){

    setProcessing(true)
    
    var fullAddress = `${region}, ${province}, ${postalCode} ${city}, ${barangay}, ${address}`
    var body = {
      fullName:fullName,
      emailAddress:emailAddress,
      phoneNumber:phoneNumber,
      status:'PENDING',
      address:fullAddress,
      detailedAddress:{
        region:region,
        province:province,
        postalCode:postalCode,
        city:city,
        barangay:barangay,
        address:address
      },
      cart:props.cart,
      pricing:{
        totalPrice:props.pricing.originalPrice - props.pricing.discount,
        discount:props.pricing.discount,
        originalPrice:props.pricing.originalPrice
      }
    }

    push(ref(studentDB,`preOrders`),body).then((key)=>{
      setProcessing(false)
      setOrdered(true)
      var sp = String(key).split('/');    
      setTrackingKey(sp[sp.length-1])
      sendPreOrderConfirmation(sp[sp.length-1]);
    })

    

  }

  function sendPreOrderConfirmation(tracking){
    setProcessing(true)
    axios.post('https://us-central1-trading-circle-ph-admin.cloudfunctions.net/app/api/email/support',{
    subject:`Shirt Order Placed Successfully - ${moment().format('MMMM YYYY')}`,
    email:emailAddress,
    html:`        
    <div style='display:table;margin:0 auto;'>
    <div style='max-width:500px;margin:0 auto;'>
        
        <p style='text-align:center;'>
        <img src='https://tradingcircle-ph.web.app/static/media/black-word-logo.1bf10b226314dd33a7ab.png' width='350px'/>
        </p>

        <hr/>

        <h2 style='white-space:pre;color:#01245A;margin-bottom:0;'>Shirt Order Placed Successfully - ${moment().format('MMMM YYYY')}</h2>
        <p style='color:#01245A;margin-top:0;margin-bottom:0;'>Hi ${fullName}, We would like to inform you that you have successfully ordered your t-shirt/s.</p>
        <br/>

        <p style='color:#01245A;margin-top:0;margin-bottom:0;'>You can track your order through the link below: </p>
        <p style='color:#01245A;margin-top:0;margin-bottom:0;'><a href='https://tradingcircle.com.ph/trading-circle-family-shirt/${tracking}' target='_blank'>https://tradingcircle.com.ph/trading-circle-family-shirt/${tracking}</a>.</p>
        <br/>
        <p style='color:#01245A;margin-top:0;margin-bottom:0;'>To settle your payment and get your order processed <b>send your proof of payment</b> on our facebook page: </p>
        <p style='color:#01245A;margin-top:0;margin-bottom:0;'><a href='https://www.facebook.com/alexbalingitofficial' target='_blank'>https://www.facebook.com/alexbalingitofficial</a>.</p>
        
    </div>
    </div>
    `
  }).then((res)=>{

    if(res.status === 200){

      setProcessing(false)

    }else{
      alert(res.text)
      setProcessing(false)
    }

  })

  }

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onShow={()=>setDefault()}
        keyboard={!ordered}
        backdrop={ordered?`static`:'dynamic'}
      > 

      <Modal.Header className='bg-blue' closeButton={!ordered}>
        
        <h4 className='saira text-center text-white m-0'><i className='fa fa-shopping-bag'/> Shirt Order Form</h4>

      </Modal.Header>

        <Modal.Body className='bg-white-blue' style={{borderRadius:"30px"}}>
          {ordered &&
          <div className='row justify-content-center px-3 py-2'>
            <h4 className='text-blue saira bold mb-0'>Your Order has been successfully submitted.</h4>
            <hr className='my-2'/>
            <p className='text-blue saira ms-2 bold mt-2'>Tracking #</p>
            <input value={trackingKey} className='form-control' type='text' disabled/>
            <p className='text-muted small mt-1'>*A confirmation Email has been sent to <b>{emailAddress}</b></p>
            <button className='btn-solid w-100 py-2 mt-3' onClick={()=>window.location.href=`/trading-circle-family-shirt/${trackingKey}`}> <i className='fa fa-truck'/> Payment & Tracking</button>          
          </div>
          }
          {!ordered &&
          <div className='row justify-content-center px-3 py-2' style={{rowGap:'15px'}}>
            <h4 className='text-blue saira bold mb-0'>Details</h4>
            <hr className='m-0'/>

            <div className='col-lg-8'>
              <p className='text-blue saira ms-2 bold'>Full Name</p>
              <input onChange={()=>verifyFullName()} id='txtFullName' className='form-control' type='text'/>
              <small id='errFullName' className='text-error'></small>
            </div>

            <div className='col-lg-4'>
              <p className='text-blue saira ms-2 bold'>Phone Number</p>
              <input onChange={()=>verifyPhoneNumber()} id='txtPhoneNumber' maxLength={11} className='form-control' type='text'/>
              <small id='errPhoneNumber' className='text-error'></small>
            </div>

            <div className='col-lg-12'>
              <p className='text-blue saira ms-2 bold'>Email Address</p>
              <input onChange={()=>verifyEmailAddress()} id='txtEmailAddress' className='form-control' type='text'/>
              <small id='errEmailAddress' className='text-error'></small>
            </div>

            <h4 className='text-blue saira bold mb-0 mt-3'>Address</h4>
            <hr className='m-0'/>

            <div className='col-lg-12'>
              <p className='text-blue saira ms-2 bold'>Region</p>
              <select className='form-select' id='slcRegion' onChange={()=>verifyRegion()}>
                {globalRegions.map((r)=>{return(<option key={r} value={r}>{r}</option>)})}
              </select>
            </div>

            <div className='col-lg-12'>
              <p className='text-blue saira ms-2 bold'>Province</p>
              <select className='form-select' id='slcProvince' onChange={()=>verifyProvince()}>
                {globalProvinces[region].map((p)=>{return(<option key={p} value={p}>{p}</option>)})}
              </select>
            </div>

            <div className='col-lg-4 col-5'>
              <p className='text-blue saira ms-2 bold'>Postal Code</p>
              <input id='txtPostalCode' className='form-control' type='text' maxLength={4} onChange={()=>verifyPostalCode()}/>
              <small id='errPostalCode' className='text-error'></small>
            </div>

            <div className='col-lg-8 col-7'>
              <p className='text-blue saira ms-2 bold'>City</p>
              <input id='txtCity' className='form-control' type='text' onChange={()=>verifyCity()}/>
              <small id='errCity' className='text-error'></small>
            </div>

            <div className='col-12'>
              <p className='text-blue saira ms-2 bold'>Barangay</p>
              <input id='txtBarangay' className='form-control' type='text' onChange={()=>verifyBarangay()}/>
              <small id='errBarangay' className='text-error'></small>
            </div>

            <div className='col-12'>
              <p className='text-blue saira ms-2 bold'>Address Line <small style={{fontSize:'12px'}}>(Subdv., St. Name, House No.)</small></p>
              <input id='txtAddress' className='form-control' type='text' onChange={()=>verifyAddress()}/>
              <small id='errAddress' className='text-error'></small>
            </div>

            <hr className='m-0'/>

            <div className='col-12'>
              <p className='text-blue saira ms-2 bold'>Full Address</p>
              <p className='text-blue small ms-3'>{region}, {province}, {postalCode} {city}, {barangay}, {address}</p>
            </div>

            <hr className='m-0'/>
            <div className='col-12'>
              <p className='text-blue saira ms-2 bold'>Original Price</p>
              <p className='text-blue small ms-3'>₱ {numberWithCommas(props.pricing.originalPrice)} ({props.cart.length} Shirt/s)</p>
              
              <p className={`${props.pricing.shippingFee === 0?'text-success':'text-error'} saira ms-2 bold mt-2`}>Shipping Fee</p>
              <p className={`${props.pricing.shippingFee === 0?'text-success':'text-error'} small ms-3`}>{props.pricing.shippingFee === 0?'FREE':`₱ ${numberWithCommas(props.pricing.shippingFee)}`}</p>

              <p className='text-success saira ms-2 bold mt-2'>Discount Price</p>
              <p className='text-success small ms-3'>₱ {numberWithCommas(props.pricing.discount)}</p>

              {props.pricing.mugs !== 0&& 
                <>
                  <p className='text-success saira ms-2 bold mt-2'>Freebie</p>
                  <p className='text-success small ms-3'>{props.pricing.mugs} TC Mug</p>
                </>
              }

              <hr/>
              <h5 className='text-blue saira ms-2 bold'>Total Price</h5>
              <h5 className='text-blue bold ms-3'>₱ {numberWithCommas(props.pricing.originalPrice - props.pricing.discount + props.pricing.shippingFee)}</h5>
            </div>

            <div className='col-12'>
              {fullName && phoneNumber && emailAddress && region && province && postalCode && city && barangay && address?
              <button className='btn-solid w-100 py-2' onClick={()=>submitDetails()}> <i className='fa fa-box'/> Confirm Order & Payment</button>
              :
              <button className='btn-solid w-100 py-2' disabled> <i className='fa fa-box'/> Confirm Order & Payment</button>
              }
            </div>
            <p className='text-muted ms-2 small'>*Make sure that your order information is correct before confirming! It is the buyer's liability if the information provided is incorrect.</p>


          </div>
          }
          

        </Modal.Body>

      </Modal>

      <ModalLoading show={processing}/>

    </>
  );
}