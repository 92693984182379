import Modal from 'react-bootstrap/Modal';

import $ from 'jquery'
import { useState } from 'react';
import ModalLoading from './ModalLoading';
import blueFullLogo from '../../img/logo/blue-full-logo.png';
import { hasCorrectEmail} from '../../helper/formatter';
import { EmailAuthProvider, reauthenticateWithCredential, updateEmail } from 'firebase/auth';
import { studentAuth } from '../../api/firebase-student-config';

export default function ModalChangeEmail(props) {

  var [processing,setProcessing] = useState(false)
  var [changed,setChanged] = useState(false);

  var [password,setPassword] = useState()
  var [emailAddress,setEmailAddress] = useState()

  function verifyPassword(){

    var txtPassword = $('#txtPassword').val();
    var errPassword = $('#errPassword');
    
    errPassword.text('')
    setPassword(null);
    
    setPassword(txtPassword);

  }

  function verifyEmailAddress(){
    var txtEmailAddress = $('#txtEmailAddress').val();
    var errEmailAddress = $('#errEmailAddress');
    
    errEmailAddress.text('')
    setEmailAddress(null);

    if(txtEmailAddress.trim().length === 0) errEmailAddress.text('Blank spaces are not allowed.');
    else if(!hasCorrectEmail(txtEmailAddress.trimEnd())) errEmailAddress.text('Invalid Email Format.');
    else setEmailAddress(txtEmailAddress);
  }

  function changeEmail(){

    setProcessing(true)

    var errPassword = $('#errPassword');
    var errEmailAddress = $('#errEmailAddress');

    const user = studentAuth.currentUser

    const credential = EmailAuthProvider.credential(
      user.email,
      password
    )

    reauthenticateWithCredential(user,credential).then((creds)=>{

      if(creds){
        
        updateEmail(user,emailAddress).then(()=>{
          window.location.reload()
          setChanged(true)
        }).catch((error)=>{
          
          if(error.code === 'auth/email-already-in-use') errEmailAddress.text('Email Aready In Use')
          else errEmailAddress.text(error.message)
          setProcessing(false)

        })

      }else setProcessing(false)

    }).catch((error)=>{

      setProcessing(false)
      if(error.code === 'auth/wrong-password') errPassword.text('Wrong Password')
      else if(error.code === 'auth/too-many-requests') errPassword.text('Too Many Requests')
      else errPassword.text(error.message)

    })
    

  }

  function setDefault(){
    setPassword(null)
    setEmailAddress(null)
    setChanged(false)
  }

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop='static'
        onShow={()=>setDefault()}
      >
        
        <Modal.Header className='bg-blue' closeButton>
        
          <h4 className='saira text-center text-white m-0'>Change Email Address</h4>

        </Modal.Header>

        <Modal.Body className='bg-white-blue'>

          <div className='row justify-content-center py-3'>

            {changed?
            <div className='col-10 animate__animated animate__fadeIn'>

              <div className='row mb-2 justify-content-center'>
                <div className='col-12 text-center'>
                  <img src={blueFullLogo} alt='logo' width="50%" />
                </div>
              </div>

              <p className='text-center'><i className='fa fa-check text-blue' style={{fontSize:"72px",marginBottom:"-50px"}}/></p>
              <p className='text-center text-blue'>Email Changed Successfully <b className='text-light-blue'>({emailAddress})</b></p>
            
            </div>
            :
            <div className='col-10 animate__animated animate__fadeIn'>

              <div className='row mb-4 justify-content-center'>
                <div className='col-12 text-center'>
                  <img src={blueFullLogo} alt='logo' width="50%" />
                </div>
              </div>
              
              <p className='bold text-blue mt-3'>New Email Address</p>
              <input id="txtEmailAddress" className='form-control' type="email" onChange={()=>verifyEmailAddress()}/>
              <small id="errEmailAddress" class="form-text text-danger text-center mb-3 mt-2"></small>

              <p className='bold text-blue mt-3'>Password</p>
              <input id="txtPassword" className='form-control' type="password" onChange={()=>verifyPassword()}/>
              <small id="errPassword" class="form-text text-danger text-center mb-3 mt-2"></small>

              {emailAddress?
              <button onClick={()=>changeEmail()} className='form-control btn-blue py-2 mt-4'><i class="fa fa-envelope me-1"></i> Change Email</button>
              :
              <button className='form-control btn-blue py-2 mt-4' disabled><i class="fa fa-envelope me-1"></i> Change Email</button>
              }
            
            </div>
            }
            

          </div>

        </Modal.Body>

        <Modal.Footer className='bg-blue'>
        </Modal.Footer>

      </Modal>

      <ModalLoading show={processing} />

    </>
  );
}