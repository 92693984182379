
import { Helmet } from 'react-helmet-async';

import alexPortrait from '../../img/people/alex-portrait.png'
import moneyReceive from '../../img/icon/money-receive.png'
import copySuccess from '../../img/icon/copy-success.png'
import trade from '../../img/icon/trade.png'

import moment from 'moment'
import { useEffect, useState } from 'react';
import blackWordLogo from '../../img/logo/black-word-logo.png';
import { FunnelFooter } from '../../components/landing/FunnelFooter';

import {set,ref,push} from 'firebase/database'
import { funnelDB } from '../../api/firebase-funnel-config';
import $ from 'jquery'

import irregShape from "../../img/bg/irregular-bg.png"
import ModalFreeTrainingCommunity from '../../components/general/ModalFreeTrainingCommunity';


export const FreeTrainingCommunity = (props) =>{

    var[subscribing,setSubscribing] = useState(false);

    var[hour,setHour] = useState(0)
    var[minute,setMinute] = useState(0)
    var[second,setSecond] = useState(0)

    const [pageID,setPageID] = useState()



    useEffect(()=>{

        if(pageID){
            var now = moment().format('MMMM DD YYYY')

            if(pageID){

                $.getJSON("https://api64.ipify.org?format=json", function(e){

                    var cleanedIP = String(e.ip).split('.').join('-')

                    push(ref(funnelDB,`analytics/impressions/${pageID}/${now}`),e.ip).then(()=>{

                        set(ref(funnelDB,`analytics/unique/${pageID}/${now}/${cleanedIP}`),'VISITED').then(()=>{

                        })

                    })

                });

            }
        }else setPageID('free-training')

    },[pageID])

    useEffect(()=>{

        var eventTime= moment().add(1,'day').unix(); 
        var currentTime = moment(moment().month()+1 + '-' +moment().date()+ '-'+moment().year() ,'MM-DD-YY');
        var diffTime =  currentTime - eventTime;
        var duration = moment.duration(diffTime*1000, 'milliseconds');
        var interval = 1000;

        const inte = setInterval(() =>{
            duration = moment.duration(duration - interval, 'milliseconds');
            setHour(duration.hours() < 10?'0'+ String(duration.hours()):duration.hours())
            setMinute(duration.minutes() < 10?'0'+ String(duration.minutes()):duration.minutes())
            setSecond(duration.seconds() < 10?'0'+ String(duration.seconds()):duration.seconds())
        }, interval);

        return () => clearInterval(inte);

    },[])

    return(
        <>
            <Helmet>
                <title>Trading Circle - Free Training</title>
                <script>
                    fbq('track', 'ViewContent')
                </script>
            </Helmet>
            <div className="container-fluid animate__animated animate__fadeIn px-lg-2 px-4 py-4">



                <div className='row justify-content-center'>

                    <div className='col-12 text-center'>
                        <img className='d-none d-lg-inline darkmode-logo' src={blackWordLogo} width='20%' alt='logo'/>
                        <img className='d-inline d-lg-none darkmode-logo' src={blackWordLogo} width='70%' alt='logo'/>
                        <div className='row justify-content-center'>
                            <div className='col-lg-6 col-12'>
                                <hr className='blue-hr my-3' style={{opacity:'0.5'}}/>
                            </div>
                        </div>
                    </div>

                    <div className='col-12'>
                        <div className='row justify-content-center'>
                            <div className='col-lg-12 text-center px-lg-0'>
                                <p className='mb-3 bg-light-blue text-white py-2' style={{fontSize:'calc(12px + 0.6vw)',borderRadius:'15px'}}>Designed for <b>EA Traders</b> who wants to discover the <b>magic of manual trading</b>.</p>
                            </div>

                            <div className='col-lg-9 text-center'>
                                <h1 className='black' style={{fontSize:'calc(24px + 0.8vw)'}}><u>MASTER</u> the art of <span className='text-light-blue'>MANUAL TRADING</span> while you are in <span className='text-light-blue'>Robot</span> and <span className='text-light-blue'>CopyTrading</span> to <span className='text-light-blue'>SKYROCKET</span> your income to <span className='text-light-blue'>300x</span> For Only <span className='text-light-blue'>15 minutes per Day.</span></h1>
                                <p className='mt-2' style={{fontSize:'calc(12px + 0.6vw)'}}>(This <span className='text-light-blue bold'>TRAINING</span> suits <span className='text-light-blue bold'>COPY</span> and <span className='text-light-blue bold'>EA Traders</span> who wants to learn <span className='text-light-blue bold'>LIMITLESS OPPORTUNITIES</span> of <span className='text-light-blue bold'>MANUAL TRADING</span>)</p>
                            </div>

                            <div className='col-12 my-4'/>
                            
                            <div className='col-12'>
                                <div className='row justify-content-center'>
                                    <div className='col-lg-2 col-8 align-self-center'>
                                        <div className='row justify-content-center'>
                                            <img src={alexPortrait} alt='Alex'/>
                                        </div>
                                    </div>

                                    <div className='col-lg-5 col-11 align-self-center ms-lg-4'>
                                        <div className='row justify-content-center align-self-center'>
                                            <p className='black text-center' style={{fontSize:'calc(14px + 0.6vw)'}}>In this <u>FREE</u> Training you will learn:</p>

                                            <div className='my-3'>
                                                <p className='mb-4 bold' style={{fontSize:'calc(12px + 0.5vw)'}}><i style={{fontSize:'calc(20px + 0.65vw)'}} className='fas fa-check text-light-blue black'/> How to <b>manual trade</b> in the most <b>effortless</b> and <b>elementary way</b>, even if you have <b>zero knowledge</b> of Forex in general.</p>
                                                <p className='mb-4 bold' style={{fontSize:'calc(12px + 0.5vw)'}}><i style={{fontSize:'calc(20px + 0.65vw)'}} className='fas fa-check text-light-blue black'/> How <b>copy trading</b> and <b>robot trading</b> complement <b>manual trading</b> and how manual trading can <b>300x boost</b> your existing trades and <b>profits</b>.</p>
                                                <p className='mb-4 bold' style={{fontSize:'calc(12px + 0.5vw)'}}><i style={{fontSize:'calc(20px + 0.65vw)'}} className='fas fa-check text-light-blue black'/> How you can <b>place trades</b> in <b>MetaTrader 4</b> and <b>effortlessly</b> read charts for <b>only 15 minutes per day</b>.</p>

                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-9 animate__animated animate__pulse animate__infinite animate__slow mt-4 d-lg-block d-none' data-aos="fade-up">
                                <button style={{fontSize:'28px'}} onClick={()=>setSubscribing(true)} className='form-control btn-solid bold py-4 px-5 w-100 btn-funnel'><i class="fa-solid fa-handshake-angle"></i> Access the FREE Training NOW!</button>
                            </div>

                            <div className='col-12 d-lg-none d-block'>
                                <button style={{fontSize:'calc(0.75rem + 1 vw)'}} onClick={()=>setSubscribing(true)} className='form-control btn-solid bold py-3 px-5 mt-4 w-100 animate__animated animate__pulse animate__infinite animate__slow btn-funnel'><i class="fa-solid fa-handshake-angle"></i> Access FREE Training!</button>
                            </div>

                            
                        </div>
                        
                    </div>

                    <div className='col-12'>

                        

                        {/*START: Training with Step by Step Desktop*/}
                        <div className='row d-lg-block d-none mt-2' id='learn-more' data-aos="fade-up" data-aos-offset="500">
                            <div className='col-12 pt-2 pb-5'>

                                <div className='row justify-content-center' style={{marginTop:"50px"}}>

                                    <div className='col-lg-8 text-center'>
                                        <h1 className='saira' style={{fontSize:"calc(1.4rem + 1.4vw)",lineHeight:"1.45"}}>This Training is Step-by-Step from Basic Up To The Strategy</h1>
                                        <p className='text-muted mt-4 bold'>Here's what you will discover:</p>
                                    </div>

                                </div>

                                <div className='row justify-content-center d-flex mt-5 mb-5' style={{gap:"40px"}}>

                                    <div className='col-lg-3 col-10 card-container p-5 text-center' style={{minWidth:"350px",maxWidth:'350px'}}>
                                        <img className='mb-3' src={moneyReceive} alt='icon'/>
                                        <p className='text-justify'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Generate CONSISTENT INCOME DAILY</b> from Forex whether The Current Market is Up or Down by Starting with only a SMALL CAPITAL.</p>
                                    </div>

                                    <div className='col-lg-3 col-10 card-container p-5 text-center' style={{minWidth:"350px",maxWidth:'350px'}}>
                                        <img className='mb-3' src={trade} alt='icon'/>
                                        <p className='text-justify'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Trade like the Pros for Only 20 MINUTES A DAY</b> by Using my Personal CIRCLE Strategy that Saved Me a Lot of Time Even if You Have a Full-Time Job.</p>
                                    </div>

                                    <div className='col-lg-3 col-10 card-container p-5 text-center' style={{minWidth:"350px",maxWidth:'350px'}}>
                                        <img className='mb-3' src={copySuccess} alt='icon'/>
                                        <p className='text-justify saira'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b className='montserrat'>Copy and Paste my PAYAMAN METHOD</b> that is Responsible for Making me 7-Figures in the Forex Market and RESCUED me From Huge Losses.</p>
                                    </div>

                                </div>
                                
                            </div>
                        </div>
                        {/*END: Training with Step by Step Desktop*/}

                        {/*START: Training with Step by Step Mobile*/}
                        <div className='row d-lg-none d-block'>
                            <div className='col-12'>

                                <div className='row justify-content-center' style={{marginTop:"30px"}}>

                                    <div className='col-lg-8 text-center'>
                                        <h1 className='saira' style={{fontSize:"26px",lineHeight:"1.45"}}>This Training is Step-by-Step from Basic Up To The Strategy</h1>
                                        <p className='text-muted mt-4 bold' style={{fontSize:"16px"}}>Here's what you will discover:</p>
                                    </div>

                                </div>

                                <div id='learn-more' className='row justify-content-center d-flex mt-5 mb-5' style={{gap:"15px"}}>

                                    <div className='col-12 card-container p-4 text-center' style={{maxWidth:"300px"}} data-aos="fade-up">
                                        <img className='mb-3' src={moneyReceive} alt='icon'/>
                                        <p className='text-justify' style={{fontSize:"14px"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Generate CONSISTENT INCOME DAILY</b> from Forex whether The Current Market is Up or Down by Starting with only a SMALL CAPITAL.</p>
                                    </div>

                                    <div className='col-12 card-container p-4 text-center' style={{maxWidth:"300px"}} data-aos="fade-up">
                                        <img className='mb-3' src={trade} alt='icon'/>
                                        <p className='text-justify' style={{fontSize:"14px"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Trade like the Pros for Only 20 MINUTES A DAY</b> by Using my Personal CIRCLE Strategy that Saved Me a Lot of Time Even if You Have a Full-Time Job.</p>
                                    </div>

                                    <div className='col-12 card-container p-4 text-center' style={{maxWidth:"300px"}} data-aos="fade-up">
                                        <img className='mb-3' src={copySuccess} alt='icon'/>
                                        <p className='text-justify saira' style={{fontSize:"14px"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b className='montserrat'>Copy and Paste my PAYAMAN METHOD</b> that is Responsible for Making me 7-Figures in the Forex Market and RESCUED me From Huge Losses.</p>
                                    </div>

                                </div>
                                
                            </div>
                        </div>
                        {/*END: Training with Step by Step Mobile*/}
                        
                        {/*START: Timer Section Desktop View*/}
                        <div className='row justify-content-center d-none d-lg-block' data-aos="fade-up">

                            <div className='col-12'>
                                <div className='row justify-content-center'>

                                    <div className='col-9 animate__animated animate__pulse animate__infinite animate__slow' data-aos="fade-up">
                                        <button style={{fontSize:'28px'}} onClick={()=>setSubscribing(true)} className='form-control btn-solid bold py-4 px-5 w-100 btn-funnel'><i class="fa-solid fa-handshake-angle"></i> Access the FREE Training NOW!</button>
                                    </div>
        
                                    <div className='col-12 mt-5'>
                                        <p className='text-muted saira bold text-center mb-3'>THIS OFFER EXPIRES IN</p>
                                    </div>

                                    <div className='col-lg-10 text-center'>

                                        <h1 className='black' style={{fontSize:"6.5vw"}}>
                                            {hour}
                                            <span className='saira text-light-blue me-5' style={{fontSize:"1.25vw"}}>HOURS</span>
                                            {minute}
                                            <span className='saira text-light-blue me-5' style={{fontSize:"1.25vw"}}>MINUTES</span>
                                            {second}
                                            <span className='saira text-light-blue' style={{fontSize:"1.25vw"}}>SECONDS</span>
                                        </h1>
                                    </div>

                                </div>
                            </div>
                            

                        </div>
                        {/*END: Timer Section Desktop View*/}

                        {/*START: Timer Section Mobile View*/}
                        <div className='row justify-content-center d-block d-lg-none' data-aos="fade-up">

                            <div className='col-12' style={{marginTop:"50px"}}>
                                <button style={{fontSize:'calc(0.75rem + 1 vw)'}} onClick={()=>setSubscribing(true)} className='form-control btn-solid bold py-3 px-5 mt-4 w-100 animate__animated animate__pulse animate__infinite animate__slow btn-funnel'><i class="fa-solid fa-handshake-angle"></i> Access FREE Training!</button>
                            </div>

                            <div className='col-12' style={{marginTop:"25px"}}>
                                <p className='text-muted saira bold text-center mb-3'>THIS OFFER EXPIRES IN</p>
                            </div>

                            <div className='col-12 text-center'>

                                <h1 className='black' style={{fontSize:"10vw"}}>
                                    {hour} : {minute} : {second}
                                </h1>
                            </div>

                        </div>
                        {/*END: Timer Section Mobile View*/}

                    </div>

                </div>

            </div>

            <div className='d-lg-block d-none' style={{background:`url('${irregShape}')`,width:'125%',height:'1507px',position:'absolute',top:'-100px',zIndex:'-999',backgroundPosition:'center center',right:'-12.5%',backgroundSize:'cover',opacity:'0.3'}}/>
            <div className='d-lg-none d-block' style={{background:`url('${irregShape}')`,width:'100%',height:'1005px',position:'absolute',top:'-100px',zIndex:'-999',backgroundPosition:'center center',backgroundSize:'cover',opacity:'0.2'}}/>

            <FunnelFooter/>

            <ModalFreeTrainingCommunity show={subscribing} onHide={()=>setSubscribing(false)} origin={props.origin}/>
        </>
    );

}