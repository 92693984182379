
import { Helmet } from 'react-helmet-async';

import ModalLoading from '../../components/general/ModalLoading';
import { useEffect, useState } from 'react';

import { onValue, ref, set } from 'firebase/database';
import { onAuthStateChanged } from 'firebase/auth';
import { studentAuth, studentDB } from '../../api/firebase-student-config';
import whiteFullLogo from '../../img/logo/white-full-logo.png';

import { Navigate, NavLink, useSearchParams } from 'react-router-dom';
import { adminDB } from '../../api/firebase-admin-config';
import moment from 'moment';

export const CourseView = () =>{

    const [processing,setProcessing] = useState(true);

    var [uid,setUID] = useState(null);  
    var [student,setStudent] = useState(null);

    var [chapter,setChapter] = useState()
    var [episodes,setEpisodes] = useState([])
    var [watched,setWatched] = useState({})
    //var [upgrade,setUpgrade] = useState(true);

    // eslint-disable-next-line no-unused-vars
    let [searchParams, setSearchParams] = useSearchParams();

    useEffect(()=>{

        onAuthStateChanged(studentAuth,(student)=>{ 
            
            if(student){
        
              onValue(ref(studentDB,"students/"+student.uid),(snapshot)=>{
                if(snapshot.exists()){
                    setUID(student.uid);
                    setStudent(snapshot.val());

                    if(searchParams.get("chapter") === "99"){
                        if(!snapshot.val().special ||  snapshot.val().special !== "UPGRADE"){
                            window.location.href = "/";
                        }
                    }
                }
              })
        
            }

        })

    },[searchParams])

    useEffect(()=>{

        if(uid){

            onValue(ref(adminDB,'courses/'+searchParams.get('chapter')),(snapshot)=>{

                setProcessing(true)

                if(snapshot.exists()){

                    setChapter(snapshot.val())
                    setProcessing(false)
                    
                    if(snapshot.val().episodes[searchParams.get('episode')] === undefined) window.location.href = '/course'

                    var eps = []
                    
                    for(var _key in snapshot.val().episodes){
                        var curr = snapshot.val().episodes[_key]

                        if(_key === searchParams.get('episode')){
                            curr.selected = true
                            
                            if(student.level === 'Elite' || (student.level === 'Standard' && snapshot.val().accessLevel === 'Standard')){
                                
                                set(ref(studentDB,`watchHistory/${uid}/${searchParams.get('chapter')}/${_key}`),_key).then()
                                set(ref(studentDB,`students/${uid}/recentHistory/`),searchParams.get('chapter') +'.'+_key).then()

                                var now = moment().toString()

                                if(student.streak){

                                    var last = moment(student.streak.date)
                                    var diff = moment().diff(last,'day')

                                    if(diff === 1){
                                        
                                        var ob = student.streak
                                        ob['date'] = now
                                        ob['days'] = parseInt(ob['days']) + 1
                                        

                                        set(ref(studentDB,`students/${uid}/streak`),ob).then()
                                        
                                    }else if(diff < 0 || diff > 1){
                                        var o = {
                                            date:now,
                                            days:1
                                        }
                                        set(ref(studentDB,`students/${uid}/streak`),o).then()
                                    }


                                }else{
                                    
                                    
                                    var obj = {
                                        date:now,
                                        days:1
                                    }

                                    set(ref(studentDB,`students/${uid}/streak`),obj).then()

                                }
                            }

                        }
                        curr.key = _key
                        eps.push(curr)
                    }

                    setEpisodes(eps)

                }else window.location.href = '/course'

            })
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[searchParams,uid])

    useEffect(()=>{

        if(episodes && uid){

            onValue(ref(studentDB,`watchHistory/${uid}`),(snapshot)=>{

                if(snapshot.exists()){

                    var wh = snapshot.val()
                    var ws = {}
                    
                    for(var i = 0; i <= episodes.length;i++){
                        if(wh[searchParams.get('chapter')][i] !== undefined){
                            ws[wh[searchParams.get('chapter')][i]] = wh[searchParams.get('chapter')][i]
                        }
                    }
                    console.log(ws)
                    setWatched(ws)

                }else setWatched({})

            })
        }

    },[uid,episodes,searchParams])

    function changeSearchParameters(chapter,episode){
        setSearchParams({chapter:chapter,episode:episode})
    }

    return(
        <>
            <Helmet>
                <title>Trading Circle - Course View</title>
            </Helmet>
            
            {student && chapter?
            student.level === 'Elite' || (chapter.accessLevel === 'Standard' && student.level === 'Standard')?
            <div className="container-fluid animate__animated animate__fadeIn p-0" style={{height:"100vh",background:'black'}}>

                <div className='d-flex flex-row module-video-container'>
                    
                    <div className='m-0 p-0' style={{width:'100%'}}>
                        <div className='m-0 p-0' style={{padding:"56.25% 0 0 0",height:'100%',position:"relative"}}>
                            {student && chapter &&
                            <iframe className='d-lg-block' id="ytplayer" type="text/html"
                            style={{width:'100%',height:'100%'}}
                            src={`https://www.youtube.com/embed/${chapter.episodes[searchParams.get('episode')].videoID}?modestbranding=1&autoplay=1`}
                            allowFullScreen title='hatdog' sandbox="allow-scripts allow-forms allow-same-origin allow-presentation"/>
                            }
                        </div>
                    </div>

                    {/*
                    <div className='m-0 p-0' style={{width:'100%'}}>
                        {student && chapter &&
                        <iframe className='d-lg-block' id="ytplayer" type="text/html" width='100%' height="100%"
                        src={`https://www.youtube.com/embed/${chapter.episodes[searchParams.get('episode')].videoID}?modestbranding=1&autoplay=1`}
                         allowFullScreen title='hatdog' sandbox="allow-scripts allow-forms allow-same-origin allow-presentation"/>
                        }
                    </div> */}

                    <div className='m-0 p-0 d-lg-block d-none bg-blue' style={{minWidth:'360px',maxWidth:'360px',overflowX:'hidden',overflowY:'auto'}}>

                        <div className='row m-0 p-0'>

                            <div className='col-12 m-0 p-0'>

                                <img className='px-5 pt-4' style={{marginBottom:'-10px'}} src={whiteFullLogo} width='100%' alt='logo'/>
                                <p className='text-white text-center saira' style={{fontSize:'14px'}}>Infinite Trading Experience</p>
                                
                                <div className='row justify-content-center'>
                                    <div className='col-10'>
                                        <hr className='my-3' style={{borderBottom:'2px solid white',opacity:'1'}}/>
                                    </div>
                                </div>

                                <div className='row bg-dark-blue py-1' style={{borderRadius:'35px'}}>

                                    <div className='col-12 text-center pb-3'>
                                        {searchParams.get('chapter') === "99"?
                                        <p className='text-center saira text-white mb-2 mt-2'>Free Elite Chapter</p>
                                        :
                                        <p className='text-center saira text-white mb-2 mt-2'>{searchParams.get('chapter') === "0"?"Introduction":"Chapter " + searchParams.get('chapter')}</p>
                                        }
                                        {chapter && <img className='mb-2 animate__animated animate__fadeIn module-logo' src={chapter.thumbnailURL} style={{borderRadius:'25px'}} width='90%' alt='thumbnail'/>}   
                                        {searchParams.get('chapter') === '3' && searchParams.get('episode') === '4' && <a href='https://clients.fxprimus.com/en/register?ref=50011965' rel='noreferrer' target="_blank"><button className='btn-solid px-5 py-2 my-2 animate__animated animate__fadeIn'><i className='fa fa-handshake me-1'/> Open FX Primus Account</button></a>}
                                        <p><a className='text-white' href='/module' style={{fontSize:'14px'}}><i className='fa fa-arrow-left me-2'/> Back to Module Page</a></p>
                                    </div>

                                </div>

                                <div className='mt-4'>

                                    {student && episodes?
                                    episodes.map((episode)=>{
                                        return(
                                        <div className='px-4 py-2 pointer' key={episode.key}>
                                            <p style={{fontSize:'14px'}} onClick={()=>changeSearchParameters(searchParams.get('chapter'),episode.key)} className={episode.selected?'text-light-blue saira':'text-white saira'}>
                                                <i className={watched[episode.key]?"fa-solid fa-circle-check me-2":"fa-solid fa-circle-play me-2"}/> {episode.title}
                                            </p>
                                        </div>
                                        )
                                    })
                                    :
                                    <></>
                                    }

                                    <div className='row justify-content-center mt-4 mb-3'>
                                        {searchParams.get('chapter') !== "99"?
                                        <div className='col-10 text-center'>
                                            {Object.keys(watched).length === episodes.length?
                                            <NavLink to={`/module/assessment/results?chapter=${searchParams.get('chapter')}`}><button className='btn-solid px-5 py-1'><i className='fa-solid fa-file-pen'/> Assessment</button></NavLink>
                                            :
                                            <button className='btn-solid px-5 py-1' disabled><i className='fa-solid fa-file-pen'/> Assessment</button>
                                            }
                                        </div>
                                        :
                                        <div className='col-10 text-center'>
                                            {Object.keys(watched).length === episodes.length?
                                            <NavLink to={`/advanced-training`}><button className='btn-solid px-5 py-1'><i className='fa-solid fa-arrow-right'/> Advanced Training</button></NavLink>
                                            :
                                            <button className='btn-solid px-5 py-1' disabled><i className='fa-solid fa-arrow-right'/> Advanced Training</button>
                                            }
                                        </div>
                                        }
                                    </div>
                                    

                                </div>

                            </div>

                        </div>

                    </div>
                </div>

                <div className='m-0 p-0 d-lg-none d-block bg-blue'>

                    <div className='row bg-dark-blue py-1 m-0' style={{borderRadius:'0 0 25px 25px'}}>

                        <div className='col-12 text-center pb-3'>

                            {searchParams.get('chapter') === "99"?
                            <p className='text-center saira text-white mb-2 mt-2'>Free Elite Chapter</p>
                            :
                            <p className='text-center saira text-white mb-2 mt-2'>{searchParams.get('chapter') === "0"?"Introduction":"Chapter " + searchParams.get('chapter')}</p>
                            }                            {chapter && <img className='mb-2 animate__animated animate__fadeIn' src={chapter.thumbnailURL} style={{borderRadius:'25px'}} width='60%' alt='thumbnail'/>}   
                            {searchParams.get('chapter') === '3' && searchParams.get('episode') === '4' && <a href='https://clients.fxprimus.com/en/register?ref=50011965' rel='noreferrer' target="_blank"><button className='btn-solid px-5 py-2 my-2 animate__animated animate__fadeIn'><i className='fa fa-handshake me-1'/> Open FX Primus Account</button></a>}
                            <p><a className='text-white' href='/module' style={{fontSize:'14px'}}><i className='fa fa-arrow-left me-2'/> Back to Module Page</a></p>
                        
                        </div>

                    </div>

                    <div className='mt-4 mb-2'>
                        
                        {episodes?
                        episodes.map((episode)=>{
                            return(
                            <div className='px-5 py-2 pointer' key={episode.key}>
                                <p onClick={()=>changeSearchParameters(searchParams.get('chapter'),episode.key)} className={episode.selected?'text-light-blue saira':'text-white saira'}>
                                    <i className={watched[episode.key]?"fa-solid fa-circle-check me-2":"fa-solid fa-circle-play me-2"}/> {episode.title}
                                </p>
                            </div>
                            )
                        })
                        :
                        <></>
                        }

                        <div className='row justify-content-center mt-4 mb-3'>
                            <div className='col-10 text-center'>
                                {Object.keys(watched).length === episodes.length?
                                <NavLink to={`/module/assessment/results?chapter=${searchParams.get('chapter')}`}><button className='btn-solid px-5 py-1'><i className='fa-solid fa-file-pen'/> Assessment</button></NavLink>
                                :
                                <button className='btn-solid px-5 py-1' disabled><i className='fa-solid fa-file-pen'/> Assessment</button>
                                }                            
                            </div>
                        </div>

                    </div>

                    <div className='row justify-content-center m-0'>
                        <div className='col-11'>
                            <hr className='my-3' style={{borderBottom:'2px solid white',opacity:'1'}}/>
                        </div>

                        <div className='col-12 text-center'>
                            <img className='px-5 pt-3' src={whiteFullLogo} width='75%' alt='logo'/>
                            <p className='text-white text-center saira pb-5' style={{fontSize:'75%'}}>Infinite Trading Experience</p>
                        </div>
                    </div>
                    
                                
                    
                </div>

            </div>
            :
            <Navigate to='/course'/>
            :
            <></>
            }
            
            <ModalLoading show={processing} />
        </>
    );

}